import {
  Box,
  Card,
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import React from "react";

function Program(props) {
  const { theme, matches } = props;
  const program = {
    cover: {
      backgroundColor: "#eb3333",
    },
    headerText: {
      color: "white",
      fontFamily: "Mossport",
      lineHeight: 1,
      [theme.breakpoints.down("md")]: {
        fontSize: "55px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "85px",
      },
    },
    primaryText: {
      color: "white",
      fontFamily: "Gotham Pro",

      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "13px",
      },
    },
    image: {
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        height: "auto",
      },
      [theme.breakpoints.up("md")]: {
        height: "380px",
      },
    },
    button: {
      backgroundColor: "white",
      borderRadius: 0,
      fontFamily: "Gotham ProBold",
      fontWeight: "bold",
      fontSize: "13px",

      color: "#eb3333",
      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "13px",
      },
      "&:hover": {
        background: "#eb3333",
        borderColor: "white",
        color: "white",
      },
    },
  };

  return (
    <Box sx={program.cover}>
      <Container sx={{ my: 4 }} maxWidth="lg">
        <Stack sx={{ my: 2 }} spacing={2}>
          <Typography sx={program.headerText} component="div">
            РАЗВЛЕКАТЕЛЬНАЯ ПРОГРАММА
          </Typography>

          <Stack sx={{flex:1}} direction={matches ? "row" : "column"} spacing={4}>
            <Grid md={6} xs={12}>
              <Stack spacing={2}>
                <Typography sx={program.primaryText} component="div">
                  Также у нас в запасе насыщенная развлекательная программа,
                  которая подходит для любого возраста:
                </Typography>

                <Typography sx={program.primaryText} component="div">
                  ⁃ зажигательный и энергичный ведущий на коньках,
                </Typography>
                <Typography sx={program.primaryText} component="div">
                  ⁃ веселые ледовые эстафеты, командные соревнования и конкурсы,
                </Typography>
                <Typography sx={program.primaryText} component="div">
                  ⁃ розыгрыш небольших призов,
                </Typography>
                <Typography sx={program.primaryText} component="div">
                  ⁃ памятные подарки.
                </Typography>
                <Typography sx={program.primaryText} component="div">
                  А самые активные фигуристы наших мастер-классов смогут
                  почувствовать себя участниками настоящего шоу на льду и
                  выступить с зажигательным танцевальным номером.
                </Typography>

                <Typography sx={program.primaryText} component="div">
                  Скучать не придётся! Ждём вас на катке ВДНХ
                </Typography>
              </Stack>
            </Grid>

            <Stack
              spacing={2}
              sx={{ my: 2, flex:1 }}
              direction={"column"}
            >
              <Box
                sx={program.image}
                component="img"
                src="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2FKatanie.jpg?alt=media&token=28f08fc2-b210-4644-bd0c-8e185e8e2ba9"
              />
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

export default Program;
