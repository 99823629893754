import NavBar from "../NavBar";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";

import {
  createEvent,
  createEventRogaine,
  editEvent,
  editEventRogaine,
  getEvent,
} from "../../firebase/FirebaseFunctions";

const Input = styled("input")({
  display: "none",
});



function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const urlToObject = async (url) => {
  const response = await fetch(url);

  const blob = await response.blob();
  const file = new File([blob], "image");
  return file;
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function EventForm() {
  const [select] = useState([]);
  const [club, setClub] = useState("");
  const [clubTitle, setClubTitle] = useState();
  const [baseImage, setBaseImage] = useState();
  const history = useHistory();
/*  const [questions, setQuestions] = useState();*/
  const [formImage, setImage] = useState(null);

  const [loading, setLoading] = useState(false);
/*  const [open, setOpen] = useState(false);*/
  const [isCreate, setCreate] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [uid, setUid] = useState();

  const [values, setValues] = useState({
    description: "",
    organizer: "",
    date: "",
    startTime: "",
    endTime: "",
    amount: 0,
    price: 0,
  });

  let eventForm;
  let eventHeader;
  let query = useQuery();

  useEffect(() => {
    if (query.get("club") !== null) {
      let c = query.get("club");
      setClub(c);

      switch (c) {
        case "rogaine":
          setClubTitle("РОГЕЙН");
          break;
        case "climbing":
          setClubTitle("СКАЛОЛАЗАНИЕ");

          break;
        case "skating":
          setClubTitle("ФИГУРНОЕ КАТАНИЕ");

          break;
        default:
          history.push("/");
          break;
      }
    } else {
      history.push("/");
    }

    if (query.get("action") !== null) {
      let c = query.get("action");

      switch (c) {
        case "edit":
          setEdit(true);
          if (query.get("uid") !== null) {
            setUid(query.get("uid"));
          } else {
            history.push("/");
          }

          break;
        case "create":
          setCreate(true);
          break;
        default:
          history.push("/");
          break;
      }
    } else {
      history.push("/");
    }
  }, [query]);

/*  useEffect(() => {
    if (select.length >= 40) setSelected(false);
    else setSelected(true);
  }, [select]);*/

/*  useEffect(() => {
    getQuestions(club).then((questions) => {
      setQuestions(questions);
    });
  }, [club]);*/

  useEffect(() => {
    if (isEdit) {
      getEvent(club, uid).then((event) => {
        setForm(event);
      });

   /*   getSelectedQuestionsByUid(club, uid).then((questions) => {
        setSelection(questions);
      });*/
    }
  }, [club, uid]);



  const setForm = (event) => {
    let temp = values;
    temp.description = event.description;
    temp.organizer = event.organizer;
    temp.date = event.startDate.split(" ")[0];
    temp.startTime = event.startDate.split(" ")[1];
    temp.endTime = event.endDate.split(" ")[1];
    temp.price = event.price;
    temp.amount = event.amount;

    if (club !== "rogaine") {
      temp.price = event.price;
      temp.amount = event.amount;
    }

    setValues(temp);

    urlToObject(event.photoURL).then((file) => {
      setImage(file);
    });
  };



  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

/*  const handleClose = () => {
    setOpen(false);
  };*/

/*  const handleOpen = () => {
    setOpen(true);
  };*/

  const handleCancel = () => {
    history.push(`/event?club=${club}&uid=${uid}`);
  };

/*  const renderQuestionPick = (questions) => {
    return (
      <EventCard>
        <CardActions>
          <IconButton onClick={handleClose}>
            <ArrowBackIcon />
          </IconButton>
        </CardActions>
        <CardContent>
          <Box sx={{ height: "500px", width: "100%" }}>
            <DataGrid
              rows={questions}
              columns={columns}
              selectionModel={select}
              pageSize={7}
              rowsPerPageOptions={[7]}
              checkboxSelection
              onSelectionModelChange={handleRowSelection}
              disableSelectionOnClick
            />
          </Box>
        </CardContent>
      </EventCard>
    );
  };*/

  const renderImageCard = () => {
    getBase64(formImage).then((data) => setBaseImage(data));

    return (
      <Grid item md={6} xs={12}>
        <Card>
          <CardContent>
            <Typography>Предпросмотр картинки</Typography>
          </CardContent>
          <CardMedia component="img" image={baseImage} />
        </Card>
      </Grid>
    );
  };

  const renderFormHeader = (title) => {
    return (
      <Card>
        <CardContent>
          <Typography variant="h5">
            {isCreate ? "Создание" : null} {isEdit ? "Редактирование" : null}{" "}
            мероприятия "{title}"
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData(e.currentTarget);

    try {
      setLoading(true);

      if (isCreate)
        switch (club) {
          case "rogaine":
            createEventRogaine(
              form.get("description"),
              form.get("organizer"),
              form.get("startTime"),
              form.get("endTime"),
              form.get("date"),
              formImage,
              club,
              select,
             /* questions*/
            ).then(() => {
              setLoading(false);
              history.push(`/${club}`);
            });
            break;
          default:
            createEvent(
              form.get("description"),
              form.get("organizer"),
              form.get("startTime"),
              form.get("endTime"),
              form.get("date"),
              form.get("amount"),
              form.get("price"),
              formImage,
              club
            ).then(() => {
              setLoading(false);
              history.push(`/${club}`);
            });
            break;
        }

      if (isEdit)
        switch (club) {
          case "rogaine":
            editEventRogaine(
              uid,
              form.get("description"),
              form.get("organizer"),
              form.get("startTime"),
              form.get("endTime"),
              form.get("date"),
              formImage,
              club,
              select,
              /*questions*/
            ).then(() => {
              setLoading(false);
              history.push(`/${club}`);
            });
            break;
          default:
            editEvent(
              uid,
              form.get("description"),
              form.get("organizer"),
              form.get("startTime"),
              form.get("endTime"),
              form.get("date"),
              form.get("amount"),
              form.get("price"),
              formImage,
              club
            ).then(() => {
              setLoading(false);
              history.push(`/${club}`);
            });
            break;
        }
    } catch (e) {

    }
  };

  const renderEventForm = () => {
    return (
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Форма
          </Typography>
          <Box onSubmit={handleSubmit} component="form">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  value={values.description}
                  name="description"
                  label="Описание"
                  fullWidth
                  variant="standard"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  value={values.organizer}
                  name="organizer"
                  label="Организатор"
                  fullWidth
                  variant="standard"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  required
                  value={values.date}
                  type="date"
                  name="date"
                  label="Дата"
                  fullWidth
                  onChange={handleChange}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  required
                  type="time"
                  value={values.startTime}
                  name="startTime"
                  label="Время начала"
                  fullWidth
                  onChange={handleChange}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  required
                  type="time"
                  name="endTime"
                  label="Время окончания"
                  fullWidth
                  value={values.endTime}
                  onChange={handleChange}
                  variant="standard"
                />
              </Grid>

              {club !== "rogaine" ? (
                <>
                  <Grid item xs={12} md={6}>
                    <TextField
                      type="number"
                      required
                      value={values.amount}
                      name="amount"
                      label="Количество мест"
                      fullWidth
                      variant="standard"
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      type="number"
                      required
                      value={values.price}
                      name="price"
                      label="Цена"
                      fullWidth
                      variant="standard"
                      onChange={handleChange}
                    />
                  </Grid>
                </>
              ) : null}

              <Grid item xs={12} md={6}>
                <label htmlFor="button-file">
                  <Input
                    id="button-file"
                    onChange={(event) => {
                      setImage(event.target.files[0]);
                    }}
                    accept="image/*"
                    type="file"
                  />
                  <Button variant="outlined" component="span">
                    Загрузить фото
                  </Button>
                </label>
              </Grid>

              {formImage ? renderImageCard() : null}

              {/*{club === "rogaine" ? (
                <Grid item md={12} xs={12}>
                  <Button
                    onClick={handleOpen}
                    variant="outlined"
                    component="span"
                  >
                    Выбрать КП
                  </Button>
                </Grid>
              ) : null}*/}

              <Grid item xs={12}>
                <Stack direction="row" spacing={1}>
                {loading ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Button type="submit" variant="contained">
                    {isCreate ? "Запланировать" : null}
                    {isEdit ? "Редактировать" : null} мероприятие
                  </Button>
                )}

                {isEdit ? (
                  <Button
                    onClick={handleCancel}
                    variant="outlined"
                    component="span"
                  >
                    Отмена
                  </Button>
                ) : null}
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    );
  };

  try {
    eventForm = renderEventForm();
    eventHeader = renderFormHeader(clubTitle);
    /*if (questions !== undefined) questionPick = renderQuestionPick(questions);*/
  } catch (e) {}

  return (
    <>
      <NavBar />

      <Container sx={{ my: 2, p: 3 }} maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {eventHeader}
          </Grid>

          <Grid item xs={12}>
            {eventForm}
            {/*{!open ? eventForm : questionPick}*/}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default EventForm;
