import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useAuth } from "../../contexts/AuthContext";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import { useHistory } from "react-router-dom";

function Info(props) {
  const history = useHistory();
  const { logout, currentUser } = useAuth();
  const { theme } = props;
  const [open, setOpen] = useState(false);
  const [newSex, setNewSex] = useState(props.sex);

  const infoStyles = {
    cover: {
      backgroundColor: "white",
    },

    infoBox: {
      width: "260px",
      textAlign: "right",
    },

    infoBoxLeft: {
      width: "260px",
      textAlign: "left",
    },
    headerText: {
      lineHeight: 1,
      color: "#eb3333",
      fontFamily: "Mossport",
      mb: 0,
      [theme.breakpoints.down("md")]: {
        fontSize: "55px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "85px",
      },
    },
    primaryTextLg: {
      color: "black",
      fontFamily: "Gotham ProBold",
      fontWeight: "bold",

      [theme.breakpoints.down("md")]: {
        fontSize: "30px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "50px",
      },
    },
    primaryText: {
      color: "black",
      fontFamily: "Gotham ProBold",
      fontWeight: "bold",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "26px",
      },
    },
    secondaryText: {
      color: "#eb3333",
      fontFamily: "Gotham ProBold",
      fontWeight: "bold",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "26px",
      },
    },
    image: {
      [theme.breakpoints.down("md")]: {
        height: "230px",
      },
      [theme.breakpoints.up("md")]: {
        height: "300px",
      },
    },
    button: {
      backgroundColor: "#eb3333",
      borderRadius: 0,
      fontFamily: "Gotham ProBold",
      fontWeight: "bold",
      fontSize: "13px",

      color: "white",
      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "13px",
      },
      "&:hover": {
        background: "white",
        borderColor: "#eb3333",
        color: "#eb3333",
      },
    },
  };

  const handleEdit = () => {
    setOpen(true);
  };

  let data;
  let dialog;

  const updateUser = async (
    uid,
    firstName,
    middleName,
    lastName,
    phonenumber,
    birthDate,
    sex
  ) => {
    await updateDoc(doc(firestore, "users", uid), {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      phonenumber: phonenumber,
      birthDate: birthDate,
      sex: sex,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData(e.currentTarget);

    await updateUser(
      currentUser.uid,
      form.get("firstName"),
      form.get("middleName"),
      form.get("lastName"),
      form.get("phonenumber"),
      form.get("birthDate"),
      form.get("sex")
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeSex = (event) => {
    setNewSex(event.target.value);
  };

  const logoutHandler = async () => {
    try {
      await logout();
      history.push("/");
    } catch (e) {}
  };

  const renderDialog = (user) => {
    let { firstName, lastName, middleName, birthDate, phonenumber, sex } = user;

    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Редактирование"}</DialogTitle>
        <DialogContent>
          <Box onSubmit={handleSubmit} component="form">
            <Stack sx={{ mt: 1 }} spacing={2}>
              <TextField
                defaultValue={lastName}
                size="small"
                name="lastName"
                label="Фамилия"
                variant="outlined"
                required
              />

              <TextField
                defaultValue={firstName}
                size="small"
                name="firstName"
                label="Имя"
                variant="outlined"
                required
              />

              <TextField
                defaultValue={middleName}
                size="small"
                name="middleName"
                label="Отчество"
                variant="outlined"
              />

              <TextField
                defaultValue={phonenumber}
                size="small"
                name="phonenumber"
                label="Номер телефона"
                variant="outlined"
                required
              />

              <TextField
                defaultValue={birthDate}
                size="small"
                type="date"
                name="birthDate"
                label="Дата рождения"
                variant="outlined"
                required
              />
              <FormControl size="small" fullWidth>
                <InputLabel id="sex-select-label">Пол</InputLabel>
                <Select
                  defaultValue={sex}
                  labelId="sex-select-label"
                  value={newSex}
                  name="sex"
                  label="Пол"
                  onChange={handleChangeSex}
                >
                  <MenuItem value={"Муж"}>Муж</MenuItem>
                  <MenuItem value={"Жен"}>Жен</MenuItem>
                </Select>
              </FormControl>

              <Button
                type="submit"
                variant="contained"
                onClick={() => {
                  props.handleClose();
                }}
              >
                Подтвердить
              </Button>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  const renderProfile = (user) => {
    let {
      firstName,
      lastName,
      middleName,
      email,
      birthDate,
      phonenumber,
      sex,
    } = user;

    let bd = new Intl.DateTimeFormat("ru-RU", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    }).format(Date.parse(birthDate));

    return (
      <Container sx={{ my: 4 }} maxWidth="lg">
        <Stack sx={{ my: 2 }}>
          <Typography sx={infoStyles.headerText} component="div">
            ЛИЧНЫЙ КАБИНЕТ
          </Typography>

          <Typography my={4} sx={infoStyles.primaryTextLg} component="div">
            {lastName + " " + firstName + " " + middleName}
          </Typography>

          <Stack my={4} direction="column">
            <Stack direction="row" spacing={1}>
              <Box sx={infoStyles.infoBox}>
                <Typography sx={infoStyles.secondaryText}>
                  ДАТА РОЖДЕНИЯ:
                </Typography>
              </Box>
              <Box sx={infoStyles.infoBoxLeft}>
                <Typography sx={infoStyles.primaryText}>{bd}</Typography>
              </Box>
            </Stack>

            <Stack direction="row" spacing={1}>
              <Box sx={infoStyles.infoBox}>
                <Typography sx={infoStyles.secondaryText}>ТЕЛЕФОН:</Typography>
              </Box>
              <Box sx={infoStyles.infoBoxLeft}>
                <Typography sx={infoStyles.primaryText}>
                  {phonenumber}
                </Typography>
              </Box>
            </Stack>

            <Stack direction="row" spacing={1}>
              <Box sx={infoStyles.infoBox}>
                <Typography sx={infoStyles.secondaryText}>EMAIL:</Typography>
              </Box>
              <Box sx={infoStyles.infoBoxLeft}>
                <Typography sx={infoStyles.primaryText}>{email}</Typography>
              </Box>
            </Stack>
          </Stack>

          <Stack direction="row" justifyContent="right" spacing={2}>
            <Button
              sx={infoStyles.button}
              onClick={handleEdit}
              variant="outlined"
            >
              Редактировать
            </Button>

            <Button
              sx={infoStyles.button}
              onClick={logoutHandler}
              variant="outlined"
            >
              Выйти
            </Button>
          </Stack>
        </Stack>
      </Container>
    );
  };

  try {
    data = renderProfile(props);
    dialog = renderDialog(props);
  } catch (e) {}

  return (
    <Box sx={infoStyles.cover}>
      {data}
      {dialog}
    </Box>
  );
}

Info.propTypes = {
  firstName: PropTypes.any,
  lastName: PropTypes.any,
  middleName: PropTypes.any,
  email: PropTypes.any,
  phonenumber: PropTypes.any,
  sex: PropTypes.any,
  birthDate: PropTypes.any,
};

export default Info;
