import NavBar from "../NavBar";
import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent, CardMedia,
  Container, Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import {
  checkUserByQuestion,
  countAnswer,
  getActiveRogaine,
  getQuestionByLink,
} from "../../firebase/FirebaseFunctions";
import { useAuth } from "../../contexts/AuthContext";
import Button from "@mui/material/Button";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function shuffleArray(array) {
  let i = array.length - 1;
  for (; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

function Question() {
  const { currentUser } = useAuth();
  const [link, setLink] = useState("");
  const [question, setQuestion] = useState();
  const [event, setEvent] = useState();
  const history = useHistory();
  const [isActive, setActive] = useState(false);
  const [check, setCheck] = useState({
    isValidQuestion: false,
    isValidUser: false,
    isValidEvent: false,
  });

  let query = useQuery();

  let questionCard;
  let header;

  useEffect(() => {
    if (query.get("id") !== null) {
      setLink(query.get("id"));
    } else {
      history.push("/");
    }

    if (!currentUser){
      history.push("/register");
    }
  }, [query]);

  useEffect(() => {
    getQuestion(link);
  }, [link]);

  useEffect(() => {
    getActive();
  }, []);



  useEffect(() => {
    if (event && currentUser)
      checkUser(event, currentUser);
  }, [event, currentUser]);


  const checkUser = (event, currentUser) => {
    checkUserByQuestion(currentUser.uid, event.id).then((valid) => {
      setCheck({
        ...check,
        isValidUser: valid,
      });
    })
  }

  const getQuestion = (link) => {
    getQuestionByLink(link).then((question) => {
      setQuestion(question);
    });
  };

  const getActive = () => {
    getActiveRogaine().then((event) => {
      if (event.length > 0) {
        setEvent(event[0]);
        setActive(true);
      }else {
        setActive(false);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    let correct = false;

    

    if (form.get("answer") === question.correct)
      correct = true

    countAnswer(correct, event.id, currentUser.uid, question.id).then(() => {
      history.push(`/profile`)
    })
  }

  const renderEventNotStarted = () => {
    return(
        <Card>
          <CardContent>
            <Typography variant="h5" component="div">Мероприятие еще не началось</Typography>
          </CardContent>
        </Card>
    );
  }





  const renderQuestion = (q) => {
    let { question, wrong1, wrong2, wrong3, correct } = q;

    let answers = [wrong1, wrong2, wrong3, correct];
    answers = shuffleArray(answers);

    return (
      <Card>



        <CardContent>
          <Box component="form" onSubmit={handleSubmit}>
            <FormControl fullWidth component="fieldset">
              <FormLabel>
                <Typography color="text.primary" variant="h5">
                  {question}
                </Typography>
              </FormLabel>
              <RadioGroup name="answer">
                <FormControlLabel
                  value={answers[0]}
                  control={<Radio />}
                  label={answers[0]}
                />
                <FormControlLabel
                  value={answers[1]}
                  control={<Radio />}
                  label={answers[1]}
                />
                <FormControlLabel
                  value={answers[2]}
                  control={<Radio />}
                  label={answers[2]}
                />
                <FormControlLabel
                  value={answers[3]}
                  control={<Radio />}
                  label={answers[3]}
                />
              </RadioGroup>
            </FormControl>
            <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
              Ответить
            </Button>
          </Box>
        </CardContent>
      </Card>
    );
  };

  const renderHeader = (q) => {

    let { title } = q;

    return(
        <Card>
          <CardContent>
            <Typography variant="h4" component="div">Вопрос "{title}"</Typography>
          </CardContent>
        </Card>
    );
  }

  try {

    header = renderHeader(question);

    if (isActive)
      questionCard = renderQuestion(question);
    else
      questionCard = renderEventNotStarted();


  } catch (e) {}

  return (
    <Box>
      <NavBar />

      <Container maxWidth="md" sx={{ my: 2, p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {header}
          </Grid>

          <Grid item xs={12}>
            {questionCard}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Question;
