import {Box, Grid, useMediaQuery} from "@mui/material";
import NavBar from "../NavBar";
import React, { useEffect, useState } from "react";

import { getEventJson } from "../../firebase/FirebaseFunctions";
import { useTheme } from "@emotion/react";
import { collection, onSnapshot } from "firebase/firestore";
import { firestore } from "../../firebase";

import Header from "./Header";
import WhiteBox from "./WhiteBox";

import BigWall from "./BigWall";
import Difficulty from "./Difficulty";
import Bouldering from "./Bouldering";
import EventCard from "../EventCard";
import EventItem from "../event-page-components/EventItem";
import Schedule from "../Schedule";

import Footer from "../Footer";
import Prizes from "../Prizes";

function Climbing() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [events, setEvents] = useState();
  const club = "climbing";

  useEffect(() => {
    onSnapshot(collection(firestore, `clubs/${club}/events`), (docs) => {
      const events = [];
      docs.forEach((doc) => {
        if (doc.exists) {
          let event = getEventJson(doc);
          events.push(event);
        }
      });
      setEvents(events);
    });
  }, []);

  let eventsList;

  const renderEvents = (docs) => {
    return docs.map((doc, i) => {
      return (
          <Grid key={i} item xs={12}>
              <EventItem isProfile={false} event={doc} />
          </Grid>
      );
    });
  };

  try {
    eventsList = renderEvents(events);
  } catch (e) {

  }

  return (
    <>
      <NavBar />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Header matches={matches} theme={theme} />

        {matches ? null : <WhiteBox theme={theme} />}

        <BigWall theme={theme} />
        <Difficulty matches={matches} theme={theme} />
        <Bouldering matches={matches} theme={theme} />

        <Schedule id="special" club={club} theme={theme} >
          {eventsList}
        </Schedule>

        <Prizes club={club} theme={theme}/>
        <Footer matches={matches} />
      </Box>
    </>
  );
}

export default Climbing;
