import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import NavBar from "../NavBar";
import React, { useEffect, useState } from "react";
import { getEventJson } from "../../firebase/FirebaseFunctions";
import Header from "../skating-page-components/Header";
import { useTheme } from "@emotion/react";
import RedBox from "./RedBox";
import School from "./School";
import Program from "./Program";
import Schedule from "../Schedule";
import { collection, onSnapshot } from "firebase/firestore";
import { firestore } from "../../firebase";
import EventCard from "../EventCard";
import EventItem from "../event-page-components/EventItem";
import Footer from "../Footer";
import Prizes from "../Prizes";

function Skating() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [events, setEvents] = useState();
  const club = "skating";

  useEffect(() => {
    onSnapshot(collection(firestore, `clubs/${club}/events`), (docs) => {
      const events = [];
      docs.forEach((doc) => {
        if (doc.exists) {
          let event = getEventJson(doc);
          events.push(event);
        }
      });
      setEvents(events);
    });
  }, []);

  let eventsList;

  const renderEvents = (docs) => {
    if (docs.length > 0)
      return docs.map((doc, i) => {
        return (
          <Grid key={i} item xs={12}>

              <EventItem isProfile={false} event={doc} />

          </Grid>
        );
      });
    else
      return (
        <Grid item xs={12}>

            <Typography variant="h5">Здесь пока пусто</Typography>

        </Grid>
      );
  };

  try {
    eventsList = renderEvents(events);
  } catch (e) {}

  return (
    <>
      <NavBar />

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Header matches={matches} theme={theme} />

        {matches ? null : <RedBox theme={theme} />}

        <School  matches={matches} theme={theme} />
        <Program matches={matches} theme={theme} />
        <Schedule club={club} theme={theme}>
          {eventsList}
        </Schedule>
        <Prizes club={club} theme={theme} />
        <Footer />
      </Box>
    </>
  );
}

export default Skating;
