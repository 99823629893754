import NavBar from "../NavBar";
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  useMediaQuery,
} from "@mui/material";
import "react-multi-carousel/lib/styles.css";

import {getEventJson} from "../../firebase/FirebaseFunctions";
import { useTheme } from "@emotion/react";
import EventCard from "../EventCard";
import EventItem from "../event-page-components/EventItem";
import Footer from "../Footer";
import Header from "./Header";
import RedBox from "./RedBox";
import SportFormat from "./SportFormat";
import ActiveFormat from "./ActiveFormat";
import Schedule from "../Schedule";
import {collection, onSnapshot} from "firebase/firestore";
import {firestore} from "../../firebase";
import Prizes from "../Prizes";


function Rogaine() {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [events, setEvents] = useState();
  const club = "rogaine";

  useEffect(() => {
    onSnapshot(collection(firestore, `clubs/${club}/events`), (docs) => {
      const events = [];
      docs.forEach((doc) => {
        if (doc.exists) {
          let event = getEventJson(doc);
          events.push(event);
        }
      });
      setEvents(events);
    });
  }, []);



  let eventsList;

  const renderEvents = (docs) => {
    return docs.filter((doc) => {
      if (doc.isHidden){
        if (doc.isHidden === true){
          return false;
        }
      }
      return true;
    }).map((doc, i) => {
      return (
        <Grid key={i} item xs={12}>

            <EventItem isProfile={false} event={doc} />

        </Grid>
      );
    });
  };

  try {
    eventsList = renderEvents(events);
  } catch (e) {

  }

  return (
    <>
      <NavBar />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Header theme={theme} matches={matches} />

        {matches ? null : <RedBox theme={theme} />}

        <SportFormat matches={matches} theme={theme} />
        <ActiveFormat matches={matches} theme={theme} />
        <Box id="special">
        <Schedule club={club} theme={theme} >
          {eventsList}
        </Schedule>
        </Box>
        <Prizes club={club} theme={theme}/>

        <Footer matches={matches} />
      </Box>
    </>
  );
}

export default Rogaine;
