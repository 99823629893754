import NavBar from "../NavBar";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  onSnapshot,
  doc,
  getDoc,
  addDoc,
  collection,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import {
  deleteEvent,
  endEvent,
  getArrived,
  getMemberAnswered,
  getMembers,
  getQuestions,
  getRoleMember,
  getRoleOrganizer,
  joinEvent,
  joinEventOrientation,
  joinEventRogaine,
  leaveEvent,
  startEvent,
  updateArrived,
  updateArrivedOrientation,
  updateArrivedRogaine,
} from "../../firebase/FirebaseFunctions";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import EditIcon from "@mui/icons-material/Edit";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StopCircleIcon from "@mui/icons-material/StopCircle";

let columns = [
  { field: "id", headerName: "uid", width: 120, hide: true },
  {
    field: "lastName",
    headerName: "Фамилия",
    editable: false,
    width: 200,
  },
  {
    field: "firstName",
    headerName: "Имя",
    editable: false,
    width: 200,
  },
  {
    field: "middleName",
    headerName: "Отчество",
    editable: false,
    width: 200,
  },
  {
    field: "phonenumber",
    headerName: "Телефон",
    editable: false,
    width: 200,
  },
  {
    field: "email",
    headerName: "Почта",
    editable: false,
    width: 200,
  },
];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function Event() {
  const [club, setClub] = useState();
  const [uid, setUid] = useState();
  const [event, setEvent] = useState();
  const { currentUser } = useAuth();
  const [organizer, setOrganizer] = useState(false);
  const [member, setMember] = useState(false);
  const [type, setType] = useState("");
  const [members, setMembers] = useState([]);
  const [arrived, setArrived] = useState([]);
  const [eventType, setEventType] = useState("");
  const [questions, setQuestions] = useState([]);
  const [isActive, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isOver, setOver] = useState(false);
  const [changed, setChanged] = useState(false);

  const [show, setShow] = useState({
    members: false,
  });

  const history = useHistory();
  let query = useQuery();
  let sidePanel;
  let eventDesc;
  let membersPanel;
  let amount;
  let price;
  let dialog;

  useEffect(() => {
    if (query.get("club") !== null) {
      setClub(query.get("club"));
    } else {
      history.push("/");
    }

    if (query.get("uid") !== null) {
      setUid(query.get("uid"));
    }
  }, [club, uid]);

  useEffect(() => {
    if (club && uid) getEvent(club, uid);
  }, [club, uid]);

  useEffect(() => {
    if (club === "rogaine")
      getQuestions(club).then((questions) => {
        setQuestions(questions);
      });
  }, [club]);

  useEffect(() => {
    getRoleOrganizer(club, currentUser).then((organizer) => {
      setOrganizer(organizer);
    });

    getRoleMember(club, currentUser, uid).then((member) => {
      if (member !== "") setMember(true);

      setType(member);
    });
  }, [club, currentUser]);

  useEffect(() => {
    if (event) setActive(event.isActive);
    if (event) setOver(event.isOver);
  }, [event]);

  const getEvent = (club, uid) => {
    onSnapshot(doc(firestore, `clubs/${club}/events/${uid}`), (doc) => {
      setEvent(doc.data());
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (message) => {
    setOpen(true);
    setMessage(message);
  };

  const handleJoin = () => {
    joinEvent(currentUser.uid, uid, club, currentUser.email);
  };

  const handleStart = () => {
    setOpen(false);
    startEvent(uid, club);
  };

  const handleStop = () => {
    setOpen(false);
    endEvent(uid, club);
  };

  const handleJoinOrientation = () => {
    joinEventOrientation(currentUser.uid, uid, currentUser.email);
  };

  const handleJoinRogaine = () => {
    joinEventRogaine(currentUser.uid, uid, currentUser.email);
  };

  const handleShowMembers = async (type) => {
    setEventType(type);

    await getArrived(club, uid, type).then((arrived) => {
      setArrived(arrived);
    });

    if (type === "rogaine")
      await getMembers(club, uid, type).then(async (members) => {
        questions.forEach((question) => {
          columns.push({
            field: `question${question.id}`,
            headerName: `КП${question.id}`,
            width: 120,
          });
        });

        columns.push({ field: "points", headerName: "Баллы", width: 150 });
        const rows = await createRows(members);

        setMembers(rows);

        setShow({
          ...show,
          members: true,
        });
      });
    else
      await getMembers(club, uid, type).then(async (members) => {
        columns = [
          { field: "id", headerName: "uid", width: 120, hide: true },
          {
            field: "lastName",
            headerName: "Фамилия",
            editable: false,
            width: 200,
          },
          {
            field: "firstName",
            headerName: "Имя",
            editable: false,
            width: 200,
          },
          {
            field: "middleName",
            headerName: "Отчество",
            editable: false,
            width: 200,
          },
          {
            field: "phonenumber",
            headerName: "Телефон",
            editable: false,
            width: 200,
          },
          {
            field: "email",
            headerName: "Почта",
            editable: false,
            width: 200,
          },
        ];

        if (club === "climbing") {
          columns.push({
            field: "time",
            headerName: "Тренировка",
            editable: false,
            width: 400,
          });
        }

        /*

        joinEvent("E01LLKKupBctNqkvrqTT95VJRdK2","XvxkjDEw6uQi9TFMmD8g","climbing", "jaroslav121994@gmail.com", "Урок Боулдеринг 2: 13.30-15.30")
        joinEvent("b4tjeT0utgP7ErlKJNM22SLTJ902","QxcBnOVo02Lo5ofE2spK","climbing", "vitkovivse@gmail.com", "Урок Трудность 2: 14.00-15.00")*/

        setMembers(members);

        setShow({
          ...show,
          members: true,
        });
      });
  };

  const createRows = async (members) => {
    let rows = [];
    await Promise.all(
      members.map(async (member) => {
        let object = {
          id: member.id,
          firstName: member.firstName,
          lastName: member.lastName,
          middleName: member.middleName,
          phonenumber: member.phonenumber,
          email: member.email,
        };

        const answered = await getMemberAnswered(member.id, uid);

        console.log(answered);

        questions.forEach((question) => {
          if (answered.answered_correct) {
            if (answered.answered_correct.includes(question.id))
              object[`question${question.id}`] = "1";
          }

          if (answered.answered_incorrect) {
            if (answered.answered_incorrect.includes(question.id))
              object[`question${question.id}`] = "0";
          }
        });

        if(answered.answered_correct){
          object.points = answered.answered_correct.length;
        }else{
          object.points = 0;
        }

        
        rows.push(object);
      })
    );

    return rows;
  };

  const handleHideMembers = async (type) => {
    setShow({
      ...show,
      members: false,
    });

    if (changed) {
      setChanged(false);

      if (type === "orientation")
        await updateArrivedOrientation(club, uid, arrived, type);

      if (type === "rogaine")
        await updateArrivedRogaine(club, uid, arrived, type);

      if (type === "default") await updateArrived(club, uid, arrived, type);
    }

    setEventType("");
  };

  const handleRowSelection = (e) => {
    if (arrived !== e) {
      setChanged(true);
    }

    setArrived(e);
  };

  const handleEdit = () => {
    history.push(`/form?action=edit&club=${club}&uid=${uid}`);
  };

  const handleDelete = () => {
    setOpen(false);
    deleteEvent(club, uid).then(() => {
      history.push(`/${club}`);
    });
  };

  const handleShowList = () => {
    history.push(`/${club}`);
  };

  const renderPrice = (doc) => {
    let { price } = doc;

    return (
      <Card sx={{ height: "100%" }}>
        <CardContent>
          <Typography color="text.secondary" gutterBottom>
            Цена
          </Typography>
          <Typography
            sx={{ marginTop: 5 }}
            textAlign="center"
            variant="h2"
            component="div"
          >
            {price}
          </Typography>
          <Typography textAlign="center" component="div">
            Баллов
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const renderAmount = (doc) => {
    let { amount } = doc;

    return (
      <Card sx={{ height: "100%" }}>
        <CardContent>
          <Typography color="text.secondary" gutterBottom>
            Места
          </Typography>
          <Typography sx={{ marginTop: 2 }} textAlign="center" component="div">
            Осталось
          </Typography>
          <Typography textAlign="center" variant="h2" component="div">
            {amount}
          </Typography>
          <Typography textAlign="center" component="div">
            Мест
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const renderMembers = (members, columns) => {
    return (
      <Card sx={{ height: "100%" }}>
        <CardActions>
          <IconButton
            onClick={() => {
              handleHideMembers(eventType);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </CardActions>
        <CardContent>
          <Box sx={{ height: "500px", width: "100%" }}>
            <DataGrid
              rows={members}
              pageSize={50}
              selectionModel={arrived}
              onSelectionModelChange={handleRowSelection}
              rowsPerPageOptions={[50]}
              checkboxSelection
              columns={columns}
              disableSelectionOnClick
              components={{
                Toolbar: CustomToolbar,
              }}
            />
          </Box>
        </CardContent>
      </Card>
    );
  };

  const renderRogaineEvent = (doc) => {
    let { description, photoURL, startDate, endDate, organizer } = doc;

    return (
      <Card sx={{ height: "100%" }}>
        <CardMedia component="img" height="450" image={photoURL} />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            Начнется: {startDate}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            Закончится: {endDate}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            Организатор: {organizer}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const renderEvent = (doc) => {
    let { description, photoURL, startDate, endDate, organizer } = doc;

    return (
      <Card sx={{ height: "100%" }}>
        <CardMedia component="img" height="450" image={photoURL} />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            Начнется: {startDate}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            Закончится: {endDate}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            Организатор: {organizer}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const renderJoin = () => {
    return (
      <Card sx={{ height: "100%" }}>
        <CardContent>
          <Stack spacing={2}>
            <Typography color="text.secondary" gutterBottom>
              Статус участия
            </Typography>
            <Button
              startIcon={<GroupAddIcon />}
              onClick={handleJoin}
              variant="outlined"
            >
              Участвовать
            </Button>
          </Stack>
        </CardContent>
      </Card>
    );
  };

  const renderJoinRogaine = () => {
    return (
      <Card sx={{ height: "100%" }}>
        <CardContent>
          <Stack spacing={2}>
            <Typography color="text.secondary" gutterBottom>
              Статус участия
            </Typography>
            <Grid container spacing={1}>
              <Grid item md={6} xs={12}>
                <Stack spacing={1}>
                  <Typography textAlign="center">
                    Ориентирование на скорость
                  </Typography>
                  <Button
                    startIcon={<GroupAddIcon />}
                    onClick={handleJoinOrientation}
                    variant="outlined"
                    fullWidth
                  >
                    Участвовать
                  </Button>
                </Stack>
              </Grid>

              <Grid item md={6} xs={12}>
                <Stack spacing={1}>
                  <Typography textAlign="center">
                    Рогейн с проверкой знаний
                  </Typography>
                  <Button
                    startIcon={<GroupAddIcon />}
                    onClick={handleJoinRogaine}
                    variant="outlined"
                    fullWidth
                  >
                    Участвовать
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </CardContent>
      </Card>
    );
  };

  const renderJoined = () => {
    let text = "";
    let isRogaine = false;

    // eslint-disable-next-line default-case
    switch (type) {
      case "rogaine":
        isRogaine = true;
        text = "Рогейн с проверкой знаний";
        break;
      case "orientation":
        isRogaine = true;
        text = "Ориентирование на скорость";
        break;
    }

    return (
      <Card sx={{ height: "100%" }}>
        <CardContent>
          <Stack spacing={2}>
            <Typography color="text.secondary" gutterBottom>
              Статус участия
            </Typography>
            <Typography textAlign="center">
              Поздравляем вы зарегистрированы, как участник.
            </Typography>
            <Typography textAlign="center">
              На ваш e-mail отправлено письмо с информацией о мероприятии. Если
              вы передумаете, то отменить регистрацию можно в личном кабинете.
            </Typography>

            {isRogaine ? (
              <Typography textAlign="center">{text}</Typography>
            ) : null}

            <Button
              disabled
              startIcon={<GroupAddIcon />}
              onClick={handleJoin}
              variant="outlined"
            >
              Вы участвуете
            </Button>
          </Stack>
        </CardContent>
      </Card>
    );
  };

  const renderControlPanel = () => {
    return (
      <Card sx={{ height: "100%" }}>
        <CardContent>
          <Stack spacing={2}>
            <Typography color="text.secondary" gutterBottom>
              Панель управления
            </Typography>

            {club === "rogaine" && event.isClass === undefined ? (
              <>
                <Stack spacing={1}>
                  <Typography textAlign="center">
                    Ориентирование на скорость
                  </Typography>
                  <Button
                    onClick={() => {
                      handleShowMembers("orientation");
                    }}
                    startIcon={<FormatListBulletedIcon />}
                    variant="outlined"
                  >
                    Список участников
                  </Button>
                </Stack>

                <Stack spacing={1}>
                  <Typography textAlign="center">
                    Рогейн с проверкой знаний
                  </Typography>
                  <Button
                    onClick={() => {
                      handleShowMembers("rogaine");
                    }}
                    startIcon={<FormatListBulletedIcon />}
                    variant="outlined"
                  >
                    Список участников
                  </Button>
                </Stack>
              </>
            ) : (
              <Button
                onClick={() => {
                  handleShowMembers("default");
                }}
                startIcon={<FormatListBulletedIcon />}
                variant="outlined"
              >
                Список участников
              </Button>
            )}

            {isOver ? null : isActive ? (
              <Button
                onClick={() => {
                  handleOpen("закончить");
                }}
                startIcon={<StopCircleIcon />}
                disabled={club === "class"}
                variant="outlined"
              >
                Закончить мероприятие
              </Button>
            ) : (
              <Button
                onClick={() => {
                  handleOpen("начать");
                }}
                startIcon={<PlayCircleFilledIcon />}
                variant="outlined"
                disabled={club === "class"}
              >
                Начать мероприятие
              </Button>
            )}

            {isOver ? null : (
              <Button
                onClick={handleEdit}
                startIcon={<EditIcon />}
                variant="outlined"
                disabled={club === "class"}
              >
                Редактировать мероприятие
              </Button>
            )}

            {isOver ? null : (
              <Button
                onClick={() => {
                  handleOpen("удалить");
                }}
                startIcon={<DeleteIcon />}
                variant="outlined"
                disabled={club === "class"}
              >
                Удалить мероприятие
              </Button>
            )}
          </Stack>
        </CardContent>
      </Card>
    );
  };

  const renderOver = () => {
    return (
      <Card sx={{ height: "100%" }}>
        <CardContent>
          <Stack spacing={2}>
            <Typography color="text.secondary" gutterBottom>
              Статус участия
            </Typography>
            <Typography textAlign="center">Мероприятие окончилось</Typography>
            <Typography textAlign="center">
              Но вы можете зарегестрироваться на следуещие
            </Typography>

            <Button onClick={handleShowList} variant="outlined">
              Список мероприятий
            </Button>
          </Stack>
        </CardContent>
      </Card>
    );
  };

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Вы уверены ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Что хотите {message} мероприятие
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {message === "удалить" ? (
            <Button onClick={handleDelete} autoFocus>
              Да
            </Button>
          ) : isActive ? (
            <Button onClick={handleStop} autoFocus>
              Да
            </Button>
          ) : (
            <Button onClick={handleStart} autoFocus>
              Да
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  try {
    dialog = renderDialog();

    if (club === "rogaine" && event.isClass === undefined) {
      eventDesc = renderRogaineEvent(event);
      if (!member && !organizer) sidePanel = renderJoinRogaine(event);
    } else {
      eventDesc = renderEvent(event);
      amount = renderAmount(event);
      price = renderPrice(event);
      if (!member && !organizer) sidePanel = renderJoin();
    }

    if (isOver && !organizer) sidePanel = renderOver();

    if (member && !organizer && !isOver) sidePanel = renderJoined();

    if (!member && organizer) sidePanel = renderControlPanel();

    if (members) membersPanel = renderMembers(members, columns);
  } catch (e) {}

  return (
    <>
      <NavBar />

      <Container maxWidth="xl" sx={{ my: 2, p: 2 }}>
        <Grid container spacing={2}>
          {show.members ? (
            <Grid item xs={12}>
              {membersPanel}
            </Grid>
          ) : null}

          {/*<Grid item md={7} xs={12}>
            {show.members ? null : eventDesc}
          </Grid>*/}

          <Grid item xs={12}>
            {show.members ? null : sidePanel}
          </Grid>

          {/*          <Grid item md={2} xs={12}>
            {show.members ? null : amount}
          </Grid>

          <Grid item md={2} xs={12}>
            {show.members ? null : price}
          </Grid>*/}
        </Grid>

        {dialog}
      </Container>
    </>
  );
}

export default Event;
