import {Box, Button, Card, CardMedia, Container, Grid, Stack, Typography} from "@mui/material";

import React from "react";

function Difficulty(props) {
    const { theme,matches } = props;

    const difficulty = {
        cover: {
            backgroundColor: "white",
        },
        headerText: {
            lineHeight: 1,
            color: "#eb3333",
            fontFamily: "Mossport",
            mb: 0,
            [theme.breakpoints.down("md")]: {
                fontSize: "55px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "85px",
            },
        },
        primaryText: {
            color: "black",
            fontFamily: "Gotham Pro",

            [theme.breakpoints.down("md")]: {
                fontSize: "16px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "13px",
            },
        },
        image: {
            [theme.breakpoints.down("md")]: {
                maxWidth: "100%",
                height: "auto",
            },
            [theme.breakpoints.up("md")]: {
                height: "380px",
            },
        },
        button: {
            backgroundColor: "#eb3333",
            borderRadius: 0,
            fontFamily: "Gotham ProBold",
            fontWeight: "bold",
            fontSize: "13px",

            color: "white",
            [theme.breakpoints.down("md")]: {
                fontSize: "10px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "13px",
            },
            "&:hover": {
                background: "white",
                borderColor: "#eb3333",
                color: "#eb3333",
            },
        },
    }

    return(
        <Box sx={difficulty.cover}>
            <Container sx={{ my: 4 }} maxWidth="lg">
                <Stack sx={{ my: 2 }}>
                    <Typography sx={difficulty.headerText} component="div">
                        ЗАНЯТИЕ НА ТРУДНОСТИ
                    </Typography>

                    <Stack spacing={2} sx={{ my: 2 }} direction={matches ? "row" : "column"}>

                        <Box sx={{flex: 1}}>
                            <Typography sx={difficulty.primaryText} component="div">
                                Урок посвящен знакомству со скалолазанием и учит выбирать и
                                пользоваться скалолазным оборудованием. Под присмотром
                                опытного тренера посетители научатся самостоятельно
                                организовывать свою страховку, что позволит им в дальнейшем
                                продолжить занятия на скалодроме самостоятельно.
                            </Typography>

                            <Typography sx={difficulty.primaryText} component="div">
                                <Box sx={{fontFamily: "Gotham ProBold",}}>
                                    Урок для
                                    взрослых.
                                </Box>
                            </Typography>

                            <Typography sx={difficulty.primaryText} component="div">
                                <Box sx={{fontFamily: "Gotham ProBold",}}>
                                    Длительность 1 час.
                                </Box>
                            </Typography>
                        </Box>



                        <Box sx={{flex: 1}}>
                            <Box
                                sx={difficulty.image}
                                component="img"
                                src="https://media.discordapp.net/attachments/910931020130451550/910931054813130832/unknown.png?width=597&height=670"
                            />
                        </Box>

                    </Stack>


                </Stack>
            </Container>
        </Box>
    );
}

export default Difficulty;