import NavBar from "../NavBar";
import React, { useEffect, useState } from "react";
import { Checkbox, Container, Box } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  getProgress,
  givePrizeByIndex,
} from "../../firebase/FirebaseFunctions";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Progress() {
  const history = useHistory();
  const [club, setClub] = useState();
  const [users, setUsers] = useState();
  let header = ["Фамилия", "Имя", "Отчество"];
  let rows = [];
  let query = useQuery();
  let table;

  useEffect(() => {
    if (query.get("club") !== null) {
      setClub(query.get("club"));
    } else {
      history.push("/");
    }
  }, [club]);

  useEffect(() => {
    if (club)
      getProgress(club).then((users) => {
        setUsers(users);
      });
  }, [club]);

  const renderTable = (docs) => {
    switch (club) {
      case "rogaine":
        header.push("Мешок для обуви");
        header.push("Снуд");
        header.push("Шапка");
        header.push("Компас");
        break;
      case "skating":
        header.push("Футболка");
        header.push("Перчатка");
        header.push("Шапка");
        header.push("Термостакан");

        break;
      case "climbing":
        header.push("Футболка");
        header.push("Бафф");
        header.push("Шапка");
        header.push("Термостакан");
        break;
    }
    header.push("Баллы");

    docs.sort((a,b) => {
      if(a.lastName < b.lastName){
        return -1;
      }
      if(a.lastName < b.lastName){
        return 1;
      }
      return 0;
    })

    const changePrize = (uid, index, club, value) => {
      givePrizeByIndex(uid, index, club, value);
    };

    return (
      <TableContainer component={Paper}>
        <Box sx={{m:2}}>Количество участников: {docs.length}</Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {header.map((name) => {
                return <TableCell>{name}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {docs.filter(function(doc) {
              if (doc.rogaine_progress === undefined){
                return false;
              }
              return true;
            }).map((doc, i) => {


              let points = 0;
              let progress;

              switch (club) {
                case "rogaine":
                  points = doc.rogaine_points;
                  progress = doc.rogaine_progress;
                  break;
                case "skating":
                  points = doc.skating_points;
                  progress = doc.skating_progress;
                  break;
                case "climbing":
                  points = doc.climbing_points;
                  progress = doc.climbing_progress;
                  break;
              }



              progress.map((progress) => {
                rows.push(progress.received);
              });

              return (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{doc.lastName}</TableCell>
                  <TableCell>{doc.firstName}</TableCell>
                  <TableCell>{doc.middleName}</TableCell>


                  {progress.map((row, i) => {
                    return (
                      <TableCell>
                        {progress[i].price > points ? (
                          <></>
                        ) : (
                          <Checkbox
                            onChange={(event) => {
                              changePrize(
                                doc.id,
                                i,
                                club,
                                event.target.checked
                              );
                            }}
                            key={i}
                            defaultChecked={progress[i].received}
                          />
                        )}
                      </TableCell>
                    );
                  })}
                  <TableCell>{points}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  try {
    if (users) table = renderTable(users);
  } catch (e) {

  }

  return (
    <>
      <NavBar />

      <Container maxWidth="xl" sx={{ my: 2, p: 2 }}>
        {table}
      </Container>
    </>
  );
}
export default Progress;
