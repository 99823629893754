import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";

import { HashLink } from "react-router-hash-link";

function Header(props) {
  const { matches, theme } = props;

  const header = {
    cover: {
      backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F1614676823_58-p-fon-figurnoe-katanie-62.png?alt=media&token=819de9f2-8ac9-40f0-83b8-28ee73d301d0)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },

    headerText: {
      color: "white",
      fontFamily: "Mossport",
      lineHeight: 1,
      [theme.breakpoints.down("md")]: {
        fontSize: "55px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "85px",
      },
    },

    redBox: {
      background: {
        backgroundColor: "#eb3333",
        px: 3,
        py: 4,
      },
      headerText: {
        fontSize: "24px",
        color: "white",
        fontFamily: "Mossport",
        [theme.breakpoints.down("md")]: {
          fontSize: "28px",
          mb: 2,
        },
        [theme.breakpoints.up("md")]: {
          fontSize: "24px",
        },
      },
      primaryText: {
        fontWeight: "bold",
        color: "white",
        fontSize: "11px",
        fontFamily: "Gotham ProBold",

        [theme.breakpoints.down("md")]: {
          fontSize: "14px",
        },
        [theme.breakpoints.up("md")]: {
          fontSize: "11px",
        },
      },
      button: {
        backgroundColor: "white",
        borderRadius: 0,
        fontFamily: "Gotham ProBold",
        fontWeight: "bold",
        fontSize: "13px",

        [theme.breakpoints.down("md")]: {
          fontSize: "17px",
        },
        [theme.breakpoints.up("md")]: {
          fontSize: "13px",
        },
        "&:hover": {
          background: "#eb3333",
          borderColor: "white",
          color: "white",
        },
      },
    },
  };
  return (
    <Box sx={header.cover}>
      <Container sx={matches ? { my: 4 } : { my: 12 }} maxWidth="lg">
        <Stack sx={{ my: 2, mt:13 }} spacing={5}>
          <Typography sx={header.headerText} component="div">
            ФИГУРНОЕ КАТАНИЕ
          </Typography>

          {matches ? (
            <Grid xs={6}>
              <Box sx={header.redBox.background}>
                <Typography sx={header.redBox.headerText}>
                  27 НОЯБРЯ — 25 ДЕКАБРЯ
                </Typography>
                <Typography sx={header.redBox.primaryText}>
                  Хватай коньки - будет интересно!
                </Typography>
                <Box sx={{ my: 3 }}>
                  <Typography sx={header.redBox.primaryText}>
                    Фигурное катание - красивый вид спорта и одна из любимейших
                    зимних забав. Наша площадка придётся по душе как желающим
                    улучшить свои навыки катания на льду, так и любителям
                    активного и веселого отдыха.
                  </Typography>
                </Box>

                <Button
                  component={HashLink}
                  smooth
                  to="/skating#schedule"
                  sx={header.redBox.button}
                  variant="outlined"
                >
                  <Box sx={{ px: 3 }}>РАСПИСАНИЕ</Box>
                </Button>
              </Box>
            </Grid>
          ) : null}
        </Stack>
      </Container>
    </Box>
  );
}
export default Header;
