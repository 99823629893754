import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import React, { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { Alert, Snackbar, Stack } from "@mui/material";

function ForgotPassword() {
  let data;
  const { resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [openError, setOpenError] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenError(false);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessage(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      setError("");
      setLoading(true);
      await resetPassword(data.get("email")).catch();
      setMessage("Проверьте вашу почту");
      setOpenMessage(true);
    } catch (e) {
      setError("Не удалось сбросить пароль");
      setOpenError(true);
    }
    setLoading(false);
  };

  const renderForgetPassword = () => {
    return (
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(https://source.unsplash.com/random)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Сбросить пароль
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                type="email"
                required
                fullWidth
                id="email"
                label="Почта"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <Button
                disabled={loading}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Сбросить
              </Button>

              <Stack
                sx={{ width: "100%" }}
                direction="row"
                justifyContent="center"
              >
                <Link href="/login" variant="body2">
                  Войти
                </Link>
              </Stack>
            </Box>
          </Box>
        </Grid>
        <Snackbar
          open={openError}
          autoHideDuration={6000}
          onClose={handleCloseError}
        >
          <Alert
            onClose={handleCloseError}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>

        <Snackbar
          open={openMessage}
          autoHideDuration={6000}
          message={message}
          onClose={handleCloseMessage}
        />
      </Grid>
    );
  };

  try {
    data = renderForgetPassword();
  } catch (e) {

  }

  return <>{data}</>;
}

export default ForgotPassword;
