import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

function School(props) {
  const { theme, matches } = props;

  const school = {
    cover: {
      backgroundColor: "white",
    },
    headerText: {
      lineHeight: 1,
      color: "#eb3333",
      fontFamily: "Mossport",
      mb: 0,
      [theme.breakpoints.down("md")]: {
        fontSize: "55px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "85px",
      },
    },
    primaryText: {
      color: "black",
      fontFamily: "Gotham Pro",

      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "13px",
      },
    },
    image: {
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        height: "auto",
      },
      [theme.breakpoints.up("md")]: {
        height: "380px",
      },
    },
    button: {
      backgroundColor: "#eb3333",
      borderRadius: 0,
      fontFamily: "Gotham ProBold",
      fontWeight: "bold",
      fontSize: "13px",

      color: "white",
      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "13px",
      },
      "&:hover": {
        background: "white",
        borderColor: "#eb3333",
        color: "#eb3333",
      },
    },
  };

  return (
    <Box sx={school.cover}>
      <Container sx={{ my: 4 }} maxWidth="lg">
        <Stack sx={{ my: 2 }} spacing={2}>
          <Typography sx={school.headerText} component="div">
            ШКОЛА ФИГУРНОГО КАТАНИЯ
          </Typography>

          <Stack direction={matches ? "row" : "column"} spacing={4}>
            <Grid item md={6} xs={12}>
              <Stack spacing={2}>
                <Typography sx={school.primaryText} component="div">
                  Приходите к нам:
                </Typography>
                <Typography sx={school.primaryText} component="div">
                  27-28 ноября (сб/вс)
                </Typography>
                <Typography sx={school.primaryText} component="div">
                  2-3 декабря (чт/пт)
                </Typography>
                <Typography sx={school.primaryText} component="div">
                  9-10 декабря (чт/пт)
                </Typography>
                <Typography sx={school.primaryText} component="div">
                  16-17 декабря (чт/пт)
                </Typography>

                <Typography sx={school.primaryText} component="div">
                  Начинаем ровно в 10:30!
                </Typography>

                <Typography sx={school.primaryText} component="div">
                  Мы проведем для вас бодрую разминку на льду и поделимся
                  искусством владения коньком на своих мастер-классах.
                </Typography>
              </Stack>
            </Grid>

            <Stack spacing={2} sx={{ my: 2 }} direction={matches ? "row" : "column"}>
              <Box
                  sx={school.image}
                  component="img"
                  src="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2Ff23a3e11b68b49068a21fb3e20204790.jpg?alt=media&token=a884efa0-5305-4cf8-81bd-17925334d5c8"
              />
            </Stack>


          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

export default School;
