import React, { useContext, useEffect, useState } from "react";
import {auth, firestore} from "../firebase";
import {
  onAuthStateChanged,
  signOut,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {setDoc, doc} from "firebase/firestore";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  function signup(email, password, firstName, middleName, lastName, phonenumber, birthDate, sex) {

    return createUserWithEmailAndPassword(auth, email, password).then(async (userCredential) => {
      const user = userCredential.user;
      await setDoc(doc(firestore, `users/${user.uid}`), {
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        phonenumber: phonenumber,
        email: email,
        birthDate: birthDate,
        sex: sex,
        rogaine_points:0,
        skating_points:0,
        climbing_points:0,
        rogaine_progress: [{name:"Мешок для обуви", received: false, price: 1}, {name:"Снуд", received: false, price: 2}, {name:"Шапка", received: false, price: 3}, {name:"Компас", received: false, price: 4}],
        skating_progress: [{name:"Футболка", received: false, price: 1}, {name:"Снуд", received: false, price: 2}, {name:"Шапка", received: false, price: 3}, {name:"Складной стакан", received: false, price: 4}],
        climbing_progress: [{name:"Скалолазание", received: false, price: 1}, {name:"Перчатки", received: false, price: 2}, {name:"Шапка", received: false, price: 3}, {name:"Термостакан", received: false, price: 4}],
      });
    });
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return signOut(auth)
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(auth, email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(auth, password);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
