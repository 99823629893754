import {Box, Container, Grid, Stack, Typography} from "@mui/material";
import React from "react";

function EventSchedule(props) {
  const { theme, children } = props;
  const schedule = {
    cover: {
      backgroundColor: "white",
    },
    headerText: {
      color: "black",
      fontFamily: "Mossport",
      [theme.breakpoints.down("md")]: {
        fontSize: "30px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "55px",
      },
    },
  };

  return (
    <Box sx={schedule.cover}>
      <Container sx={{ my: 4 }} maxWidth="lg">
        <Stack sx={{ my: 2 }}>
          <Typography sx={schedule.headerText} component="div">
            ЗАПИСЬ НА МЕРОПРИЯТИЯ
          </Typography>
          <Grid container spacing={2}>
            {children}
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}

export default EventSchedule;
