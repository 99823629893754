import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";

function BigWall(props) {
  const { theme } = props;

  const bigWall = {
    cover: {
      backgroundColor: "#eb3333",
    },
    headerText: {
      color: "white",
      fontFamily: "Mossport",
      lineHeight: 1,
      [theme.breakpoints.down("md")]: {
        fontSize: "55px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "85px",
      },
    },
    primaryText: {
      color: "white",
      fontFamily: "Gotham Pro",

      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "13px",
      },
    },
    image: {
      [theme.breakpoints.down("md")]: {
        height: "230px",
      },
      [theme.breakpoints.up("md")]: {
        height: "300px",
      },
    },
    button: {
      backgroundColor: "white",
      borderRadius: 0,
      fontFamily: "Gotham ProBold",
      fontWeight: "bold",
      fontSize: "13px",

      color: "#eb3333",
      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "13px",
      },
      "&:hover": {
        background: "#eb3333",
        borderColor: "white",
        color: "white",
      },
    },
  };

  return (
    <Box sx={bigWall.cover}>
      <Container sx={{ my: 4 }} maxWidth="lg">
        <Stack sx={{ my: 2 }}>
          <Typography sx={bigWall.headerText} component="div">
            СКАЛОДРОМ BIG WALL
          </Typography>

          <Grid md={9} xs={12}>
            <Stack spacing={1}>
              <Typography sx={bigWall.primaryText} component="div">
                Для посетителей открыт просторный зал, окруженный панорамными
                окнами. В 13-метровом стенде для лазания на трудность есть зона с
                имитацией настоящей скалы, которая позволит вам почувствовать себя
                настоящим скалолазом. Также у нас есть 9-метровые стенки с
                маршрутами разной сложности — наш опытный тренер поможет вам
                выбрать тот, который наиболее подойдет для вашей физической
                подготовки.
              </Typography>

              <Typography sx={bigWall.primaryText} component="div">
                Занятие на скалодроме — отличный способ провести время
                и попробовать что-то новое.
              </Typography>

              <Typography sx={bigWall.primaryText} component="div">
                Мы находимся в Павильоне 22, сразу за
                ”Москвариумом”. До нас удобно доехать на бесплатном электробусе,
                наша остановка — “Зеленый театр”.
              </Typography>

              <Typography sx={bigWall.primaryText} component="div">
                Участникам Спортивных Сезонов мы
                предлагаем познакомиться со скалолазанием или повысить уже
                имеющиеся навыки. Специальное оборудование не нужно, от вас
                достаточно удобной одежды, не стесняющей движения.
              </Typography>

              <Typography sx={bigWall.primaryText} component="div">
                Вы можете
                выбрать и записаться на один или несколько уроков.
              </Typography>
            </Stack>

          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}

export default BigWall;
