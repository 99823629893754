import {Box, Button, Card, CardMedia, Container, Grid, Stack, Typography} from "@mui/material";

import React from "react";

function Bouldering(props) {
    const { theme,matches } = props;
    const bouldering = {
        cover: {
            backgroundColor: "#eb3333",
        },
        headerText: {
            color: "white",
            fontFamily: "Mossport",
            lineHeight: 1,
            [theme.breakpoints.down("md")]: {
                fontSize: "55px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "85px",
            },
        },
        primaryText: {
            color: "white",
            fontFamily: "Gotham Pro",

            [theme.breakpoints.down("md")]: {
                fontSize: "16px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "13px",
            },
        },
        image: {
            [theme.breakpoints.down("md")]: {
                maxWidth: "100%",
                height: "auto",
            },
            [theme.breakpoints.up("md")]: {
                height: "380px",
            },
        },
        button: {
            backgroundColor: "white",
            borderRadius: 0,
            fontFamily: "Gotham ProBold",
            fontWeight: "bold",
            fontSize: "13px",

            color: "#eb3333",
            [theme.breakpoints.down("md")]: {
                fontSize: "10px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "13px",
            },
            "&:hover": {
                background: "#eb3333",
                borderColor: "white",
                color: "white",
            },


        },


    }

    return(
        <Box sx={bouldering.cover}>
            <Container sx={{ my: 4 }} maxWidth="lg">
                <Stack sx={{ my: 2 }}>
                    <Typography sx={bouldering.headerText} component="div">
                        ЗАНЯТИЕ НА БОУЛДЕРИНГЕ
                    </Typography>

                    <Grid md={9} xs={12}>
                        <Typography sx={bouldering.primaryText} component="div">
                            На уроке посетители познакомятся с дисциплиной скалолазания —
                            “боулдеринг”, которая предполагает лазание на низкой стенке без
                            страховки. Наши гости изучат принципы падения на маты
                            и простые приемы передвижения по стене.  Длительность 2 часа.
                        </Typography>
                        <Typography sx={bouldering.primaryText}>
                            Этот урок предназначен
                            для детей и взрослых.
                        </Typography>
                        <Typography sx={bouldering.primaryText}>
                            Этот урок предназначен
                            для детей и взрослых.
                        </Typography>
                        <Typography sx={bouldering.primaryText}>
                            Длительность 2 часа. Для скалолазов,
                            которые уже получили первичный опыт и желают узнать больше
                            о лазании в этой дисциплине, мы предложим урок технического
                            лазания на боулдеринге.
                        </Typography>
                        <Typography sx={bouldering.primaryText}>
                            Для детей и взрослых. Длительность
                            2 часа.
                        </Typography>
                    </Grid>

                    <Stack spacing={2} sx={{ my: 2 }} direction={matches ? "row" : "column"}>
                        <Box
                            sx={bouldering.image}
                            component="img"
                            src="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2Fbowlder.jpg?alt=media&token=167c7d5c-690c-4a1a-a178-a3d4773ccb2f"
                        />
                    </Stack>


                </Stack>
            </Container>
        </Box>
    );
}

export default Bouldering;