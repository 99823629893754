import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import {HashLink} from "react-router-hash-link";

function RedBox(props) {
  const { theme } = props;

  const redBox = {
      background: {
          backgroundColor: "#eb3333",
          px: 3,
          py: 4,
      },
      headerText: {
          fontSize: "24px",
          color: "white",
          fontFamily: "Mossport",
          [theme.breakpoints.down("md")]: {
              fontSize: "28px",
              mb: 2,
          },
          [theme.breakpoints.up("md")]: {
              fontSize: "24px",
          },
      },
      primaryText: {
          fontWeight: "bold",
          color: "white",
          fontSize: "11px",
          fontFamily: "Gotham ProBold",

          [theme.breakpoints.down("md")]: {
              fontSize: "14px",
          },
          [theme.breakpoints.up("md")]: {
              fontSize: "11px",
          },
      },
      button: {
          backgroundColor: "white",
          borderRadius: 0,
          fontFamily: "Gotham ProBold",
          fontWeight: "bold",
          fontSize: "13px",

          [theme.breakpoints.down("md")]: {
              fontSize: "17px",
          },
          [theme.breakpoints.up("md")]: {
              fontSize: "13px",
          },
          "&:hover": {
              background: "#eb3333",
              borderColor: "white",
              color: "white",
          },
      },
    };

  return (
    <Box sx={redBox.background}>
      <Container sx={{ my: 4 }} maxWidth="lg">
        <Typography sx={redBox.headerText}>
          13 НОЯБРЯ — 26 ДЕКАБРЯ
        </Typography>
        <Typography sx={redBox.primaryText}>
          Рогейн на ВДНХ в рамках Спортивного Клуба ВДНХ — это уникальная
          возможность активно и интересно провести несколько часов на свежем
          воздухе! Поближе познакомиться с территорией парка ВДНХ! И улучшить
          свои навыки ориентироваться на местности по карте и компасу!
        </Typography>
        <Box sx={{ my: 3 }}>
          <Typography sx={redBox.primaryText}>
            Два варианта участия: Спортивный и Активно-познавательный
          </Typography>
        </Box>

        <Button component={HashLink} smooth to='/rogaine#schedule' sx={redBox.button} variant="outlined">
          <Box  sx={{ px: 3 }}>РАСПИСАНИЕ</Box>
        </Button>
      </Container>
    </Box>
  );
}

export default RedBox;
