import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";

function Schedule(props) {
  const { theme, children, club } = props;
  const schedule = {
    cover: {
      backgroundColor: "white",
    },
    headerText: {
      color: "#eb3333",
      fontFamily: "Mossport",
      mb: 0,
      [theme.breakpoints.down("md")]: {
        fontSize: "55px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "85px",
      },
    },
  };

  return (
    <Box id="schedule" component="section" sx={schedule.cover}>
      <Container sx={{ my: 4 }} maxWidth="lg">
        <Stack sx={{ my: 2 }}>
          <Typography sx={schedule.headerText} component="div">
            РАСПИСАНИЕ
          </Typography>
          {club === "climbing" ? (
            <Box sx={{ my: 2 }}>
              <Typography sx={schedule.primaryText}>
                <Box sx={{ fontWeight: "bold" }}>
                  Для получения браслета, дающего право на бесплатную
                  тренировку, необходимо обратиться к администраторам нашей
                  площадки. Центральная аллея напротив павильона № 57
                </Box>
              </Typography>
            </Box>
          ) : null}
          <Grid container spacing={2}>
            {children}
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}

export default Schedule;
