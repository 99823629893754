import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getRoleMember,
  getRoleOrganizer,
  joinEvent,
  joinEventClass,
  joinEventOrientation,
  joinEventRogaine,
  leaveEvent,
} from "../../firebase/FirebaseFunctions";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import Link from "@mui/material/Link";

function EventItem(props) {
  const { event, isProfile, user } = props;
  const { currentUser } = useAuth();
  const [organizer, setOrganizer] = useState(false);
  const [member, setMember] = useState(false);
  const [isClass, setClass] = useState(false);
  const [type, setType] = useState("");
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const large = useMediaQuery(theme.breakpoints.up("lg"));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const rogaine = {
    box_4: {
      headerText: {
        color: "#eb3333",
        fontFamily: "Mossport",
        mb: 0,
        [theme.breakpoints.down("md")]: {
          fontSize: "55px",
        },
        [theme.breakpoints.up("md")]: {
          fontSize: "85px",
        },
      },
      primaryText: {
        color: "black",
        fontFamily: "Gotham Pro",

        [theme.breakpoints.down("md")]: {
          fontSize: "16px",
        },
        [theme.breakpoints.up("md")]: {
          fontSize: "13px",
        },
      },
      card: {
        dateText: {
          color: "#eb3333",
          fontFamily: "Mossport",
          lineHeight: 1,
          [theme.breakpoints.down("md")]: {
            fontSize: "20px",
          },
          [theme.breakpoints.up("md")]: {
            fontSize: "25px",
          },
        },
        headerText: {
          color: "black",
          fontWeight: "bold",

          fontFamily: "Gotham ProBold",
          letterSpacing: 0.09,
          [theme.breakpoints.up("md")]: {
            fontSize: "16px",
          },
          [theme.breakpoints.down("md")]: {
            fontSize: "14px",
          },
        },
        primaryText: {
          color: "black",
          lineHeight: 1.2,
          fontWeight: "200",
          fontFamily: "Gotham Pro",
          letterSpacing: 0.09,
          [theme.breakpoints.up("md")]: {
            fontSize: "12px",
          },
          [theme.breakpoints.down("md")]: {
            fontSize: "10px",
          },
        },
        button: {
          backgroundColor: "#eb3333",
          borderRadius: 0,
          my: 1,
          fontFamily: "Gotham ProBold",
          fontWeight: "bold",
          minHeight: "40px",
          color: "white",
          [theme.breakpoints.down("md")]: {
            fontSize: "8px",
          },
          [theme.breakpoints.up("md")]: {
            fontSize: "10px",
          },
          "&:hover": {
            background: "white",
            borderColor: "#eb3333",
            color: "#eb3333",
          },

          "&.Mui-disabled": {
            color: "white",
            background: "grey",
          },
        },
      },
    },
    box_5: {
      headerText: {
        color: "#eb3333",
        fontFamily: "Mossport",
        mb: 0,
        [theme.breakpoints.down("md")]: {
          fontSize: "55px",
        },
        [theme.breakpoints.up("md")]: {
          fontSize: "85px",
        },
      },
      primaryText: {
        color: "black",
        fontFamily: "Gotham Pro",

        [theme.breakpoints.down("md")]: {
          fontSize: "16px",
        },
        [theme.breakpoints.up("md")]: {
          fontSize: "13px",
        },
      },
      card: {
        dateText: {
          color: "white",
          fontFamily: "Mossport",
          lineHeight: 1,
          [theme.breakpoints.down("md")]: {
            fontSize: "20px",
          },
          [theme.breakpoints.up("md")]: {
            fontSize: "25px",
          },
        },
        headerText: {
          color: "white",
          fontWeight: "bold",

          fontFamily: "Gotham ProBold",
          letterSpacing: 0.09,
          [theme.breakpoints.up("md")]: {
            fontSize: "16px",
          },
          [theme.breakpoints.down("md")]: {
            fontSize: "14px",
          },
        },
        primaryText: {
          color: "white",
          lineHeight: 1.2,
          fontWeight: "200",
          fontFamily: "Gotham Pro",
          letterSpacing: 0.09,
          [theme.breakpoints.up("md")]: {
            fontSize: "12px",
          },
          [theme.breakpoints.down("md")]: {
            fontSize: "10px",
          },
        },
        button: {
          backgroundColor: "white",
          borderRadius: 0,
          my: 1,
          fontFamily: "Gotham ProBold",
          fontWeight: "bold",
          minHeight: "40px",
          color: "#eb3333",
          [theme.breakpoints.down("md")]: {
            fontSize: "8px",
          },
          [theme.breakpoints.up("md")]: {
            fontSize: "10px",
          },
          "&:hover": {
            background: "#eb3333",
            borderColor: "white",
            color: "white",
          },
          "&.Mui-disabled": {
            color: "white",
            background: "grey",
          },
        },
      },
    },
  };

  let sidePanel;

  useEffect(() => {
    if (event.isClass !== undefined)
      setClass(event.isClass)

  }, [])


  useEffect(() => {
    if (currentUser)
      getRoleOrganizer(event.club, currentUser).then((organizer) => {
        setOrganizer(organizer);
      });
    if (currentUser)
      getRoleMember(event.club, currentUser, event.id).then((member) => {
        if (member !== "") setMember(true);

        setType(member);
      });

  }, [event, currentUser]);

  const handleJoin = (time) => {
    if (currentUser) {
      joinEvent(currentUser.uid, event.id, event.club, currentUser.email, time);
      setAnchorEl(null);
    } else history.push("/register");
  };

  const handleJoinClass = () => {
    if (currentUser) joinEventClass(currentUser.uid, event.id, event.club);
    else history.push("/register");
  };

  const handleJoinOrientation = () => {
    if (currentUser)
      joinEventOrientation(currentUser.uid, event.id, currentUser.email);
    else history.push("/register");
  };

  const handleJoinRogaine = () => {
    if (currentUser)
      joinEventRogaine(currentUser.uid, event.id, currentUser.email);
    else history.push("/register");
  };

  const handleEventClick = () => {
    history.push(`/event?club=${event.club}&uid=${event.id}`);
  };

  const handleProgress = () => {
    history.push(`/progress?club=${event.club}`);
  };

  const handleLeave = (uid, eventUid, club, isClass) => {
    leaveEvent(uid, eventUid, club, isClass);
  };

  const renderJoinRogaine = (event) => {

    return (
      <>
        <Button
          onClick={handleJoinOrientation}
          disabled={member && !organizer || event.members_orientation.length >= ( ["event4", "class2","event9"].includes(event.description) ? 180 : 100)}
          size="small"
          variant="outlined"
          sx={rogaine.box_4.card.button}
        >
          {event.members_orientation.length >= ( ["event4", "class2","event9"].includes(event.description) ? 180 : 100) ? "МЕСТ НЕТ" : "СПОРТИВНЫЙ ФОРМАТ"}

        </Button>

        <Button
          onClick={handleJoinRogaine}
          disabled={member && !organizer || event.members_orientation.length >= ( ["event4", "class2","event9"].includes(event.description) ? 70 : 50)}
          size="small"
          variant="outlined"
          sx={rogaine.box_4.card.button}
        >
          {event.members_orientation.length >= ( ["event4", "class2","event9"].includes(event.description) ? 180 : 100) ? "МЕСТ НЕТ" : "АКТИВНО-ПОЗНАВАТЕЛЬНЫЙ ФОРМАТ"}

        </Button>
      </>
    );
  };

  const renderJoin = () => {
    return (
      <Button
        onClick={handleClick}
        disabled={member && !organizer}
        size="small"
        variant="outlined"
        sx={rogaine.box_4.card.button}
      >
        ВЫБРАТЬ ТРЕНИРОВКУ
      </Button>
    );
  };

  const renderJoinSkating = (amount) => {
    return (
      <Button
        onClick={() => {
          handleJoin("");
        }}
        disabled={(member && !organizer) || amount === 0}
        size="small"
        variant="outlined"
        sx={rogaine.box_4.card.button}
      >
        {amount > 0 ? "ЗАБРОНИРОВАТЬ" : "МЕСТ НЕТ"}
      </Button>
    );
  };

  const renderProfile = () => {
    let text = "";
    let isRogaine = false;



    // eslint-disable-next-line default-case
    switch (type) {
      case "rogaine":
        isRogaine = true;
        text = "АКТИВНО-ПОЗНАВАТЕЛЬНЫЙ ФОРМАТ";
        break;
      case "orientation":
        isRogaine = true;
        text = "СПОРТИВНЫЙ ФОРМАТ";
        break;
    }

    return (
      <>
        {isRogaine ? (
          <Button
            disabled
            size="small"
            variant="outlined"
            sx={rogaine.box_4.card.button}
          >
            {text}
          </Button>
        ) : null}

        <Button
          onClick={() => {
            handleLeave(currentUser.uid, event.id, event.club, isClass);
          }}
          size="small"
          variant="outlined"
          sx={
            event.description === "event11"
                ? rogaine.box_5.card.button
                : rogaine.box_4.card.button
          }
        >
          ОТМЕНИТЬ ЗАПИСЬ
        </Button>
      </>
    );
  };

  const renderOpenControlPanel = () => {
    return (
      <>
        <Button
          onClick={handleEventClick}
          size="small"
          variant="outlined"
          sx={
            event.description === "event11"
                ? rogaine.box_5.card.button
                : rogaine.box_4.card.button
          }
        >
          ПАНЕЛЬ УПРАВЛЕНИЯ
        </Button>
        <Button
          onClick={handleProgress}
          size="small"
          variant="outlined"
          sx={
            event.description === "event11"
                ? rogaine.box_5.card.button
                : rogaine.box_4.card.button
          }
        >
          ПРОГРЕСС ПО ПОДАРКАМ
        </Button>
      </>
    );
  };

  const style = {
    backgroundColor: "#F3F3F3",
    px: 1,
    py: 2,
  };
  let card;

  const style1 = {
    backgroundColor: "#eb3333",
    px: 1,
    py: 2,
  };

  const renderJoinClass = (amount) => {
    return (
      <Button
        onClick={handleJoinClass}
        disabled={amount > 0 ? false : true}
        size="small"
        variant="outlined"
        sx={
          event.description === "event11"
            ? rogaine.box_5.card.button
            : rogaine.box_4.card.button
        }
      >
        {amount > 0 ? "ЗАБРОНИРОВАТЬ" : "МЕСТ НЕТ"}
      </Button>
    );
  };

  const renderOver = (event) => {


    return (
        <>
          <Button
              disabled
              size="small"
              variant="outlined"
              sx={rogaine.box_4.card.button}
          >
            Мероприятие окончилось
          </Button>

          {event.results ? (<Button
              component={Link}
              href={event.results}
              target="_blank"
              size="small"
              variant="outlined"
              sx={rogaine.box_4.card.button}
          >
            Результаты
          </Button>) : null}

        </>

    );
  };

  const renderActive = () => {
    return (
      <Button
        disabled
        size="small"
        variant="outlined"
        sx={rogaine.box_4.card.button}
      >
        Регистрация закрыта
      </Button>
    );
  };

  const schedule = {
    rogaine: [
      "10:30 - Регистрация участников",
      "11:40 - Предстартовый брифинг",
      "11:50 - Старт участников формата “Спортивный”",
      "12:00 - Старт участников формата “Активно-познавательный”",
      "14:00 - Финиш участников формата “Активно-познавательный”",
      "15:00 - Награждение участников",
    ],
    climbing: [
      "Урок Трудность 1 : 12.00-13.00",
      "Урок Трудность 2: 14.00-15.00",
      "Урок Трудность 3: 16.00-17.00",
      "Урок Боулдеринг 1: 11.00-13.00",
      "Урок Боулдеринг 2: 13.30-15.30",
      "Урок Боулдеринг 3: 16.00-18.00",
    ],
    class_rogaine: ["Мастер-класс по рогейну\n" + "Начало в 12.00"],
    class_special_rogaine: ["15:00 - Мастер-класс по скандинавской ходьбе от Дмитрия Губерниева"],
    class_special_climbing: ["Лекция по скалолазанию от Алексея Рубцова"],
    class_special_skating: ["12:00 - Мастер класс по фигурному катанию с Еленой Родионовой"],
    skating: [
      "10.30-12.20 - Групповые занятие с инструкторами ",
      "12.30-15.00 - Свободное катание",
    ],
  };

  let schedule_list;

  switch (event.club) {
    case "rogaine":
      schedule_list = schedule.rogaine;
      break;
    case "skating":
      schedule_list = schedule.skating;
      break;
    case "climbing":
      schedule_list = schedule.climbing;
      break;
  }
  let desc;

  if (event.isClass !== undefined && event.club === "rogaine"){
    schedule_list = schedule.class_rogaine
  }

  if (event.isClass !== undefined && event.club === "rogaine" && event.description === "event11"){
    schedule_list = schedule.class_special_rogaine
  }

  if (event.isClass !== undefined && event.club === "climbing" && event.description === "event11"){
    schedule_list = schedule.class_special_climbing
  }

  if (event.isClass !== undefined && event.club === "skating" && event.description === "event11"){
    schedule_list = schedule.class_special_skating
  }

  const renderDesc = (user,event) => {
    let obj = '';
    let isUser = false;

    function isUid(obj) {
      return obj.uid === event.id
    }

    try {

      if (event && user){
        obj = user.time.find(isUid)

      }

      if (user){
        isUser = true;
      }
    }catch (e) {

    }

    return (
        <Box sx={large ? { width: 550, px: 5 } : matches ? { width: 400, px: 5 } : { width: 320, px: 5 }}>
          <Stack direction="column">

                <Typography
                    textAlign="center"
                    sx={event.description === "event11" ? rogaine.box_5.card.headerText : rogaine.box_4.card.headerText}
                >
                  ПРОГРАММА
                </Typography>



            {isUser && event.club === "climbing" && event.isClass === undefined ? (<Typography sx={event.description === "event11" ? rogaine.box_5.card.primaryText : rogaine.box_4.card.primaryText}>
              {obj.time}
            </Typography>) : (
                schedule_list.map((row) => {
                  return (
                      <Typography sx={event.description === "event11" ? rogaine.box_5.card.primaryText : rogaine.box_4.card.primaryText}>
                        {row}
                      </Typography>
                  );
                })
            )}
          </Stack>
        </Box>
    );
  }

  try {
    desc = renderDesc(user,event);

    if (event.isClass !== undefined) {
      if (!organizer && !isProfile) sidePanel = renderJoinClass(event.amount);
    } else if (event.club === "rogaine") {
      if (!organizer && !isProfile) sidePanel = renderJoinRogaine(event);
    } else if (event.club === "climbing") {
      if (!organizer && !isProfile) sidePanel = renderJoin();
    } else if (event.club === "skating") {
      if (!organizer && !isProfile) sidePanel = renderJoinSkating(event.amount);
    }

    if (event.isActive && !organizer) sidePanel = renderActive();




    if (isProfile && !organizer) sidePanel = renderProfile();

    if (event.isOver && !organizer) sidePanel = renderOver(event);

    if (!member && organizer) sidePanel = renderOpenControlPanel();
  } catch (e) {
    console.log(e)
  }

  return (
    <Box sx={event.description === "event11" ? style1 : style}>
      <Stack direction={matches ? "row" : "column"} spacing={3}>
        <Box ml={2} mt={3}>
          <Typography
            sx={
              event.description === "event11"
                ? rogaine.box_5.card.dateText
                : rogaine.box_4.card.dateText
            }
          >
            {event.date}
          </Typography>
        </Box>

        {desc}



        <Stack
          sx={{ flexGrow: 1 }}
          spacing={3}
          direction="row"
          justifyContent="flex-end"
        >
          <Box sx={{flex:1}}>
            <Stack direction="column">
              {event.club === "rogaine" && event.isClass === undefined ? (
                <>
                  <Typography
                    textAlign="center"
                    sx={rogaine.box_4.card.headerText}
                  >
                    ПРАВИЛА
                  </Typography>
                  <Button
                    component={Link}
                    href="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%A0%D0%BE%D0%B3%D0%B5%D0%B8%CC%86%D0%BD%20%D0%92%D0%94%D0%9D%D0%A5%20(1).pdf?alt=media&token=af933737-497a-449c-9705-b61fbaa0f7e2"
                    size="small"
                    target="_blank"
                    variant="outlined"
                    sx={rogaine.box_4.card.button}
                  >
                    ПОЛОЖЕНИЕ О ПРОВЕДЕНИИ
                  </Button>
                </>
              ) : null}
            </Stack>
          </Box>

          <Box sx={{flex:1}}>
            <Stack direction="column">
              <Typography
                textAlign="center"
                sx={
                  event.description === "event11"
                    ? rogaine.box_5.card.headerText
                    : rogaine.box_4.card.headerText
                }
              >
                {organizer ? "АДМИН" : "УЧАСТВОВАТЬ"}
              </Typography>
              {sidePanel}
            </Stack>
            {/* <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {schedule_list.map((row, i) => {
                return (
                  <MenuItem
                    onClick={() => {
                      handleJoin(row);
                    }}
                    key={i}
                  >
                    <Typography
                      sx={{
                        color: "#929292",
                        fontSize: "15px",
                        letterSpacing: "1px",
                        fontFamily: "Gotham Pro",
                      }}
                    >
                      {row}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Menu> */}
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}

export default EventItem;
