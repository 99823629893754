import NavBar from "../NavBar";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Divider,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";

import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Footer from "../Footer";
import { useAuth } from "../../contexts/AuthContext";
import { Map, Placemark, YMaps } from "react-yandex-maps";
import { HashLink } from "react-router-hash-link";
import axios from "axios";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import PropTypes from "prop-types";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function Home() {
  const [value, setValue] = React.useState(0);
  const { currentUser } = useAuth();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const links = [
    {
      name: "13.11",
      items: [
        {
          link: "https://i.ibb.co/Zhh0x9Z/136A8934.jpg",
        },
        {
          link: "https://i.ibb.co/p3p5ht2/136A8935.jpg",
        },
        {
          link: "https://i.ibb.co/xGZrPCy/136A8946.jpg",
        },
        {
          link: "https://i.ibb.co/MDsskZ6/136A8984.jpg",
        },
        {
          link: "https://i.ibb.co/hF18XKy/136A8989.jpg",
        },
        {
          link: "https://i.ibb.co/HqQW8dR/136A8991.jpg",
        },
        {
          link: "https://i.ibb.co/cwJgxCM/136A8994.jpg",
        },
        {
          link: "https://i.ibb.co/M8hpV7t/136A8999.jpg",
        },
        {
          link: "https://i.ibb.co/jJ4qnDz/136A9010.jpg",
        },
        {
          link: "https://i.ibb.co/r7RGmg3/136A9011.jpg",
        },
        {
          link: "https://i.ibb.co/xSS72JZ/136A9015.jpg",
        },
        {
          link: "https://i.ibb.co/5140Y55/136A9018.jpg",
        },
        {
          link: "https://i.ibb.co/4jkZdLy/136A9021.jpg",
        },
        {
          link: "https://i.ibb.co/xq59ZdZ/136A9038.jpg",
        },
        {
          link: "https://i.ibb.co/K6pxRfn/136A9045.jpg",
        },
        {
          link: "https://i.ibb.co/X3xhDxj/136A9057.jpg",
        },
        {
          link: "https://i.ibb.co/ZLYCrBf/136A9063.jpg",
        },
        {
          link: "https://i.ibb.co/TbXR47p/136A9065.jpg",
        },
        {
          link: "https://i.ibb.co/3fjS39r/136A9070.jpg",
        },
        {
          link: "https://i.ibb.co/QdxPbKB/136A9078.jpg",
        },
        {
          link: "https://i.ibb.co/FsbVZWq/136A9088.jpg",
        },
        {
          link: "https://i.ibb.co/BNZZqps/136A9096.jpg",
        },
        {
          link: "https://i.ibb.co/JBkBgZm/136A9103.jpg",
        },
        {
          link: "https://i.ibb.co/yFwM3kf/136A9107.jpg",
        },
        {
          link: "https://i.ibb.co/F3HrcfT/136A9108.jpg",
        },
        {
          link: "https://i.ibb.co/JBsJJBq/136A9110.jpg",
        },
        {
          link: "https://i.ibb.co/7VQmJVC/136A9123.jpg",
        },
        {
          link: "https://i.ibb.co/Wxz30X8/136A9133.jpg",
        },
        {
          link: "https://i.ibb.co/3CkjJFR/136A9138.jpg",
        },
        {
          link: "https://i.ibb.co/ncW2GqZ/136A9145.jpg",
        },
        {
          link: "https://i.ibb.co/Cnh1f5W/136A9158.jpg",
        },
        {
          link: "https://i.ibb.co/4JkdWq8/136A9168.jpg",
        },
        {
          link: "https://i.ibb.co/fxjQkHJ/136A9178.jpg",
        },
        {
          link: "https://i.ibb.co/7W3JZnn/136A9184.jpg",
        },
        {
          link: "https://i.ibb.co/FbM09Cg/136A9199.jpg",
        },
        {
          link: "https://i.ibb.co/9N5MPzX/136A9202.jpg",
        },
        {
          link: "https://i.ibb.co/LphyQXh/136A9205.jpg",
        },
        {
          link: "https://i.ibb.co/t3r8K2S/136A9213.jpg",
        },
        {
          link: "https://i.ibb.co/rbd6JV0/136A9221.jpg",
        },
        {
          link: "https://i.ibb.co/mCs4c0w/136A9228.jpg",
        },
        {
          link: "https://i.ibb.co/688F2yS/136A9236.jpg",
        },
        {
          link: "https://i.ibb.co/tzFNv7k/136A9244.jpg",
        },
        {
          link: "https://i.ibb.co/JKPQWmD/136A9250.jpg",
        },
        {
          link: "https://i.ibb.co/wSjbWMm/136A9264.jpg",
        },
        {
          link: "https://i.ibb.co/8KPtZ24/136A9280.jpg",
        },
        {
          link: "https://i.ibb.co/vJ3566Z/136A9295.jpg",
        },
        {
          link: "https://i.ibb.co/Mnr6nBf/136A9318.jpg",
        },
        {
          link: "https://i.ibb.co/z5rmnMr/136A9327.jpg",
        },
        {
          link: "https://i.ibb.co/48yjpZc/136A9341.jpg",
        },
        {
          link: "https://i.ibb.co/Kx9FpyQ/136A9361.jpg",
        },
        {
          link: "https://i.ibb.co/HVHzj9h/136A9364.jpg",
        },
      ],
    },
    {
      name: "20.11",
      items: [
        {
          link: "https://i.ibb.co/PGGMWss/136A0003.jpg",
        },
        {
          link: "https://i.ibb.co/0GJSDrJ/136A0006.jpg",
        },
        {
          link: "https://i.ibb.co/VBh9hjq/136A0007.jpg",
        },
        {
          link: "https://i.ibb.co/NLHghjN/136A0008.jpg",
        },
        {
          link: "https://i.ibb.co/7Vs6964/136A0009.jpg",
        },
        {
          link: "https://i.ibb.co/jhb1qDn/136A0010.jpg",
        },
        {
          link: "https://i.ibb.co/r3Wh9SP/136A0017.jpg",
        },
        {
          link: "https://i.ibb.co/YpxtztX/136A0022.jpg",
        },
        {
          link: "https://i.ibb.co/bQ7q8h7/136A0026.jpg",
        },
        {
          link: "https://i.ibb.co/58GvSFY/136A0027.jpg",
        },
        {
          link: "https://i.ibb.co/6HcNDs1/136A0029.jpg",
        },
        {
          link: "https://i.ibb.co/Q9ZRZJT/136A0031.jpg",
        },
        {
          link: "https://i.ibb.co/X7Vxr2S/136A0039.jpg",
        },
        {
          link: "https://i.ibb.co/wwQLvXH/136A0041.jpg",
        },
        {
          link: "https://i.ibb.co/RNVvBXS/136A0042.jpg",
        },
        {
          link: "https://i.ibb.co/2WZFMsF/136A0050.jpg",
        },
        {
          link: "https://i.ibb.co/PDRnq8h/136A0053.jpg",
        },
        {
          link: "https://i.ibb.co/BP9F1bj/136A0057.jpg",
        },
        {
          link: "https://i.ibb.co/N3VsWBN/136A0061.jpg",
        },
        {
          link: "https://i.ibb.co/bW5Bb1f/136A0122.jpg",
        },
        {
          link: "https://i.ibb.co/Vw01HZh/136A0124.jpg",
        },
        {
          link: "https://i.ibb.co/474Fdwg/136A0128.jpg",
        },
        {
          link: "https://i.ibb.co/QJHtKwN/136A0130.jpg",
        },
        {
          link: "https://i.ibb.co/cLcSLzK/136A0133.jpg",
        },
        {
          link: "https://i.ibb.co/gdFmjSC/136A0143.jpg",
        },
        {
          link: "https://i.ibb.co/pLv69jp/136A0149.jpg",
        },
        {
          link: "https://i.ibb.co/09MkHk4/136A0155.jpg",
        },
        {
          link: "https://i.ibb.co/0CfRD3Q/136A0162.jpg",
        },
        {
          link: "https://i.ibb.co/NNXhwpF/136A0163.jpg",
        },
        {
          link: "https://i.ibb.co/g7NV5X0/136A0166.jpg",
        },
        {
          link: "https://i.ibb.co/TMM6sVL/136A0168.jpg",
        },
        {
          link: "https://i.ibb.co/n0TcbpW/136A0170.jpg",
        },
        {
          link: "https://i.ibb.co/6D4xTF9/136A0181.jpg",
        },
        {
          link: "https://i.ibb.co/QD32w9R/136A0186.jpg",
        },
        {
          link: "https://i.ibb.co/Z2gFdKw/136A0195.jpg",
        },
        {
          link: "https://i.ibb.co/gjq57jw/136A0198.jpg",
        },
        {
          link: "https://i.ibb.co/Rp7BC2J/136A0201.jpg",
        },
        {
          link: "https://i.ibb.co/YXshDCR/136A0213.jpg",
        },
        {
          link: "https://i.ibb.co/5GKNZ7b/136A0217.jpg",
        },
        {
          link: "https://i.ibb.co/3SKBY4q/136A0222.jpg",
        },
        {
          link: "https://i.ibb.co/gjjS583/136A0226.jpg",
        },
        {
          link: "https://i.ibb.co/swPMyDn/136A0251.jpg",
        },
        {
          link: "https://i.ibb.co/vP4tVGP/136A0256.jpg",
        },
        {
          link: "https://i.ibb.co/T2vKBXz/136A0260.jpg",
        },
        {
          link: "https://i.ibb.co/DMpk1bq/136A0262.jpg",
        },
        {
          link: "https://i.ibb.co/xFrkLyy/136A0266.jpg",
        },
        {
          link: "https://i.ibb.co/MNPYKxV/136A0270.jpg",
        },
        {
          link: "https://i.ibb.co/6HCqVrN/136A0273.jpg",
        },
        {
          link: "https://i.ibb.co/GRyjsmL/136A0281.jpg",
        },
        {
          link: "https://i.ibb.co/x5rXh0j/136A0285.jpg",
        },
        {
          link: "https://i.ibb.co/FzWyJMZ/136A0290.jpg",
        },
        {
          link: "https://i.ibb.co/NNwYXXY/136A0284.jpg",
        },
        {
          link: "https://i.ibb.co/Tt63VL6/136A0299.jpg",
        },
        {
          link: "https://i.ibb.co/r0C1Y6S/136A0301.jpg",
        },
        {
          link: "https://i.ibb.co/8bvc4SV/136A0306.jpg",
        },
        {
          link: "https://i.ibb.co/VTwJsJF/136A0309.jpg",
        },
        {
          link: "https://i.ibb.co/Z1RQWY2/136A0313.jpg",
        },
        {
          link: "https://i.ibb.co/1RLkPdh/136A0318.jpg",
        },
        {
          link: "https://i.ibb.co/nCgQyTY/136A0324.jpg",
        },
        {
          link: "https://i.ibb.co/cbtKWcc/136A0326.jpg",
        },
        {
          link: "https://i.ibb.co/X39Bj3n/136A0327.jpg",
        },
        {
          link: "https://i.ibb.co/SfvK9VG/136A0330.jpg",
        },
        {
          link: "https://i.ibb.co/kJmh2WJ/136A0331.jpg",
        },
        {
          link: "https://i.ibb.co/cL6xctC/136A0334.jpg",
        },
        {
          link: "https://i.ibb.co/pwGLjCR/136A0335.jpg",
        },
        {
          link: "https://i.ibb.co/477s9BJ/136A0338.jpg",
        },
        {
          link: "https://i.ibb.co/P47HhL6/136A0339.jpg",
        },
        {
          link: "https://i.ibb.co/9YDNfk8/136A0341.jpg",
        },
        {
          link: "https://i.ibb.co/DQ1HHVJ/136A0342.jpg",
        },
        {
          link: "https://i.ibb.co/grpBy6x/136A0344.jpg",
        },
        {
          link: "https://i.ibb.co/Y8dJkpm/136A0345.jpg",
        },
        {
          link: "https://i.ibb.co/h2VYkgv/136A0346.jpg",
        },
        {
          link: "https://i.ibb.co/1QyGK92/136A0347.jpg",
        },
        {
          link: "https://i.ibb.co/1rg1sSc/136A0348.jpg",
        },
        {
          link: "https://i.ibb.co/7KXCQ4q/136A0349.jpg",
        },
        {
          link: "https://i.ibb.co/hXQz23v/136A0350.jpg",
        },
        {
          link: "https://i.ibb.co/ZHWZNFK/136A0356.jpg",
        },
        {
          link: "https://i.ibb.co/sgCxQFz/136A0365.jpg",
        },
        {
          link: "https://i.ibb.co/vmnQGbj/136A0377.jpg",
        },
        {
          link: "https://i.ibb.co/nn41WZy/136A0385.jpg",
        },
        {
          link: "https://i.ibb.co/mbTXCBN/136A0393.jpg",
        },
        {
          link: "https://i.ibb.co/tx4kwp3/136A0411.jpg",
        },
        {
          link: "https://i.ibb.co/qkgDz4X/136A0413.jpg",
        },
        {
          link: "https://i.ibb.co/gDWj5mG/136A0416.jpg",
        },
        {
          link: "https://i.ibb.co/zR6sC9w/136A0420.jpg",
        },
        {
          link: "https://i.ibb.co/02zmGv2/136A0425.jpg",
        },
        {
          link: "https://i.ibb.co/VgDkR9w/136A0429.jpg",
        },
        {
          link: "https://i.ibb.co/g7N6Pzb/136A0432.jpg",
        },
        {
          link: "https://i.ibb.co/dJn3VLn/136A0436.jpg",
        },
        {
          link: "https://i.ibb.co/TKpvhS0/136A0438.jpg",
        },
        {
          link: "https://i.ibb.co/wJHHYt6/136A0445.jpg",
        },
        {
          link: "https://i.ibb.co/VWb8Wst/136A0453.jpg",
        },
        {
          link: "https://i.ibb.co/kGCVxs6/136A0457.jpg",
        },
        {
          link: "https://i.ibb.co/CMBTHbJ/136A0465.jpg",
        },
        {
          link: "https://i.ibb.co/RNMwFRg/136A0467.jpg",
        },
        {
          link: "https://i.ibb.co/f4Pv6pL/136A0470.jpg",
        },
        {
          link: "https://i.ibb.co/ZRsYgRs/136A0473.jpg",
        },
        {
          link: "https://i.ibb.co/qrB3FQs/136A0478.jpg",
        },
        {
          link: "https://i.ibb.co/bRSpCHt/136A0483.jpg",
        },
        {
          link: "https://i.ibb.co/Bt9xt8C/136A0488.jpg",
        },
        {
          link: "https://i.ibb.co/smgp0mg/136A0494.jpg",
        },
        {
          link: "https://i.ibb.co/v1SXQ9k/136A0509.jpg",
        },
        {
          link: "https://i.ibb.co/gg6JchB/136A0517.jpg",
        },
        {
          link: "https://i.ibb.co/PgVvK5J/136A0521.jpg",
        },
        {
          link: "https://i.ibb.co/wNTbDSz/136A0524.jpg",
        },
        {
          link: "https://i.ibb.co/M5FQ2Mz/136A0529.jpg",
        },
        {
          link: "https://i.ibb.co/kmqgZMZ/136A0533.jpg",
        },
      ],
    },
    {
      name: "21.11",
      items: [
        {
          link: "https://i.ibb.co/QnHJmBj/136A1173.jpg",
        },
        {
          link: "https://i.ibb.co/pbnMwnt/136A1174.jpg",
        },
        {
          link: "https://i.ibb.co/7WSCsHd/136A1180.jpg",
        },
        {
          link: "https://i.ibb.co/kJP06qd/136A1204.jpg",
        },
        {
          link: "https://i.ibb.co/bKpy02w/136A1207.jpg",
        },
        {
          link: "https://i.ibb.co/fG5pftD/136A1214.jpg",
        },
        {
          link: "https://i.ibb.co/zHvvHXt/136A1226.jpg",
        },
        {
          link: "https://i.ibb.co/4TC6BN0/136A1232.jpg",
        },
        {
          link: "https://i.ibb.co/qBvKMxc/136A1239.jpg",
        },
        {
          link: "https://i.ibb.co/T15HpKh/136A1261.jpg",
        },
        {
          link: "https://i.ibb.co/6BH0yWm/136A1268.jpg",
        },
        {
          link: "https://i.ibb.co/cLgtR5B/136A1273.jpg",
        },
        {
          link: "https://i.ibb.co/b7S9Jpn/136A1297.jpg",
        },
        {
          link: "https://i.ibb.co/Bg3RnwR/136A1303.jpg",
        },
        {
          link: "https://i.ibb.co/YT8N1qN/136A1314.jpg",
        },
        {
          link: "https://i.ibb.co/F80Cnq6/136A1326.jpg",
        },
        {
          link: "https://i.ibb.co/qNBFYNB/136A1343.jpg",
        },
        {
          link: "https://i.ibb.co/ZNLCDJY/136A1351.jpg",
        },
        {
          link: "https://i.ibb.co/7rqqb5w/136A1364.jpg",
        },
        {
          link: "https://i.ibb.co/JyHJqk7/136A1486.jpg",
        },
        {
          link: "https://i.ibb.co/HxRnVZn/136A1490.jpg",
        },
        {
          link: "https://i.ibb.co/PZQYBrS/136A1492.jpg",
        },
        {
          link: "https://i.ibb.co/5hzDtD6/136A1493.jpg",
        },
        {
          link: "https://i.ibb.co/6vLSNSJ/136A1521.jpg",
        },
        {
          link: "https://i.ibb.co/vP6CCNP/136A1558.jpg",
        },
        {
          link: "https://i.ibb.co/t4GF5ns/136A1570.jpg",
        },
        {
          link: "https://i.ibb.co/dQTzG4H/136A1590.jpg",
        },
        {
          link: "https://i.ibb.co/L8SQTMZ/136A1596.jpg",
        },
        {
          link: "https://i.ibb.co/CBG2wsd/136A1674.jpg",
        },
        {
          link: "https://i.ibb.co/SNDG7vV/136A1680.jpg",
        },
        {
          link: "https://i.ibb.co/hXqPcgH/136A1709.jpg",
        },
        {
          link: "https://i.ibb.co/rpDs73Y/136A1719.jpg",
        },
        {
          link: "https://i.ibb.co/1r0JgDK/136A1743.jpg",
        },
        {
          link: "https://i.ibb.co/9q8hV4n/136A1773.jpg",
        },
        {
          link: "https://i.ibb.co/t8TR50f/136A1797.jpg",
        },
        {
          link: "https://i.ibb.co/dDb5RWZ/136A1843.jpg",
        },
        {
          link: "https://i.ibb.co/g9j1gw1/136A1912.jpg",
        },
        {
          link: "https://i.ibb.co/jZqTjym/136A1937.jpg",
        },
        {
          link: "https://i.ibb.co/hdYRLzR/136A1938.jpg",
        },
        {
          link: "https://i.ibb.co/Jmzdt9y/136A1949.jpg",
        },
        {
          link: "https://i.ibb.co/GPgTdFk/136A1971.jpg",
        },
        {
          link: "https://i.ibb.co/q5Y7jZK/136A2001.jpg",
        },
        {
          link: "https://i.ibb.co/HB3dkcv/136A2040.jpg",
        },
        {
          link: "https://i.ibb.co/9cC0XdV/136A2110.jpg",
        },
        {
          link: "https://i.ibb.co/0XJsvM3/136A2124.jpg",
        },
        {
          link: "https://i.ibb.co/D7FC0Q2/136A2198.jpg",
        },
      ],
    },
    {
      name: "27.11",
      items: [
        {
          link: "https://i.ibb.co/NLdCtm7/CHB-4023.jpg",
        },
        {
          link: "https://i.ibb.co/ZNy4bGB/CHB-4184.jpg",
        },
        {
          link: "https://i.ibb.co/PNz2Zvc/CHB-4876.jpg",
        },
        {
          link: "https://i.ibb.co/vBnGrHR/CHB-5170.jpg",
        },
        {
          link: "https://i.ibb.co/89Dfh9m/CHB-5555.jpg",
        },
        {
          link: "https://i.ibb.co/njjF4MV/CHB-5632.jpg",
        },
        {
          link: "https://i.ibb.co/QMcp7Z7/CHB-6263.jpg",
        },
        {
          link: "https://i.ibb.co/hDp1r10/CHB-6693.jpg",
        },
        {
          link: "https://i.ibb.co/b6BVvKL/DSC-1098.jpg",
        },
        {
          link: "https://i.ibb.co/pJsb5cw/DSC-1220.jpg",
        },
        {
          link: "https://i.ibb.co/VgNm7MP/DSC-1439.jpg",
        },
        {
          link: "https://i.ibb.co/Fw2wQMK/DSC-1510.jpg",
        },
        {
          link: "https://i.ibb.co/fd85YVK/DSC-1517.jpg",
        },
        {
          link: "https://i.ibb.co/DgXtdFM/DSC-2103.jpg",
        },
        {
          link: "https://i.ibb.co/SJ6pFg8/DSC-2339.jpg",
        },
        {
          link: "https://i.ibb.co/4FGMNWj/vdnh-001.jpg",
        },
        {
          link: "https://i.ibb.co/G2PPrhD/vdnh-002.jpg",
        },
        {
          link: "https://i.ibb.co/54TZJfS/vdnh-003.jpg",
        },
        {
          link: "https://i.ibb.co/5WcSmDc/vdnh-007.jpg",
        },
        {
          link: "https://i.ibb.co/ZxcFGnp/vdnh-009.jpg",
        },
        {
          link: "https://i.ibb.co/HNZXPsK/vdnh-011.jpg",
        },
        {
          link: "https://i.ibb.co/bRgv7xB/vdnh-012.jpg",
        },
        {
          link: "https://i.ibb.co/pJRjR3d/vdnh-013.jpg",
        },
        {
          link: "https://i.ibb.co/CBGVVwD/vdnh-026.jpg",
        },
        {
          link: "https://i.ibb.co/fHgBCFg/vdnh-027.jpg",
        },
        {
          link: "https://i.ibb.co/dB44X2r/vdnh-032.jpg",
        },
        {
          link: "https://i.ibb.co/2j8ZWDC/vdnh-036.jpg",
        },
        {
          link: "https://i.ibb.co/sPHcvJW/vdnh-043.jpg",
        },
        {
          link: "https://i.ibb.co/5BfxkZd/vdnh-047.jpg",
        },
        {
          link: "https://i.ibb.co/w4MmjTy/vdnh-055.jpg",
        },
        {
          link: "https://i.ibb.co/rwHmgSK/vdnh-058.jpg",
        },
        {
          link: "https://i.ibb.co/JzsfPgG/vdnh-066.jpg",
        },
        {
          link: "https://i.ibb.co/cvwjdKp/vdnh-071.jpg",
        },
        {
          link: "https://i.ibb.co/2npFtNG/vdnh-075.jpg",
        },
        {
          link: "https://i.ibb.co/zQ9nz5S/vdnh-081.jpg",
        },
        {
          link: "https://i.ibb.co/VxjTth4/vdnh-091.jpg",
        },
        {
          link: "https://i.ibb.co/xsg1bSb/vdnh-092.jpg",
        },
        {
          link: "https://i.ibb.co/7rSc5CB/vdnh-096.jpg",
        },
        {
          link: "https://i.ibb.co/jL3hqCH/vdnh-099.jpg",
        },
        {
          link: "https://i.ibb.co/xgxcnkJ/vdnh-103.jpg",
        },
        {
          link: "https://i.ibb.co/bFqHJdx/vdnh-108.jpg",
        },
        {
          link: "https://i.ibb.co/0J6Wmkz/vdnh-119.jpg",
        },
        {
          link: "https://i.ibb.co/LtPhTv4/vdnh-123.jpg",
        },
        {
          link: "https://i.ibb.co/gPkLS2P/vdnh-126.jpg",
        },
        {
          link: "https://i.ibb.co/5c03J9F/vdnh-130.jpg",
        },
        {
          link: "https://i.ibb.co/1TFtVxW/vdnh-135.jpg",
        },
        {
          link: "https://i.ibb.co/TqTGkc9/vdnh-141.jpg",
        },
        {
          link: "https://i.ibb.co/QjhSMwr/vdnh-146.jpg",
        },
        {
          link: "https://i.ibb.co/yy3dwNQ/vdnh-244.jpg",
        },
        {
          link: "https://i.ibb.co/Qf8YBb6/vdnh-249.jpg",
        },
        {
          link: "https://i.ibb.co/H7cWHk7/vdnh-273.jpg",
        },
        {
          link: "https://i.ibb.co/JRrtsrL/vdnh-291.jpg",
        },
        {
          link: "https://i.ibb.co/x8Dv7Qm/vdnh-305.jpg",
        },
        {
          link: "https://i.ibb.co/jW3RXc2/vdnh-342.jpg",
        },
        {
          link: "https://i.ibb.co/3M734MT/vdnh-348.jpg",
        },
        {
          link: "https://i.ibb.co/JKmjQxd/vdnh-350.jpg",
        },
        {
          link: "https://i.ibb.co/jJp3N8h/vdnh-358.jpg",
        },
        {
          link: "https://i.ibb.co/tsfJDzC/vdnh-359.jpg",
        },
        {
          link: "https://i.ibb.co/wMnM9Kw/vdnh-368.jpg",
        },
        {
          link: "https://i.ibb.co/31d2gs4/vdnh-388.jpg",
        },
        {
          link: "https://i.ibb.co/m0m0bSJ/vdnh-393.jpg",
        },
        {
          link: "https://i.ibb.co/df8mX4y/vdnh-412.jpg",
        },
        {
          link: "https://i.ibb.co/T2dFsRs/vdnh-419.jpg",
        },
        {
          link: "https://i.ibb.co/BGzdBQ6/vdnh-432.jpg",
        },
      ],
    },
    {
      name: "28.11",
      items: [
        {
          link: "https://i.ibb.co/cD6hJzL/CC-3972.jpg",
        },
        {
          link: "https://i.ibb.co/HVGHNNh/CC-3988.jpg",
        },
        {
          link: "https://i.ibb.co/L1V4P9T/CC-3534.jpg",
        },
        {
          link: "https://i.ibb.co/r3qn8S5/CC-4164.jpg",
        },
        {
          link: "https://i.ibb.co/Rcw5zR5/Mossport-3212.jpg",
        },
        {
          link: "https://i.ibb.co/C2wVfnt/Mossport-3235.jpg",
        },
        {
          link: "https://i.ibb.co/MgqMFF4/Mossport-3300.jpg",
        },
        {
          link: "https://i.ibb.co/JFBcx3D/Mossport-3312.jpg",
        },
        {
          link: "https://i.ibb.co/FDWDCNj/Mossport-3404.jpg",
        },
        {
          link: "https://i.ibb.co/1zWCBff/Mossport-3424.jpg",
        },
        {
          link: "https://i.ibb.co/MMbCtMP/Mossport-3425.jpg",
        },
        {
          link: "https://i.ibb.co/MCS4ys3/Mossport-3439.jpg",
        },
        {
          link: "https://i.ibb.co/JnxCsrM/Mossport-3446.jpg",
        },
        {
          link: "https://i.ibb.co/56H1tv0/Mossport-3490.jpg",
        },
        {
          link: "https://i.ibb.co/vwcCpFr/Mossport-3514.jpg",
        },
        {
          link: "https://i.ibb.co/ZcQDDWc/Mossport-3523.jpg",
        },
        {
          link: "https://i.ibb.co/8P5h27N/Mossport-3526.jpg",
        },
        {
          link: "https://i.ibb.co/LRvwd82/Mossport-3527.jpg",
        },
        {
          link: "https://i.ibb.co/1KKs1Hc/Mossport-3531.jpg",
        },
        {
          link: "https://i.ibb.co/LCKfNcC/Mossport-3540-2.jpg",
        },
        {
          link: "https://i.ibb.co/ypf151m/Mossport-3572.jpg",
        },
        {
          link: "https://i.ibb.co/FHpkpM9/Mossport-3585.jpg",
        },
        {
          link: "https://i.ibb.co/pK8tYGP/Mossport-3590.jpg",
        },
        {
          link: "https://i.ibb.co/nQdh01M/Mossport-3602-2.jpg",
        },
        {
          link: "https://i.ibb.co/qjmc81s/Mossport-3616.jpg",
        },
        {
          link: "https://i.ibb.co/c8JymXx/Mossport-3619.jpg",
        },
        {
          link: "https://i.ibb.co/QCw6WRb/Mossport-3623.jpg",
        },
        {
          link: "https://i.ibb.co/DLFY4CX/Mossport-3625.jpg",
        },
        {
          link: "https://i.ibb.co/k8mRvj4/Mossport-3626-2.jpg",
        },
        {
          link: "https://i.ibb.co/4M0GBJx/Mossport-3667.jpg",
        },
        {
          link: "https://i.ibb.co/b71PWqH/Mossport-3669.jpg",
        },
        {
          link: "https://i.ibb.co/sKpZW8r/Mossport-3676.jpg",
        },
        {
          link: "https://i.ibb.co/nCrCtHk/Mossport-3685.jpg",
        },
        {
          link: "https://i.ibb.co/kKdZCJp/Mossport-3696.jpg",
        },
        {
          link: "https://i.ibb.co/GnvpRf1/Mossport-3739.jpg",
        },
        {
          link: "https://i.ibb.co/Qk5Ds02/Mossport-3758.jpg",
        },
        {
          link: "https://i.ibb.co/XpgmFff/Mossport-3775.jpg",
        },
        {
          link: "https://i.ibb.co/bKnhbcS/Mossport-3845.jpg",
        },
        {
          link: "https://i.ibb.co/P4YKy4K/Mossport-3858.jpg",
        },
        {
          link: "https://i.ibb.co/C8BD99y/Mossport-3862.jpg",
        },
        {
          link: "https://i.ibb.co/P47bGx7/Mossport-3878.jpg",
        },
        {
          link: "https://i.ibb.co/XjK1kVv/Mossport-3929.jpg",
        },
        {
          link: "https://i.ibb.co/C5XpTfk/Mossport-3976.jpg",
        },
        {
          link: "https://i.ibb.co/hmMNP90/Mossport-4001.jpg",
        },
        {
          link: "https://i.ibb.co/qygRFqc/Mossport-4025.jpg",
        },
        {
          link: "https://i.ibb.co/GtZyhcq/Mossport-4058.jpg",
        },
        {
          link: "https://i.ibb.co/dPMxTJr/Mossport-4075.jpg",
        },
        {
          link: "https://i.ibb.co/j5c9jjW/Mossport-4086.jpg",
        },
        {
          link: "https://i.ibb.co/DLLYhY1/Mossport-4094.jpg",
        },
        {
          link: "https://i.ibb.co/gwSzQVn/Mossport-4146.jpg",
        },
        {
          link: "https://i.ibb.co/D8RQ3N5/Mossport-4158.jpg",
        },
        {
          link: "https://i.ibb.co/YLJv2G2/Mossport-4188.jpg",
        },
        {
          link: "https://i.ibb.co/YfvJC26/Mossport-4191.jpg",
        },
        {
          link: "https://i.ibb.co/HYMmD8m/Mossport-4197.jpg",
        },
        {
          link: "https://i.ibb.co/KF2nsRX/Mossport-4200.jpg",
        },
        {
          link: "https://i.ibb.co/X22cGPH/Mossport-4221.jpg",
        },
        {
          link: "https://i.ibb.co/txzCNrB/C-3502.jpg",
        },
        {
          link: "https://i.ibb.co/D5bt0tt/C-3687.jpg",
        },
        {
          link: "https://i.ibb.co/zRsvDbm/C-3753.jpg",
        },
      ],
    },
    {
      name: "02.12",
      items: [
        {
          link: "https://i.ibb.co/YBmC9cs/136A0016.jpg",
        },
        {
          link: "https://i.ibb.co/b3PkSd9/136A0021.jpg",
        },
        {
          link: "https://i.ibb.co/2gJy8XZ/136A0035.jpg",
        },
        {
          link: "https://i.ibb.co/ChdLD8S/136A0048.jpg",
        },
        {
          link: "https://i.ibb.co/P4HK7F3/136A0058.jpg",
        },
        {
          link: "https://i.ibb.co/qNPsHq6/136A0064.jpg",
        },
        {
          link: "https://i.ibb.co/YjRKPXS/136A0067.jpg",
        },
        {
          link: "https://i.ibb.co/gTLwS63/136A0068.jpg",
        },
        {
          link: "https://i.ibb.co/yQjC5fC/136A0074.jpg",
        },
        {
          link: "https://i.ibb.co/9qW9ZnB/136A0080.jpg",
        },
        {
          link: "https://i.ibb.co/mh9WSCr/136A0094.jpg",
        },
        {
          link: "https://i.ibb.co/0mq62tc/136A0110.jpg",
        },
        {
          link: "https://i.ibb.co/9gxPxXt/136A0125.jpg",
        },
        {
          link: "https://i.ibb.co/TYzqjsc/136A0138.jpg",
        },
        {
          link: "https://i.ibb.co/dPBv5pv/136A0150.jpg",
        },
        {
          link: "https://i.ibb.co/HKj8dDP/136A0166.jpg",
        },
        {
          link: "https://i.ibb.co/Vq2kW3B/136A0167.jpg",
        },
        {
          link: "https://i.ibb.co/JvkMVhY/136A0199.jpg",
        },
        {
          link: "https://i.ibb.co/k1r8P3v/136A0201.jpg",
        },
        {
          link: "https://i.ibb.co/pv5Qnkn/136A0247.jpg",
        },
        {
          link: "https://i.ibb.co/mFZScK1/136A0261.jpg",
        },
        {
          link: "https://i.ibb.co/7JR5YQZ/136A0270.jpg",
        },
        {
          link: "https://i.ibb.co/J7sN6sH/136A0287.jpg",
        },
        {
          link: "https://i.ibb.co/CnmvpJQ/136A0288.jpg",
        },
        {
          link: "https://i.ibb.co/CmY0Thw/136A0295.jpg",
        },
        {
          link: "https://i.ibb.co/02JVDx8/136A0313.jpg",
        },
        {
          link: "https://i.ibb.co/YQvd3ck/136A0359.jpg",
        },
        {
          link: "https://i.ibb.co/1msqQ3h/136A0373.jpg",
        },
        {
          link: "https://i.ibb.co/YbW6GbK/136A0380.jpg",
        },
        {
          link: "https://i.ibb.co/py7HHZM/136A0387.jpg",
        },
        {
          link: "https://i.ibb.co/68PV7B0/136A0463.jpg",
        },
        {
          link: "https://i.ibb.co/vH6B7Dx/136A0473.jpg",
        },
        {
          link: "https://i.ibb.co/7tq04Dh/136A0499.jpg",
        },
        {
          link: "https://i.ibb.co/zhcnjBv/136A0514.jpg",
        },
        {
          link: "https://i.ibb.co/Ptzw9Hw/136A0525.jpg",
        },
        {
          link: "https://i.ibb.co/BnX1V9z/136A0554.jpg",
        },
        {
          link: "https://i.ibb.co/KFjxxc9/136A0559.jpg",
        },
        {
          link: "https://i.ibb.co/jG6xkGS/136A0630.jpg",
        },
        {
          link: "https://i.ibb.co/2t8RGQQ/136A0644.jpg",
        },
        {
          link: "https://i.ibb.co/QnzJfTy/136A0646.jpg",
        },
        {
          link: "https://i.ibb.co/zZ5m7RT/136A0652.jpg",
        },
      ],
    },
    {
      name: "03.12",
      items: [
        {
          link: "https://i.ibb.co/Wpwpxtp/136A0683.jpg",
        },
        {
          link: "https://i.ibb.co/q5MnQTy/136A0689.jpg",
        },
        {
          link: "https://i.ibb.co/khN01w0/136A0708.jpg",
        },
        {
          link: "https://i.ibb.co/mc2H0L5/136A0715.jpg",
        },
        {
          link: "https://i.ibb.co/sqs8Tt9/136A0717.jpg",
        },
        {
          link: "https://i.ibb.co/gzcdGXP/136A0742.jpg",
        },
        {
          link: "https://i.ibb.co/h9y86s7/136A0753.jpg",
        },
        {
          link: "https://i.ibb.co/s5xKtQp/136A0757.jpg",
        },
        {
          link: "https://i.ibb.co/WVSfYFH/136A0766.jpg",
        },
        {
          link: "https://i.ibb.co/S54k4Lp/136A0775.jpg",
        },
        {
          link: "https://i.ibb.co/jvNvMJJ/136A0779.jpg",
        },
        {
          link: "https://i.ibb.co/myZj6TN/136A0787.jpg",
        },
        {
          link: "https://i.ibb.co/sKFPx01/136A0789.jpg",
        },
        {
          link: "https://i.ibb.co/JRpLRdG/136A0796.jpg",
        },
        {
          link: "https://i.ibb.co/2KsXSD0/136A0803.jpg",
        },
        {
          link: "https://i.ibb.co/sQz7x0g/136A0848.jpg",
        },
        {
          link: "https://i.ibb.co/wM1JzzM/136A0863.jpg",
        },
        {
          link: "https://i.ibb.co/ZY1wNKF/136A0869.jpg",
        },
        {
          link: "https://i.ibb.co/TwF5SYD/136A0921.jpg",
        },
        {
          link: "https://i.ibb.co/zx2GcwV/136A0944.jpg",
        },
        {
          link: "https://i.ibb.co/YtG2X9X/136A0953.jpg",
        },
        {
          link: "https://i.ibb.co/DfFRW5y/136A0983.jpg",
        },
        {
          link: "https://i.ibb.co/K9m77V8/136A1005.jpg",
        },
        {
          link: "https://i.ibb.co/B3KQVqH/136A1059.jpg",
        },
        {
          link: "https://i.ibb.co/wdn3FDN/136A1066.jpg",
        },
        {
          link: "https://i.ibb.co/R3k6Cpx/136A1075.jpg",
        },
        {
          link: "https://i.ibb.co/jbG0fRw/136A1108.jpg",
        },
        {
          link: "https://i.ibb.co/6F5rK9d/136A1153.jpg",
        },
        {
          link: "https://i.ibb.co/w7Hyfk9/136A1200.jpg",
        },
        {
          link: "https://i.ibb.co/7vgCd1M/136A1240.jpg",
        },
        {
          link: "https://i.ibb.co/y04TYZ0/136A1245.jpg",
        },
        {
          link: "https://i.ibb.co/F7WkLGL/136A1259.jpg",
        },
        {
          link: "https://i.ibb.co/nrgDF4f/136A1276.jpg",
        },
        {
          link: "https://i.ibb.co/JBb083z/136A1284.jpg",
        },
        {
          link: "https://i.ibb.co/bJNscn1/136A1299.jpg",
        },
        {
          link: "https://i.ibb.co/TBTcF9z/136A1320.jpg",
        },
        {
          link: "https://i.ibb.co/rm8QT8z/136A1323.jpg",
        },
        {
          link: "https://i.ibb.co/d2rFz8R/136A1346.jpg",
        },
      ],
    },
    {
      name: "4.12",
      items: [
        {
          link: "https://i.ibb.co/0CpNXC6/136A1368.jpg",
        },
        {
          link: "https://i.ibb.co/56kGng9/136A1370.jpg",
        },
        {
          link: "https://i.ibb.co/FbXYjjp/136A1373.jpg",
        },
        {
          link: "https://i.ibb.co/B6BQnvc/136A1379.jpg",
        },
        {
          link: "https://i.ibb.co/GkXcQyN/136A1391.jpg",
        },
        {
          link: "https://i.ibb.co/ynZn0sF/136A1394.jpg",
        },
        {
          link: "https://i.ibb.co/bgDypMT/136A1398.jpg",
        },
        {
          link: "https://i.ibb.co/qy9b5r5/136A1399.jpg",
        },
        {
          link: "https://i.ibb.co/DR6Zp00/136A1410.jpg",
        },
        {
          link: "https://i.ibb.co/5ctZFWY/136A1416.jpg",
        },
        {
          link: "https://i.ibb.co/YB6cYmk/136A1433.jpg",
        },
        {
          link: "https://i.ibb.co/y6WYsfN/136A1442.jpg",
        },
        {
          link: "https://i.ibb.co/vDzBtLL/136A1460.jpg",
        },
        {
          link: "https://i.ibb.co/nDKbW77/136A1473.jpg",
        },
        {
          link: "https://i.ibb.co/CKbhHGP/136A1510.jpg",
        },
        {
          link: "https://i.ibb.co/VqpHtnW/136A1519.jpg",
        },
        {
          link: "https://i.ibb.co/2n7xQXY/136A1525.jpg",
        },
        {
          link: "https://i.ibb.co/MNrvBGy/136A1527.jpg",
        },
        {
          link: "https://i.ibb.co/jb5tc7x/136A1544.jpg",
        },
        {
          link: "https://i.ibb.co/QFj0hP5/136A1602.jpg",
        },
        {
          link: "https://i.ibb.co/LNbYKfK/136A1609.jpg",
        },
        {
          link: "https://i.ibb.co/dgKDG68/136A1615.jpg",
        },
        {
          link: "https://i.ibb.co/YNF3cmT/136A1645.jpg",
        },
        {
          link: "https://i.ibb.co/8dvjy37/136A1652.jpg",
        },
        {
          link: "https://i.ibb.co/hyvW0yb/136A1658.jpg",
        },
      ],
    },
    {
      name: "9.12",
      items: [
        {
          link: "https://i.ibb.co/JtqBbzP/136A1748.jpg",
        },
        {
          link: "https://i.ibb.co/WfxKR6Y/136A1756.jpg",
        },
        {
          link: "https://i.ibb.co/FWjXTs7/136A1762.jpg",
        },
        {
          link: "https://i.ibb.co/8crJ3RH/136A1778.jpg",
        },
        {
          link: "https://i.ibb.co/C1sy9Y1/136A1782.jpg",
        },
        {
          link: "https://i.ibb.co/ZzGZH53/136A1794.jpg",
        },
        {
          link: "https://i.ibb.co/Sr0DsDv/136A1809.jpg",
        },
        {
          link: "https://i.ibb.co/g6cgb1t/136A1812.jpg",
        },
        {
          link: "https://i.ibb.co/VMcQGMB/136A1830.jpg",
        },
        {
          link: "https://i.ibb.co/TrMLVrY/136A1831.jpg",
        },
        {
          link: "https://i.ibb.co/HdsKGGB/136A1837.jpg",
        },
        {
          link: "https://i.ibb.co/ZftJQq5/136A1838.jpg",
        },
        {
          link: "https://i.ibb.co/2yyJq66/136A1844.jpg",
        },
        {
          link: "https://i.ibb.co/sRxQTvv/136A1858.jpg",
        },
        {
          link: "https://i.ibb.co/n3Jg3Qv/136A1871.jpg",
        },
        {
          link: "https://i.ibb.co/cFS7YPz/136A1875.jpg",
        },
        {
          link: "https://i.ibb.co/rbLjY6P/136A1878.jpg",
        },
        {
          link: "https://i.ibb.co/ZVKrDnL/136A1885.jpg",
        },
        {
          link: "https://i.ibb.co/FsN0q40/136A1920.jpg",
        },
        {
          link: "https://i.ibb.co/QK310qK/136A2204.jpg",
        },
        {
          link: "https://i.ibb.co/Tg3QhS1/136A2207.jpg",
        },
        {
          link: "https://i.ibb.co/5WS3VsJ/136A2246.jpg",
        },
        {
          link: "https://i.ibb.co/BfT3Y9L/136A2259.jpg",
        },
        {
          link: "https://i.ibb.co/VphFMjv/136A2269.jpg",
        },
        {
          link: "https://i.ibb.co/Y7hb9SW/136A2285.jpg",
        },
        {
          link: "https://i.ibb.co/VLsd4wZ/136A2293.jpg",
        },
        {
          link: "https://i.ibb.co/QkYm3DH/136A2302.jpg",
        },
        {
          link: "https://i.ibb.co/YTgnThV/136A2308.jpg",
        },
        {
          link: "https://i.ibb.co/PWwjxHS/136A2312.jpg",
        },
        {
          link: "https://i.ibb.co/MGj0GHQ/136A2323.jpg",
        },
        {
          link: "https://i.ibb.co/tX9vy6V/136A2327.jpg",
        },
        {
          link: "https://i.ibb.co/ck5Fhyj/136A2337.jpg",
        },
        {
          link: "https://i.ibb.co/DYKwZk9/136A2359.jpg",
        },
        {
          link: "https://i.ibb.co/Dth10gC/136A2479.jpg",
        },
        {
          link: "https://i.ibb.co/qjcYhbK/136A2498.jpg",
        },
        {
          link: "https://i.ibb.co/hDXkgQv/136A2504.jpg",
        },
        {
          link: "https://i.ibb.co/71bcmcr/136A2508.jpg",
        },
        {
          link: "https://i.ibb.co/yy54W9M/136A2522.jpg",
        },
      ],
    },
    {
      name: "10.12",
      items: [
        {
          link: "https://i.ibb.co/jWV2V6C/136-A2544.jpg",
        },
        {
          link: "https://i.ibb.co/jWV2V6C/136-A2544.jpg",
        },
        {
          link: "https://i.ibb.co/VHFTtL5/136A2560.jpg",
        },
        {
          link: "https://i.ibb.co/BZWX7m7/136-A2585.jpg",
        },
        {
          link: "https://i.ibb.co/BZWX7m7/136-A2585.jpg",
        },
        {
          link: "https://i.ibb.co/bXcB20V/136-A2591.jpg",
        },
        {
          link: "https://i.ibb.co/bXcB20V/136-A2591.jpg",
        },
        {
          link: "https://i.ibb.co/1sDVkjK/136A2598.jpg",
        },
        {
          link: "https://i.ibb.co/Fxrj5Gy/136A2613.jpg",
        },
        {
          link: "https://i.ibb.co/K0njQhw/136A2643.jpg",
        },
        {
          link: "https://i.ibb.co/5v0cY4T/136A2659.jpg",
        },
        {
          link: "https://i.ibb.co/ggSRPQQ/136A2670.jpg",
        },
        {
          link: "https://i.ibb.co/x8yznqM/136A2673.jpg",
        },
        {
          link: "https://i.ibb.co/vw8GJhh/136A2681.jpg",
        },
        {
          link: "https://i.ibb.co/Dwf019J/136A2685.jpg",
        },
        {
          link: "https://i.ibb.co/FnVfpNv/136A2696.jpg",
        },
        {
          link: "https://i.ibb.co/bvVWW3T/136A2702.jpg",
        },
        {
          link: "https://i.ibb.co/W5D0Wm9/136A2704.jpg",
        },
        {
          link: "https://i.ibb.co/G9tL5qs/136A2709.jpg",
        },
        {
          link: "https://i.ibb.co/fM2PMvd/136A2716.jpg",
        },
        {
          link: "https://i.ibb.co/qJ91BHm/136A2721.jpg",
        },
        {
          link: "https://i.ibb.co/k6LxtWC/136A2727.jpg",
        },
        {
          link: "https://i.ibb.co/T1P9qmD/136A2730.jpg",
        },
        {
          link: "https://i.ibb.co/wW15kxz/136A2755.jpg",
        },
        {
          link: "https://i.ibb.co/QntWvR4/136A2761.jpg",
        },
        {
          link: "https://i.ibb.co/x14qWvt/136A2764.jpg",
        },
        {
          link: "https://i.ibb.co/5c51XcZ/136A2774.jpg",
        },
        {
          link: "https://i.ibb.co/vqTB8SP/136A2811.jpg",
        },
        {
          link: "https://i.ibb.co/XJyBMFx/136A2817.jpg",
        },
        {
          link: "https://i.ibb.co/M6qfrBh/136A2826.jpg",
        },
        {
          link: "https://i.ibb.co/fqvPjnq/136A2828.jpg",
        },
        {
          link: "https://i.ibb.co/Vj7SW5f/136A2831.jpg",
        },
        {
          link: "https://i.ibb.co/JQcxddx/136A2864.jpg",
        },
        {
          link: "https://i.ibb.co/P5RKfFv/136A2868.jpg",
        },
        {
          link: "https://i.ibb.co/nMstB3d/136A2905.jpg",
        },
        {
          link: "https://i.ibb.co/sHDMtCh/136A2907.jpg",
        },
        {
          link: "https://i.ibb.co/FxHrNT1/136A2917.jpg",
        },
        {
          link: "https://i.ibb.co/4PK9RW3/136A2936.jpg",
        },
        {
          link: "https://i.ibb.co/Y0fRQkF/136A2947.jpg",
        },
        {
          link: "https://i.ibb.co/xjPSkgH/136A2950.jpg",
        },
        {
          link: "https://i.ibb.co/DVV8PTQ/136A2955.jpg",
        },
        {
          link: "https://i.ibb.co/SfPbgXK/136A2961.jpg",
        },
        {
          link: "https://i.ibb.co/C758Pxr/136A2982.jpg",
        },
        {
          link: "https://i.ibb.co/SrdsKvp/136A2987.jpg",
        },
        {
          link: "https://i.ibb.co/pvBKKFn/136A3022.jpg",
        },
        {
          link: "https://i.ibb.co/yhTBzCv/136A3040.jpg",
        },
        {
          link: "https://i.ibb.co/R4cM3wt/136A3054.jpg",
        },
        {
          link: "https://i.ibb.co/8dSFTQG/136A3152.jpg",
        },
        {
          link: "https://i.ibb.co/n065FKR/136A3167.jpg",
        },
        {
          link: "https://i.ibb.co/HD2Tvvq/136A3172.jpg",
        },
        {
          link: "https://i.ibb.co/CBxVwYs/136A3178.jpg",
        },
        {
          link: "https://i.ibb.co/V3zP7zC/136A3224.jpg",
        },
        {
          link: "https://i.ibb.co/7nrcddv/136A3236.jpg",
        },
        {
          link: "https://i.ibb.co/D41bdLF/136A3265.jpg",
        },
        {
          link: "https://i.ibb.co/LPDMmxm/136A3269.jpg",
        },
        {
          link: "https://i.ibb.co/KmcxzBJ/136A3276.jpg",
        },
      ],
    },
    {
      name: "11.12",
      items: [
        {
          link: "https://i.ibb.co/rZvPC57/136A3446.jpg",
        },
        {
          link: "https://i.ibb.co/G0q4Vxv/136A3466.jpg",
        },
        {
          link: "https://i.ibb.co/ZzgZD0d/136A3471.jpg",
        },
        {
          link: "https://i.ibb.co/hXjrYRF/136A3475.jpg",
        },
        {
          link: "https://i.ibb.co/HBg1KQg/136A3479.jpg",
        },
        {
          link: "https://i.ibb.co/dMLCk62/136A3499.jpg",
        },
        {
          link: "https://i.ibb.co/3SKnfng/136A3517.jpg",
        },
        {
          link: "https://i.ibb.co/nBpM5gy/136A3531.jpg",
        },
        {
          link: "https://i.ibb.co/0MpcWRc/136A3557.jpg",
        },
        {
          link: "https://i.ibb.co/kKMhdPf/136A3565.jpg",
        },
        {
          link: "https://i.ibb.co/swTVQTG/136A3569.jpg",
        },
        {
          link: "https://i.ibb.co/QPvGNCD/136A3584.jpg",
        },
        {
          link: "https://i.ibb.co/0cnptCv/136A3589.jpg",
        },
        {
          link: "https://i.ibb.co/TcZkGCf/136A3604.jpg",
        },
        {
          link: "https://i.ibb.co/XxTrPph/136A3615.jpg",
        },
        {
          link: "https://i.ibb.co/Wpd3BT7/136A3623.jpg",
        },
        {
          link: "https://i.ibb.co/ZhwM66K/136A3625.jpg",
        },
        {
          link: "https://i.ibb.co/55gVLv0/136A3641.jpg",
        },
        {
          link: "https://i.ibb.co/G7zVYFq/136A3354.jpg",
        },
        {
          link: "https://i.ibb.co/mtbRSm2/136A3366.jpg",
        },
        {
          link: "https://i.ibb.co/sKF765B/136A3369.jpg",
        },
        {
          link: "https://i.ibb.co/nzCbFjh/136A3380.jpg",
        },
        {
          link: "https://i.ibb.co/9sqXwkc/136A3385.jpg",
        },
        {
          link: "https://i.ibb.co/nmyxqNH/136A3399.jpg",
        },
        {
          link: "https://i.ibb.co/jwSCQKC/136A3413.jpg",
        },
        {
          link: "https://i.ibb.co/X46jv2R/136A3420.jpg",
        },
        {
          link: "https://i.ibb.co/h1K5Hf9/136A3422.jpg",
        },
        {
          link: "https://i.ibb.co/Wnv4qdj/136A3441.jpg",
        },
        {
          link: "https://i.ibb.co/MRLwT6r/136A3442.jpg",
        },
      ],
    },
    {
      name: "18.12",
      items: [
        {
          link: "https://i.ibb.co/zNSLdJ7/136A3373.jpg",
        },
        {
          link: "https://i.ibb.co/XLV96QB/136A3377.jpg",
        },
        {
          link: "https://i.ibb.co/ThDc1Kf/136A3380.jpg",
        },
        {
          link: "https://i.ibb.co/34CRZJW/136A3384.jpg",
        },
        {
          link: "https://i.ibb.co/Vvhn7Bc/136A3386.jpg",
        },
        {
          link: "https://i.ibb.co/YXRQKHq/136A3388.jpg",
        },
        {
          link: "https://i.ibb.co/Kmr0cjD/136A3393.jpg",
        },
        {
          link: "https://i.ibb.co/r2rW06p/136A3397.jpg",
        },
        {
          link: "https://i.ibb.co/v1Bk7cX/136A3399.jpg",
        },
        {
          link: "https://i.ibb.co/2kvr4GQ/136A3400.jpg",
        },
        {
          link: "https://i.ibb.co/NKmC5Cp/136A3405.jpg",
        },
        {
          link: "https://i.ibb.co/LSwPV9r/136A3406.jpg",
        },
        {
          link: "https://i.ibb.co/DKPYZsN/136A3409.jpg",
        },
        {
          link: "https://i.ibb.co/wrPXxCk/136A3415.jpg",
        },
        {
          link: "https://i.ibb.co/7jmf2qd/136A3416.jpg",
        },
        {
          link: "https://i.ibb.co/pJHnZpx/136A3419.jpg",
        },
        {
          link: "https://i.ibb.co/bW90dbF/136A3420.jpg",
        },
        {
          link: "https://i.ibb.co/sF13Z4S/136A3423.jpg",
        },
        {
          link: "https://i.ibb.co/DpNXf8M/136A3427.jpg",
        },
        {
          link: "https://i.ibb.co/jrg3cps/136A3428.jpg",
        },
        {
          link: "https://i.ibb.co/mvzHmW9/136A3432.jpg",
        },
        {
          link: "https://i.ibb.co/Bg9xL3f/136A3437.jpg",
        },
        {
          link: "https://i.ibb.co/V3mmrnb/136A3442.jpg",
        },
        {
          link: "https://i.ibb.co/HLvY703/136A3444.jpg",
        },
        {
          link: "https://i.ibb.co/ynjRFvz/136A3356.jpg",
        },
        {
          link: "https://i.ibb.co/x3CqnZP/136A3358.jpg",
        },
        {
          link: "https://i.ibb.co/TTY1QW7/136A3361.jpg",
        },
        {
          link: "https://i.ibb.co/gFHgXKd/136A3362.jpg",
        },
        {
          link: "https://i.ibb.co/cynrmYT/136A3363.jpg",
        },
        {
          link: "https://i.ibb.co/Xt51FGJ/136A3365.jpg",
        },
        {
          link: "https://i.ibb.co/mSJcnLm/136A3369.jpg",
        },
      ],
    },
    {
      name: "25.12",
      items: [
        {
          link: "https://i.ibb.co/CvKnsbN/IMG-3968.jpg",
        },
        {
          link: "https://i.ibb.co/jRs1SC0/IMG-3910.jpg",
        },
        {
          link: "https://i.ibb.co/kxBVGr6/IMG-3976.jpg",
        },
        {
          link: "https://i.ibb.co/t37ZGgD/IMG-4006.jpg",
        },
        {
          link: "https://i.ibb.co/G0xKzPW/IMG-4057.jpg",
        },
        {
          link: "https://i.ibb.co/pKs047y/IMG-4072.jpg",
        },
        {
          link: "https://i.ibb.co/7tW3GvJ/IMG-4087.jpg",
        },
        {
          link: "https://i.ibb.co/R3t8Ydc/IMG-4103.jpg",
        },
        {
          link: "https://i.ibb.co/DW70H2Q/IMG-4168.jpg",
        },
        {
          link: "https://i.ibb.co/bKyWZXY/IMG-4178.jpg",
        },
        {
          link: "https://i.ibb.co/QbRtjk3/IMG-4191.jpg",
        },
        {
          link: "https://i.ibb.co/s1TSjdD/IMG-4222.jpg",
        },
        {
          link: "https://i.ibb.co/0yxQfb1/IMG-4294.jpg",
        },
        {
          link: "https://i.ibb.co/VCHWns6/IMG-4302.jpg",
        },
        {
          link: "https://i.ibb.co/vd8MQcK/IMG-4322.jpg",
        },
        {
          link: "https://i.ibb.co/stf3XH1/IMG-4361.jpg",
        },
        {
          link: "https://i.ibb.co/kKr5p9Y/IMG-4379.jpg",
        },
        {
          link: "https://i.ibb.co/wg2G4kk/IMG-4521.jpg",
        },
        {
          link: "https://i.ibb.co/483w6GD/IMG-4535.jpg",
        },
        {
          link: "https://i.ibb.co/4jc7v3Z/IMG-4590.jpg",
        },
        {
          link: "https://i.ibb.co/0F3R4nS/IMG-4603.jpg",
        },
        {
          link: "https://i.ibb.co/B3LyhGQ/IMG-4613.jpg",
        },
        {
          link: "https://i.ibb.co/JvT1TQS/IMG-4631.jpg",
        },
        {
          link: "https://i.ibb.co/JCQ2by4/IMG-4634.jpg",
        },
        {
          link: "https://i.ibb.co/2djSft1/IMG-4645.jpg",
        },
        {
          link: "https://i.ibb.co/gRfWrcD/IMG-4666.jpg",
        },
        {
          link: "https://i.ibb.co/KXbsHBX/IMG-4683.jpg",
        },
        {
          link: "https://i.ibb.co/9ZMJ878/IMG-4723.jpg",
        },
        {
          link: "https://i.ibb.co/qr80C9T/IMG-4684.jpg",
        },
        {
          link: "https://i.ibb.co/fxNdghT/IMG-4741.jpg",
        },
        {
          link: "https://i.ibb.co/d6WGJKr/IMG-4797.jpg",
        },
        {
          link: "https://i.ibb.co/XC4MC3Z/IMG-4817.jpg",
        },
        {
          link: "https://i.ibb.co/wr33jbK/IMG-4829.jpg",
        },
        {
          link: "https://i.ibb.co/HtG07HX/IMG-4837.jpg",
        },
        {
          link: "https://i.ibb.co/HpnL9rw/IMG-3838.jpg",
        },
        {
          link: "https://i.ibb.co/0XLLXk4/IMG-3852.jpg",
        },
      ],
    },
    {
      name: "26.12",
      items: [
        {
          link: "https://i.ibb.co/JFqfzBm/136A5533.jpg",
        },
        {
          link: "https://i.ibb.co/XVTkTDf/136A5547.jpg",
        },
        {
          link: "https://i.ibb.co/bK6VJrG/136A5675.jpg",
        },
        {
          link: "https://i.ibb.co/q78HSLS/136A5697.jpg",
        },
        {
          link: "https://i.ibb.co/BgpdJ52/136A5720.jpg",
        },
        {
          link: "https://i.ibb.co/xs7rGwD/136A5824.jpg",
        },
        {
          link: "https://i.ibb.co/hyDv1MY/136A5834.jpg",
        },
        {
          link: "https://i.ibb.co/R4QTz8g/136A5896.jpg",
        },
        {
          link: "https://i.ibb.co/b6tKXc6/136A5934.jpg",
        },
        {
          link: "https://i.ibb.co/CWF6mgG/136A5950.jpg",
        },
        {
          link: "https://i.ibb.co/N1NF3B0/136A5952.jpg",
        },
        {
          link: "https://i.ibb.co/R7HKdnm/136A5954.jpg",
        },
        {
          link: "https://i.ibb.co/B4nJg39/136A5958.jpg",
        },
        {
          link: "https://i.ibb.co/r4m888H/136A5969.jpg",
        },
        {
          link: "https://i.ibb.co/mHmFX4p/136A5975.jpg",
        },
        {
          link: "https://i.ibb.co/xHRZv6W/136A5990.jpg",
        },
        {
          link: "https://i.ibb.co/CWFMDS8/136A6007.jpg",
        },
        {
          link: "https://i.ibb.co/yhMDWj8/136A6022.jpg",
        },
        {
          link: "https://i.ibb.co/CMfTJ2S/136A6038.jpg",
        },
        {
          link: "https://i.ibb.co/dtL2C5Z/136A6043.jpg",
        },
        {
          link: "https://i.ibb.co/NsgZjg0/136A5302.jpg",
        },
        {
          link: "https://i.ibb.co/RCstSqW/136A5314.jpg",
        },
        {
          link: "https://i.ibb.co/ZdXkPtf/136A5323.jpg",
        },
        {
          link: "https://i.ibb.co/tBVtBpw/136A5349.jpg",
        },
        {
          link: "https://i.ibb.co/vz5W7WN/136A5373.jpg",
        },
        {
          link: "https://i.ibb.co/GVGG95G/136A5385.jpg",
        },
        {
          link: "https://i.ibb.co/QmdyCvG/136A5423.jpg",
        },
        {
          link: "https://i.ibb.co/LSsRJgX/136A5471.jpg",
        },
      ],
    },
    {
      name: "28.12",
      items: [
        {
          link: "https://i.ibb.co/v3cwnc1/136A6737.jpg",
        },
        {
          link: "https://i.ibb.co/88KKDRB/136A6753.jpg",
        },
        {
          link: "https://i.ibb.co/FWHrtbS/136A6761.jpg",
        },
        {
          link: "https://i.ibb.co/qs5cHWb/136A6762.jpg",
        },
        {
          link: "https://i.ibb.co/ZGsdYVC/136A6796.jpg",
        },
        {
          link: "https://i.ibb.co/23fzFbk/136A6808.jpg",
        },
        {
          link: "https://i.ibb.co/0jYfZMq/136A6813.jpg",
        },
        {
          link: "https://i.ibb.co/FsjzQGg/136A6823.jpg",
        },
        {
          link: "https://i.ibb.co/zS4ckKL/136A6836.jpg",
        },
        {
          link: "https://i.ibb.co/Gn40wcc/136A6845.jpg",
        },
        {
          link: "https://i.ibb.co/S3nb1Py/136A6854.jpg",
        },
        {
          link: "https://i.ibb.co/YXHbPy2/136A6857.jpg",
        },
        {
          link: "https://i.ibb.co/m5ccLpP/136A6876.jpg",
        },
        {
          link: "https://i.ibb.co/PZ1bGBk/136A6879.jpg",
        },
        {
          link: "https://i.ibb.co/tsGbDQk/136A6091.jpg",
        },
        {
          link: "https://i.ibb.co/zNwtmyP/136A6103.jpg",
        },
        {
          link: "https://i.ibb.co/wJ3K73D/136A6105.jpg",
        },
        {
          link: "https://i.ibb.co/ZS58GHL/136A6129.jpg",
        },
        {
          link: "https://i.ibb.co/S3XNy8Q/136A6134.jpg",
        },
        {
          link: "https://i.ibb.co/6Y3yjKY/136A6143.jpg",
        },
        {
          link: "https://i.ibb.co/vZCR4dG/136A6160.jpg",
        },
        {
          link: "https://i.ibb.co/zsn8QSz/136A6182.jpg",
        },
        {
          link: "https://i.ibb.co/DGLwVsM/136A6188.jpg",
        },
        {
          link: "https://i.ibb.co/RQLzXwx/136A6203.jpg",
        },
        {
          link: "https://i.ibb.co/sQ4976j/136A6206.jpg",
        },
        {
          link: "https://i.ibb.co/7N9bd9b/136A6211.jpg",
        },
        {
          link: "https://i.ibb.co/1sjmsjv/136A6222.jpg",
        },
        {
          link: "https://i.ibb.co/6Rs6Kgw/136A6239.jpg",
        },
        {
          link: "https://i.ibb.co/tsfgZFL/136A6251.jpg",
        },
        {
          link: "https://i.ibb.co/6FLrVSy/136A6261.jpg",
        },
        {
          link: "https://i.ibb.co/QpSWYF1/136A6271.jpg",
        },
        {
          link: "https://i.ibb.co/tPdHFQv/136A6296.jpg",
        },
        {
          link: "https://i.ibb.co/pjP8NW8/136A6317.jpg",
        },
        {
          link: "https://i.ibb.co/vXgDT6C/136A6351.jpg",
        },
        {
          link: "https://i.ibb.co/gDNx13B/136A6379.jpg",
        },
        {
          link: "https://i.ibb.co/jfyvmP4/136A6384.jpg",
        },
        {
          link: "https://i.ibb.co/JjmF0YL/136A6406.jpg",
        },
        {
          link: "https://i.ibb.co/YPzmCJm/136A6415.jpg",
        },
        {
          link: "https://i.ibb.co/RpNbrqx/136A6462.jpg",
        },
        {
          link: "https://i.ibb.co/ryLvJh2/136A6464.jpg",
        },
        {
          link: "https://i.ibb.co/M8n5J94/136A6526.jpg",
        },
        {
          link: "https://i.ibb.co/ssZZq6m/136A6531.jpg",
        },
        {
          link: "https://i.ibb.co/X26QxYQ/136A6534.jpg",
        },
        {
          link: "https://i.ibb.co/F7P3Bdq/136A6555.jpg",
        },
        {
          link: "https://i.ibb.co/Qp7mGC7/136A6670.jpg",
        },
        {
          link: "https://i.ibb.co/P9vR7xP/136A6690.jpg",
        },
        {
          link: "https://i.ibb.co/F8GTN5Q/136A6726.jpg",
        },
      ],
    },
  ];

  const history = useHistory();
  const scheduleTitleBlock = {
    mainText: {
      lineHeight: 1,
      color: "#eb3333",
      fontFamily: "Mossport",
      [theme.breakpoints.down("md")]: {
        fontSize: "70px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "130px",
      },
    },
    mainTextSm: {
      color: "#eb3333",
      fontFamily: "Mossport",
      lineHeight: 1,
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "25px",
      },
    },
    secondaryText: {
      color: "black",
      fontWeight: "bold",
      fontSize: "16px",
      fontFamily: "Gotham ProBold",
      letterSpacing: 0.09,
      [theme.breakpoints.up("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
    secondaryTextThin: {
      color: "black",
      lineHeight: 1.2,
      fontFamily: "Gotham Pro",
      letterSpacing: 0.09,
      [theme.breakpoints.up("md")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },

    imagePrize: {
      mx: 1,
      my: 3,
      [theme.breakpoints.down("md")]: {
        width: "120px",
      },
      [theme.breakpoints.up("md")]: {
        width: "120px",
      },
    },
  };
  const seasonsTitleBlock = {
    mainText: {
      color: "#eb3333",
      fontFamily: "Mossport",
      [theme.breakpoints.down("md")]: {
        fontSize: "70px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "130px",
      },
    },
    secondaryText: {
      color: "black",
      fontWeight: "bold",
      fontSize: "16px",
      fontFamily: "Gotham ProBold",
      letterSpacing: 0.09,
      [theme.breakpoints.up("md")]: {
        width: "625px",
        fontSize: "16px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
    secondaryTextMd: {
      color: "black",
      fontWeight: "bold",
      fontFamily: "Gotham ProBold",
      letterSpacing: 0.09,
      [theme.breakpoints.up("md")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
    },
    secondaryTextSm: {
      color: "black",
      fontWeight: "bold",
      fontFamily: "Gotham ProBold",
      letterSpacing: 0.09,
      [theme.breakpoints.up("md")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
    },
    secondaryTextLg: {
      color: "black",
      fontWeight: "bold",
      fontFamily: "Gotham ProBold",
      letterSpacing: 0.09,
      [theme.breakpoints.up("md")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
    },
    button: {
      backgroundColor: "#eb3333",
      mt: 3,
      mb: 8,
      borderRadius: 0,
      fontFamily: "Gotham Pro",
      letterSpacing: 0.09,
      color: "white",
      width: "300px",

      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        mb: 2,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "14px",
      },
      "&:hover": {
        background: "white",
        borderColor: "#eb3333",
        color: "#eb3333",
      },
    },
    imageCenter: {
      [theme.breakpoints.down("md")]: {
        height: "270px",
      },
      [theme.breakpoints.up("md")]: {
        height: "400px",
      },
    },
    imageSides: {
      [theme.breakpoints.down("md")]: {
        height: "270px",
      },
      [theme.breakpoints.up("md")]: {
        height: "370px",
      },
    },

    imageSides2: {
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        height: "auto",
      },
      [theme.breakpoints.up("md")]: {
        height: "380px",
      },
    },
  };
  const prizes = [
    "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F%D0%91%D0%B0%D1%84%D1%84.jpg?alt=media&token=a38f2cbd-488d-46be-bd6a-7b384a308395",
    "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2Fgloves.png?alt=media&token=97cbf55b-0814-49b3-ab3d-860816a2723c",
    "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F7f0efd6b58d4825f.jpg?alt=media&token=80451607-e52b-41df-8207-696a6018db6b",
    "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2Fhat.png?alt=media&token=25687e27-35f2-4536-8542-e32697e32999",
    "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F7451.30_5.jpg?alt=media&token=72b6e8e0-c072-4aae-955a-bc476916e7b1",
    "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2FWhatsApp%20Image%202021-11-09%20at%2012.03.36.jpeg?alt=media&token=aa6d851e-1261-4ca3-b40f-33bea7650096",
  ];

  const prizeDesc = [
    {
      name: "Бафф",
      desc: "2 посещения (рогейн или скалолазание)",
    },
    {
      name: "Перчатки",
      desc: "2 посещения школы фигурного катания",
    },
    {
      name: "Термостакан",
      desc: "3 посещения фигурного катания",
    },
    {
      name: "Шапка",
      desc: "3 посещения (скалолазание, рогейн, фигурное катание)",
    },
    {
      name: "Компас",
      desc: "3 посещения рогейн",
    },
    {
      name: "Футболка",
      desc: "1 посещение скалолазания",
    },
  ];

  const schedule = [
    {
      date: "13-14 НОЯБРЯ",
      rogaine: {
        timings: [
          {
            time: "Cб 10:00-15:00",
            name: (
              <>
                <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                  На время,
                </Typography>

                <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                  на эрудицию
                </Typography>
              </>
            ),
          },
          { time: "Вс 12:00", name: "Семинар по рогейну" },
        ],
      },
      climbing: {
        timings: [
          { time: "11:00-13:00", name: "Урок Боулдеринг" },
          { time: "12:00-13:00", name: "Урок Трудность" },
          { time: "13:30-15:30", name: "Урок Боулдеринг" },
          { time: "14:00-15:00", name: "Урок Трудность" },
          { time: "16:00-17:00", name: "Урок Трудность" },
          { time: "16:00-18:00", name: "Урок Трудность" },
        ],
      },
      skating: {
        timings: [],
      },
    },
    {
      date: "20-21 НОЯБРЯ",
      rogaine: {
        timings: [
          {
            time: "Cб 10:00-15:00",
            name: (
              <>
                <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                  На время,
                </Typography>

                <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                  на эрудицию
                </Typography>
              </>
            ),
          },
          { time: "Вс 12:00", name: "Семинар по рогейну" },
          {
            time: (
              <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                <Box sx={{ color: "#eb3333" }}>Вс 15:00</Box>
              </Typography>
            ),
            name: (
              <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                <Box sx={{ color: "#eb3333" }}>
                  Скандинавская ходьба с Дмитрием Губерниевым
                </Box>
              </Typography>
            ),
          },
        ],
      },
      climbing: {
        timings: [
          { time: "11:00-13:00", name: "Урок Боулдеринг" },
          { time: "12:00-13:00", name: "Урок Трудность" },
          { time: "13:30-15:30", name: "Урок Боулдеринг" },
          { time: "14:00-15:00", name: "Урок Трудность" },
          { time: "16:00-17:00", name: "Урок Трудность" },
          { time: "16:00-18:00", name: "Урок Боулдеринг" },
        ],
      },
      skating: {
        timings: [],
      },
    },
    {
      date: "27-28 НОЯБРЯ",
      rogaine: {
        timings: [
          {
            time: "Cб 10:00-15:00",
            name: (
              <>
                <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                  На время,
                </Typography>

                <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                  на эрудицию
                </Typography>
              </>
            ),
          },
          { time: "Вс 12:00", name: "Семинар по рогейну" },
        ],
      },
      climbing: {
        timings: [
          { time: "11:00-13:00", name: "Урок Боулдеринг" },
          { time: "12:00-13:00", name: "Урок Трудность" },
          { time: "13:30-15:30", name: "Урок Боулдеринг" },
          { time: "14:00-15:00", name: "Урок Трудность" },
          { time: "16:00-17:00", name: "Урок Трудность" },
          { time: "16:00-18:00", name: "Урок Боулдеринг" },

          {
            time: (
              <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                <Box sx={{ color: "#eb3333" }}>Сб 17:00</Box>
              </Typography>
            ),
            name: (
              <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                <Box sx={{ color: "#eb3333" }}>
                  Лекция по скалолазанию от Алексея Рубцова
                </Box>
              </Typography>
            ),
          },
        ],
      },
      skating: {
        timings: [
          {
            time: "10:30-12:20",
            name: (
              <>
                <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                  Тренировка с инструкторами.
                </Typography>
                <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                  100 мест
                </Typography>
              </>
            ),
          },
          { time: "12:20-15:00", name: "Свободное катание" },
        ],
      },
    },
    {
      date: "2-3 ДЕКАБРЯ",
      rogaine: {
        timings: [],
      },
      climbing: {
        timings: [],
      },
      skating: {
        timings: [
          {
            time: "10:30-12:20",
            name: (
              <>
                <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                  Тренировка с инструкторами.
                </Typography>
                <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                  100 мест
                </Typography>
              </>
            ),
          },
          { time: "12:20-15:00", name: "Свободное катание" },
        ],
      },
    },
    {
      date: "4-5 ДЕКАБРЯ",
      rogaine: {
        timings: [
          {
            time: "Cб 10:00-15:00",
            name: "Семинар по рогейну",
          },
        ],
      },
      climbing: {
        timings: [],
      },
      skating: {
        timings: [],
      },
    },
    {
      date: "9-10 ДЕКАБРЯ",
      rogaine: {
        timings: [],
      },
      climbing: {
        timings: [],
      },
      skating: {
        timings: [
          {
            time: "10:30-12:20",
            name: (
              <>
                <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                  Тренировка с инструкторами.
                </Typography>
                <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                  100 мест
                </Typography>
              </>
            ),
          },
          { time: "12:20-15:00", name: "Свободное катание" },
        ],
      },
    },
    {
      date: "11-12 ДЕКАБРЯ",
      rogaine: {
        timings: [
          {
            time: "Cб 10:00-15:00",
            name: (
              <>
                <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                  На время,
                </Typography>

                <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                  на эрудицию
                </Typography>
              </>
            ),
          },
        ],
      },
      climbing: {
        timings: [],
      },
      skating: {
        timings: [],
      },
    },

    {
      date: "16-17 ДЕКАБРЯ",
      rogaine: {
        timings: [],
      },
      climbing: {
        timings: [],
      },
      skating: {
        timings: [
          {
            time: "10:30-12:20",
            name: (
              <>
                <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                  Тренировка с инструкторами.
                </Typography>
                <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                  100 мест
                </Typography>
              </>
            ),
          },
          { time: "12:20-15:00", name: "Свободное катание" },
        ],
      },
    },
    {
      date: "18-19 ДЕКАБРЯ",
      rogaine: {
        timings: [
          {
            time: "Cб 10:00-15:00",
            name: "Семинар по рогейну",
          },
        ],
      },
      climbing: {
        timings: [],
      },
      skating: {
        timings: [],
      },
    },
    {
      date: "25-26 ДЕКАБРЯ",
      rogaine: {
        timings: [
          {
            time: "Вс 12:00",
            name: (
              <>
                <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                  На время,
                </Typography>

                <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                  на эрудицию
                </Typography>
              </>
            ),
          },
        ],
      },
      climbing: {
        timings: [],
      },
      skating: {
        timings: [
          {
            time: "10:30-12:20",
            name: (
              <>
                <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                  Тренировка с инструкторами.
                </Typography>
                <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                  100 мест
                </Typography>
              </>
            ),
          },
          { time: "12:20-15:00", name: "Свободное катание" },
        ],
      },
    },
    {
      date: "28 ДЕКАБРЯ",
      rogaine: {
        timings: [],
      },
      climbing: {
        timings: [],
      },
      skating: {
        timings: [
          {
            time: (
              <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                <Box sx={{ color: "#eb3333" }}>Вт 12:00</Box>
              </Typography>
            ),
            name: (
              <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                <Box sx={{ color: "#eb3333" }}>
                  Мастер класс по фигурному катанию с Еленой Родионовой
                </Box>
                <Box sx={{ color: "#eb3333" }}>70 мест</Box>
              </Typography>
            ),
          },
        ],
      },
    },
  ];
  const registerHandle = () => {
    history.push("/register");
  };

  const profileHandle = () => {
    history.push("/profile");
  };

  const titles = {
    mainTitle: {
      [theme.breakpoints.down("md")]: {
        mt: 6,
      },
      [theme.breakpoints.up("md")]: {
        mt: 13,
      },
    },
    seasons: {
      mt: 6,
    },
  };
  const boxMain = {
    backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2Ftitle.png?alt=media&token=b7be6acc-5954-4583-a2f6-e8099c69512c)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  };

  const boxMap = {
    backgroundPosition: "center",
    backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/map.png?alt=media&token=1d053a01-846e-41e2-9c55-06fc253bbb98)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  const mainTitleBlock = {
    white: {
      lineHeight: 1,
      color: "white",
      fontFamily: "Mossport",

      [theme.breakpoints.down("md")]: {
        fontSize: "70px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "130px",
      },
    },

    red: {
      lineHeight: 1,
      color: "#eb3333",
      fontFamily: "Mossport",
      [theme.breakpoints.down("md")]: {
        fontSize: "70px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "130px",
      },
    },
  };
  const boxRegisterMap = {
    background: {
      backgroundColor: "white",
      px: 3,
      py: 4,
    },
    textMain: {
      fontSize: "24px",
      lineHeight: 1,
      color: "black",
      fontFamily: "Mossport",

      [theme.breakpoints.down("md")]: {
        fontSize: "28px",
        mb: 2,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "24px",
      },
    },
    textSecondary: {
      fontWeight: "bold",
      color: "black",
      fontSize: "11px",
      fontFamily: "Gotham ProBold",

      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
      },
    },
    textThin: {
      fontWeight: "300",
      color: "black",
      fontSize: "11px",
      fontFamily: "Gotham Pro",

      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
      },
    },
    button: {
      backgroundColor: "white",
      mt: 3,
      borderRadius: 0,
      fontFamily: "Gotham ProBold",
      fontWeight: "bold",
      fontSize: "13px",
      letterSpacing: 0.09,

      [theme.breakpoints.down("md")]: {
        fontSize: "17px",
        mb: 2,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "13px",
      },
      "&:hover": {
        background: "#eb3333",
        borderColor: "white",
        color: "white",
      },
    },

    buttonBig: {
      backgroundColor: "white",
      mt: 3,
      mb: 8,
      borderRadius: 0,
      fontFamily: "Gotham Pro",
      letterSpacing: 0.09,

      width: "300px",

      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        mb: 2,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "10px",
      },
      "&:hover": {
        background: "#eb3333",
        borderColor: "white",
        color: "white",
      },
    },
  };
  const boxRegisterSmall = {
    background: {
      backgroundColor: "#eb3333",
      px: 3,
      py: 4,
    },
    textMain: {
      fontSize: "36px",
      lineHeight: 1,
      color: "white",
      fontFamily: "Mossport",

      [theme.breakpoints.down("md")]: {
        fontSize: "28px",
        mb: 2,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "36px",
      },
    },
    textSecondary: {
      fontWeight: "bold",
      color: "white",
      fontSize: "16px",
      fontFamily: "Gotham ProBold",

      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
      },
    },
    textThin: {
      fontWeight: "300",
      color: "white",
      fontSize: "16px",
      fontFamily: "Gotham Pro",

      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
      },
    },
    button: {
      backgroundColor: "white",
      mt: 3,
      borderRadius: 0,
      fontFamily: "Gotham ProBold",
      fontWeight: "bold",
      fontSize: "18px",
      letterSpacing: 0.09,

      [theme.breakpoints.down("md")]: {
        fontSize: "17px",
        mb: 2,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "18px",
      },
      "&:hover": {
        background: "#eb3333",
        borderColor: "white",
        color: "white",
      },
    },

    buttonBig: {
      backgroundColor: "white",
      mt: 3,
      mb: 8,
      borderRadius: 0,
      fontFamily: "Gotham Pro",
      letterSpacing: 0.09,

      width: "300px",

      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        mb: 2,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "14px",
      },
      "&:hover": {
        background: "#eb3333",
        borderColor: "white",
        color: "white",
      },
    },
  };
  const special = {
    image: {
      height: "auto",
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: "50%",
      },
    },
    button: {
      backgroundColor: "white",
      borderRadius: 0,
      fontFamily: "Gotham ProBold",
      fontWeight: "bold",
      letterSpacing: 0.09,
      mt: 6,
      px: 6,
      py: 1,

      [theme.breakpoints.down("md")]: {
        fontSize: "24px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "34px",
      },
      "&:hover": {
        background: "#eb3333",
        borderColor: "white",
        color: "white",
      },
    },

    title: {
      color: "white",
      fontFamily: "Gotham ProBold",
      fontWeight: "bold",
      letterSpacing: 0.09,
      lineHeight: 1,
      [theme.breakpoints.down("md")]: {
        fontSize: "32px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "38px",
      },
    },
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  const prizesTitleBlock = {
    mainText: {
      color: "white",
      fontFamily: "Mossport",
      [theme.breakpoints.down("md")]: {
        fontSize: "70px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "130px",
      },
    },
    secondaryTextPrize: {
      color: "black",
      fontWeight: "bold",
      fontSize: "16px",
      fontFamily: "Gotham ProBold",
      letterSpacing: 0.09,
      [theme.breakpoints.up("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
    secondaryTextThin: {
      color: "black",
      lineHeight: 1.2,
      fontFamily: "Gotham Pro",
      letterSpacing: 0.09,
      [theme.breakpoints.up("md")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
    secondaryText: {
      color: "white",
      fontWeight: "bold",
      fontSize: "16px",
      fontFamily: "Gotham ProBold",
      letterSpacing: 0.09,
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },

    imagePrize: {
      mx: 1,
      my: 3,
      [theme.breakpoints.down("md")]: {
        width: "80px",
      },
      [theme.breakpoints.up("md")]: {
        height: "90px",
      },
    },
  };

  const rogaineTitleBlock = {
    mainText: {
      color: "white",
      fontFamily: "Mossport",
      lineHeight: 1,
      [theme.breakpoints.down("md")]: {
        fontSize: "70px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "130px",
      },
    },
    mainTextSm: {
      color: "white",
      fontFamily: "Mossport",
      lineHeight: 1,
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "25px",
      },
    },
    secondaryText: {
      color: "white",
      fontWeight: "bold",
      fontSize: "16px",
      fontFamily: "Gotham ProBold",
      letterSpacing: 0.09,
      width: "fit-content",
      [theme.breakpoints.up("md")]: {
        fontSize: "24px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
    },
    secondaryTextThin: {
      width: "fit-content",
      color: "white",
      lineHeight: 1.2,
      fontWeight: "200",
      fontFamily: "Gotham Pro",
      letterSpacing: 0.09,
      [theme.breakpoints.up("md")]: {
        fontSize: "24px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
    },

    imagePrize: {
      mx: 1,
      my: 3,
      [theme.breakpoints.down("md")]: {
        width: "120px",
      },
      [theme.breakpoints.up("md")]: {
        width: "120px",
      },
    },
  };

  const climbingTitleBlock = {
    mainText: {
      color: "#eb3333",
      fontFamily: "Mossport",
      [theme.breakpoints.down("md")]: {
        fontSize: "70px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "130px",
      },
    },
    secondaryText: {
      color: "black",
      fontWeight: "bold",
      fontSize: "16px",
      fontFamily: "Gotham ProBold",
      letterSpacing: 0.09,
      width: "fit-content",
      [theme.breakpoints.up("md")]: {
        fontSize: "24px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
    },
    secondaryTextThin: {
      width: "fit-content",
      color: "black",
      lineHeight: 1.2,
      fontWeight: "200",
      fontFamily: "Gotham Pro",
      letterSpacing: 0.09,
      [theme.breakpoints.up("md")]: {
        fontSize: "24px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
    },
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderSchedule = (days) => {
    return (
      <>
        <Box sx={{ width: "150px" }}>
          <Typography sx={scheduleTitleBlock.mainTextSm}>
            {days.date}
          </Typography>
        </Box>

        <Stack direction="column" sx={matches ? { flex: 1 } : null}>
          {days.rogaine.timings.length > 0 ? (
            <>
              <Typography sx={scheduleTitleBlock.secondaryText}>
                РОГЕЙН
              </Typography>

              {days.rogaine.timings.map((timing, i) => {
                return (
                  <Box key={i}>
                    <Stack spacing={5} direction="row">
                      <Box sx={{ flex: 1 }}>
                        <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                          {timing.time}
                        </Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                          {timing.name}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                );
              })}
              {days.rogaine ? <></> : null}
            </>
          ) : null}
        </Stack>

        <Stack direction="column" sx={{ flex: 1 }}>
          {days.climbing.timings.length > 0 ? (
            <>
              <Typography sx={scheduleTitleBlock.secondaryText}>
                СКАЛОЛАЗАНИЕ
              </Typography>
              {days.climbing.timings.map((timing, i) => {
                return (
                  <Box key={i}>
                    <Stack key={i} direction="row" spacing={5}>
                      <Box sx={{ flex: 1 }}>
                        <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                          {timing.time}
                        </Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                          {timing.name}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                );
              })}
            </>
          ) : null}
        </Stack>

        <Stack direction="column" sx={{ flex: 1 }}>
          {days.skating.timings.length > 0 ? (
            <>
              <Typography sx={scheduleTitleBlock.secondaryText}>
                ФИГУРНОЕ КАТАНИЕ
              </Typography>
              {days.skating.timings.map((timing, i) => {
                return (
                  <Box key={i}>
                    <Stack direction="row" spacing={5}>
                      <Box sx={{ flex: 1 }}>
                        <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                          {timing.time}
                        </Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography sx={scheduleTitleBlock.secondaryTextThin}>
                          {timing.name}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                );
              })}
            </>
          ) : null}
        </Stack>
      </>
    );
  };

  let galery;

  const renderGalery = () => {
    return (
      <>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs variant="scrollable" value={value} onChange={handleChange}>
            {links.map((item, index) => {
              return (
                <Tab
                  label={
                    <Typography sx={{ fontFamily: "Gotham ProBold" }}>
                      {item.name}
                    </Typography>
                  }
                  {...a11yProps(index)}
                />
              );
            })}
          </Tabs>
        </Box>

        {links.map((link, index) => {
          const images = link.items.map((photo) => ({
            src: photo.link,
          }));

          return (
            <TabPanel value={value} index={index}>
              <Carousel
                canAutoPlay={false}
                images={images}
                style={
                  matches
                    ? { height: "700px", width: "100%" }
                    : { height: "250px", width: "100%" }
                }
              />
            </TabPanel>
          );
        })}
      </>
    );
  };

  try {
    galery = renderGalery();
  } catch (e) {}

  return (
    <>
      <NavBar />

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={boxMain}>
          <Container maxWidth="lg" sx={{ my: 2 }}>
            <Grid container spacing={2}>
              <Grid sx={titles.mainTitle} item xs={12}>
                <Typography sx={mainTitleBlock.white} component="div">
                  СПОРТИВНЫЕ
                </Typography>
                <Stack direction="row" spacing={1.5}>
                  <Stack direction="column">
                    <Typography sx={mainTitleBlock.red} component="div">
                      СЕЗОНЫ
                    </Typography>
                    <Typography sx={mainTitleBlock.white} component="div">
                      {matches ? "" : "ВДНХ"}
                    </Typography>
                  </Stack>

                  <Typography sx={mainTitleBlock.white} component="div">
                    {matches ? "НА ВДНХ" : "НА"}
                  </Typography>
                </Stack>
              </Grid>

              {matches ? (
                <Grid sx={{ mt: 13, mb: 10 }} item md={6} xs={6}>
                  <Box sx={boxRegisterSmall.background}>
                    <Typography sx={boxRegisterSmall.textMain}>
                      13&nbsp;НОЯБРЯ&nbsp;&mdash; 26&nbsp;ДЕКАБРЯ
                    </Typography>
                    <Stack sx={{ mt: 3 }} spacing={1} direction="column">
                      <Typography sx={boxRegisterSmall.textSecondary}>
                        Место проведения мероприятия:
                      </Typography>

                      <Typography sx={boxRegisterSmall.textThin}>
                        <Box
                          sx={{ color: "white" }}
                          component="a"
                          href="https://yandex.ru/maps/-/CCUuuVw5sC"
                        >
                          ВДНХ, напротив павильона &#8470;&nbsp;57
                        </Box>
                      </Typography>
                    </Stack>
                    <Button
                      variant="outlined"
                      fullWidth
                      sx={boxRegisterSmall.button}
                      onClick={currentUser ? profileHandle : registerHandle}
                    >
                      {currentUser ? "ЛИЧНЫЙ КАБИНЕТ" : "СТАТЬ УЧАСТНИКОМ"}
                    </Button>
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </Container>
        </Box>
        {matches ? null : (
          <Box sx={{ backgroundColor: "#eb3333" }}>
            <Container maxWidth="lg">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  mx: 3,
                  mt: 10,
                  mb: 7,
                }}
              >
                <Stack spacing={1} direction="column">
                  <Typography sx={boxRegisterSmall.textMain}>
                    13&nbsp;НОЯБРЯ&nbsp;&mdash; 26&nbsp;ДЕКАБРЯ
                  </Typography>
                  <Typography sx={boxRegisterSmall.textSecondary}>
                    Место проведения мероприятия:
                  </Typography>
                  <Typography sx={boxRegisterSmall.textThin}>
                    ВДНХ, напротив павильона &#8470;&nbsp;57
                  </Typography>
                </Stack>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={boxRegisterSmall.button}
                  onClick={currentUser ? profileHandle : registerHandle}
                >
                  {currentUser ? "ЛИЧНЫЙ КАБИНЕТ" : "СТАТЬ УЧАСТНИКОМ"}
                </Button>
              </Box>
            </Container>
          </Box>
        )}

        <Stack direction={matches ? "row" : "column"}>
          <Box
            component="img"
            src="https://cdn.discordapp.com/attachments/910931020130451550/920293531443142656/photo_2021-12-10_18-14-17.jpg"
            sx={special.image}
          />
          <Stack
            spacing={5}
            justifyContent="center"
            alignItems="center"
            sx={{ flex: 1, backgroundColor: "#F6323E" }}
          >
            <Box sx={{ py: 10 }}>
              <Stack spacing={1}>
                <Typography sx={special.title}>МАСТЕР КЛАСС ПО</Typography>
                <Typography sx={special.title}>ФИГУРНОМУ КАТАНИЮ</Typography>
                <Typography sx={special.title}>С ЕЛЕНОЙ</Typography>
                <Typography sx={special.title}>РОДИОНОВОЙ</Typography>
              </Stack>

              <Button
                component={HashLink}
                smooth
                to="/climbing#special"
                disabled
                fullWidth
                variant="outlined"
                sx={special.button}
              >
                Запись закрыта
              </Button>
            </Box>
          </Stack>
        </Stack>

        <Box sx={{ display: "flex", backgroundColor: "white" }}>
          <Container maxWidth="lg">
            <Grid container spacing={2}>
              <Grid sx={titles.seasons} item xs={12}>
                <Typography sx={seasonsTitleBlock.mainText} component="div">
                  О СЕЗОНАХ
                </Typography>

                <Stack direction="column" spacing={4}>
                  <Typography
                    sx={seasonsTitleBlock.secondaryText}
                    component="div"
                  >
                    С&nbsp;13&nbsp;НОЯБРЯ ПО&nbsp;26&nbsp;ДЕКАБРЯ 2021&nbsp;ГОДА
                    КАЖДЫЕ ВЫХОДНЫЕ НА ТЕРРИТОРИИ ВДНХ ЛЮБОЙ ЖЕЛАЮЩИЙ СМОЖЕТ
                    ПРОВЕСТИ ВРЕМЯ С ПОЛЬЗОЙ!
                  </Typography>

                  <Typography
                    sx={seasonsTitleBlock.secondaryText}
                    component="div"
                  >
                    СПОРТИВНЫЕ СЕЗОНЫ ОТКРЫВАЮТСЯ ТРЕМЯ ДИСЦИПЛИНАМИ:
                  </Typography>
                </Stack>
                <Stack sx={{ my: 1, ml: 1 }} direction="column" spacing={0.5}>
                  <Typography
                    sx={seasonsTitleBlock.secondaryText}
                    component="div"
                  >
                    &bull; РОГЕЙН
                  </Typography>

                  <Typography
                    sx={seasonsTitleBlock.secondaryText}
                    component="div"
                  >
                    &bull; СКАЛОЛАЗАНИЕ
                  </Typography>
                  <Typography
                    sx={seasonsTitleBlock.secondaryText}
                    component="div"
                  >
                    &bull; ФИГУРНОЕ КАТАНИЕ
                  </Typography>
                </Stack>

                <Stack
                  sx={{ my: 3, px: 2 }}
                  justifyContent="center"
                  alignItems="center"
                  direction={matches ? "row" : "column"}
                  container
                  spacing={5}
                >
                  <Box
                    sx={seasonsTitleBlock.imageSides}
                    component="img"
                    src="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2Fphoto_2021-11-06_12-07-39.jpg?alt=media&token=d3f2424d-9f70-42bc-a329-4793902922e8"
                  />
                  <Box
                    sx={seasonsTitleBlock.imageCenter}
                    component="img"
                    src="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2Fclimbing.png?alt=media&token=758a17e4-30d0-4864-9496-938403c85b05"
                  />
                  <Box
                    sx={seasonsTitleBlock.imageSides}
                    component="img"
                    src="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2Ff23a3e11b68b49068a21fb3e20204790.jpg?alt=media&token=a884efa0-5305-4cf8-81bd-17925334d5c8"
                  />
                </Stack>

                <Stack
                  sx={{ mt: 5 }}
                  direction="column"
                  alignItems="flex-end"
                  spacing={1}
                >
                  <Typography
                    textAlign="right"
                    sx={seasonsTitleBlock.secondaryText}
                  >
                    ТРЕНИРОВАТЬСЯ{" "}
                    <Box sx={{ color: "#eb3333" }} component="span">
                      ИЛИ
                    </Box>{" "}
                    СОРЕВНОВАТЬСЯ?
                  </Typography>
                  <Typography
                    textAlign="right"
                    sx={seasonsTitleBlock.secondaryTextMd}
                  >
                    ПРИЙТИ С ДРУЗЬЯМИ{" "}
                    <Box sx={{ color: "#eb3333" }} component="span">
                      ИЛИ
                    </Box>{" "}
                    НАЙТИ ИХ&nbsp;СРЕДИ УЧАСТНИКОВ?
                  </Typography>
                  <Typography
                    textAlign="right"
                    sx={seasonsTitleBlock.secondaryTextSm}
                  >
                    ПРОКЛАДЫВАТЬ МАРШРУТ, ПОКОРЯТЬ ВЫСОТЫ{" "}
                    <Box sx={{ color: "#eb3333" }} component="span">
                      ИЛИ
                    </Box>{" "}
                    СКОЛЬЗИТЬ ПО&nbsp;ЛЬДУ?
                  </Typography>
                  <Typography
                    textAlign="right"
                    sx={seasonsTitleBlock.secondaryTextLg}
                  >
                    ВЫБРАТЬ ЧТО-ТО ОДНО{" "}
                    <Box sx={{ color: "#eb3333" }} component="span">
                      ИЛИ
                    </Box>{" "}
                    ПОПРОБОВАТЬ ВСЁ — РЕШАТЬ{" "}
                    <Box sx={{ color: "#eb3333" }} component="span">
                      ТЕБЕ
                    </Box>
                    !
                  </Typography>
                </Stack>
                <Stack alignItems="center">
                  <Button
                    variant="outlined"
                    sx={seasonsTitleBlock.button}
                    onClick={currentUser ? profileHandle : registerHandle}
                  >
                    {currentUser ? "ЛИЧНЫЙ КАБИНЕТ" : "СТАТЬ УЧАСТНИКОМ"}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box id="disciplines" sx={{ display: "flex", bgcolor: "#eb3333" }}>
          <Container maxWidth="lg">
            <Grid sx={{ mb: 4 }} container spacing={2}>
              <Grid sx={titles.seasons} item xs={12}>
                <Typography sx={rogaineTitleBlock.mainText} component="div">
                  РОГЕЙН ВДНХ
                </Typography>
              </Grid>

              <Grid item md={6} xs={12}>
                <Stack spacing={1}>
                  <Typography sx={{ lineHeight: 1 }}>
                    <Box component="span" sx={rogaineTitleBlock.secondaryText}>
                      Рогейн на&nbsp;ВДНХ в&nbsp;рамках СПОРТИВНЫХ СЕЗОНОВ
                    </Box>
                    <Box
                      component="span"
                      sx={rogaineTitleBlock.secondaryTextThin}
                    >
                      {" "}
                      &mdash;&nbsp;это уникальная возможность активно
                      и&nbsp;интересно провести несколько часов на&nbsp;свежем
                      воздухе!
                    </Box>
                  </Typography>

                  <Typography sx={{ lineHeight: 1 }}>
                    <Box
                      component="span"
                      sx={rogaineTitleBlock.secondaryTextThin}
                    >
                      Поближе познакомиться с&nbsp;территорией парка ВДНХ.
                      И&nbsp;улучшить свои навыки ориентироваться
                      на&nbsp;местности по&nbsp;карте и компасу.
                    </Box>
                  </Typography>
                </Stack>

                <Box sx={{ my: 1 }}>
                  <Typography sx={rogaineTitleBlock.secondaryText}>
                    Два варианта участия:
                  </Typography>
                  <Typography sx={rogaineTitleBlock.secondaryText}>
                    Спортивный и&nbsp;активно-познавательный
                  </Typography>
                </Box>

                <Button
                  component={Link}
                  to="/rogaine"
                  variant="outlined"
                  sx={boxRegisterSmall.buttonBig}
                >
                  <Box sx={{ px: 12 }}>ПОДРОБНЕЕ</Box>
                </Button>
              </Grid>

              <Grid item md={6} xs={12}>
                <Box
                  sx={seasonsTitleBlock.imageSides2}
                  component="img"
                  src="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/unknown%20(1).png?alt=media&token=0942b42c-05f1-4602-92d9-2ef44ade86fb"
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box sx={{ display: "flex", bgcolor: "white" }}>
          <Container maxWidth="lg">
            <Grid sx={{ mb: 4 }} container spacing={2}>
              <Grid sx={titles.seasons} item xs={12}>
                <Typography sx={climbingTitleBlock.mainText} component="div">
                  СКАЛОЛАЗАНИЕ
                </Typography>
              </Grid>

              <Grid item md={6} xs={12}>
                <Stack spacing={2}>
                  <Typography
                    component="div"
                    sx={climbingTitleBlock.secondaryText}
                  >
                    Bigwallsport&nbsp;&mdash; это настоящее городское
                    скалолазание.
                  </Typography>
                  <Typography
                    component="div"
                    sx={climbingTitleBlock.secondaryTextThin}
                  >
                    Наш большой и&nbsp;светлый павильон&nbsp;&mdash; это целый
                    мир уникального личного опыта и&nbsp;комфортная среда для
                    общения между гостями скалодрома.
                  </Typography>
                  <Typography
                    component="div"
                    sx={climbingTitleBlock.secondaryTextThin}
                  >
                    Для посетителей открыт просторный зал, окруженный
                    панорамными окнами. На&nbsp;13-метровом стенде для лазания
                    на трудность есть зона с&nbsp;имитацией настоящей скалы
                  </Typography>
                </Stack>

                <Button
                  component={Link}
                  to="/climbing"
                  variant="outlined"
                  sx={seasonsTitleBlock.button}
                >
                  <Box sx={{ px: 12 }}>ПОДРОБНЕЕ</Box>
                </Button>
              </Grid>

              <Grid item md={6} xs={12}>
                <Box
                  sx={seasonsTitleBlock.imageSides2}
                  component="img"
                  src="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/Screenshot%202021-11-17%20174546.png?alt=media&token=5d284fbe-029f-4d69-8e19-0910bdebc61c"
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box sx={{ display: "flex", bgcolor: "#eb3333" }}>
          <Container maxWidth="lg">
            <Grid sx={{ mb: 4 }} container spacing={2}>
              <Grid sx={titles.seasons} item xs={12}>
                <Typography sx={rogaineTitleBlock.mainText} component="div">
                  ФИГУРНОЕ КАТАНИЕ
                </Typography>
              </Grid>

              <Grid item md={6} xs={12}>
                <Stack spacing={2}>
                  <Typography sx={rogaineTitleBlock.secondaryTextThin}>
                    Фигурное катание&nbsp;&mdash; красивый вид спорта
                    и&nbsp;одна из&nbsp;любимейших зимних забав москвичей. Наша
                    площадка придётся по&nbsp;душе как желающим улучшить свои
                    навыки катания на&nbsp;льду, так и любителям активного
                    и&nbsp;веселого отдыха.
                  </Typography>

                  <Box>
                    <Typography
                      sx={rogaineTitleBlock.secondaryTextThin}
                      component="div"
                    >
                      Приходите к нам:
                    </Typography>
                    <Typography
                      sx={rogaineTitleBlock.secondaryText}
                      component="div"
                    >
                      27-28 ноября (сб/вс)
                    </Typography>
                    <Typography
                      sx={rogaineTitleBlock.secondaryText}
                      component="div"
                    >
                      2-3 декабря (чт/пт)
                    </Typography>
                    <Typography
                      sx={rogaineTitleBlock.secondaryText}
                      component="div"
                    >
                      9-10 декабря (чт/пт)
                    </Typography>
                    <Typography
                      sx={rogaineTitleBlock.secondaryText}
                      component="div"
                    >
                      16-17 декабря (чт/пт)
                    </Typography>
                    <Typography
                      sx={rogaineTitleBlock.secondaryText}
                      component="div"
                    >
                      25-26 декабря (сб/вс)
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      sx={rogaineTitleBlock.secondaryText}
                      component="div"
                    >
                      Начинаем ровно в 10:30!
                    </Typography>

                    <Typography
                      sx={rogaineTitleBlock.secondaryTextThin}
                      component="div"
                    >
                      Мы проведем для вас бодрую разминку на льду и поделимся
                      искусством владения коньком на своих мастер-классах.
                    </Typography>
                  </Box>
                </Stack>

                <Button
                  component={Link}
                  to="/skating"
                  variant="outlined"
                  sx={boxRegisterSmall.buttonBig}
                >
                  <Box sx={{ px: 12 }}>ПОДРОБНЕЕ</Box>
                </Button>
              </Grid>

              <Grid item md={6} xs={12}>
                <Box
                  sx={seasonsTitleBlock.imageSides2}
                  component="img"
                  src="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/Screenshot%202021-11-17%20174906.png?alt=media&token=9cd7822a-afab-439b-987f-f50049e07113"
                />
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box id="schedule" sx={{ display: "flex", bgcolor: "white" }}>
          <Container maxWidth="lg">
            <Grid container spacing={2}>
              <Grid sx={titles.seasons} item xs={12}>
                <Typography sx={scheduleTitleBlock.mainText} component="div">
                  РАСПИСАНИЕ РАБОТЫ СЕКЦИЙ
                </Typography>

                {schedule.map((days, i) => {
                  return (
                    <>
                      {matches ? (
                        <Stack
                          spacing={5}
                          sx={{ my: 4 }}
                          key={i}
                          direction="row"
                        >
                          {renderSchedule(days)}
                        </Stack>
                      ) : (
                        <Stack
                          sx={{ my: 4 }}
                          key={i}
                          spacing={2}
                          direction="column"
                        >
                          {renderSchedule(days)}
                        </Stack>
                      )}
                      {i !== schedule.length ? <Divider /> : null}
                    </>
                  );
                })}
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Divider />

        <Box id="galery" sx={{ display: "flex", bgcolor: "white" }}>
          <Container maxWidth="lg">
            <Grid container spacing={2}>
              <Grid sx={titles.seasons} item xs={12}>
                <Typography sx={scheduleTitleBlock.mainText} component="div">
                  ГАЛЕРЕЯ
                </Typography>
              </Grid>

              <Grid sx={{ mb: 5 }} item xs={12}>
                {galery}
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Stack id="prizes" sx={{ display: "flex", bgcolor: "#eb3333" }}>
          <Container sx={{ mb: 3 }} maxWidth="lg">
            <Grid container spacing={2}>
              <Grid sx={titles.seasons} item xs={12}>
                <Typography sx={prizesTitleBlock.mainText} component="div">
                  ПРИЗЫ
                </Typography>
                <Typography sx={prizesTitleBlock.secondaryText} component="div">
                  ВСЕ УЧАСТНИКИ ПОЛУЧАЮТ СУВЕНИРЫ ОТ&nbsp;МОСКОВСКОГО СПОРТА!
                </Typography>
              </Grid>
            </Grid>
          </Container>
          <Stack direction="row">
            {matches ? (
              <Box sx={{ backgroundColor: "white", flex: 1 }}></Box>
            ) : null}

            <Box
              sx={
                matches
                  ? { backgroundColor: "white", flex: 4 }
                  : { backgroundColor: "white", my: 3, pl: 5, pr: 5, flex: 4 }
              }
            >
              <Grid
                justifyContent="center"
                alignItems="center"
                container
                sx={{ my: 5 }}
                spacing={1}
              >
                {prizes.map((prize, i) => {
                  return (
                    <Stack
                      justifyContent="flex-end"
                      alignItems="center"
                      sx={{ flex: 1, mx: 3 }}
                    >
                      <Box
                        key={i}
                        component="img"
                        sx={prizesTitleBlock.imagePrize}
                        src={prize}
                      />

                      <Typography sx={prizesTitleBlock.secondaryTextPrize}>
                        {prizeDesc[i].name}
                      </Typography>
                      <Typography
                        textAlign="center"
                        sx={prizesTitleBlock.secondaryTextThin}
                      >
                        {prizeDesc[i].desc}
                      </Typography>
                    </Stack>
                  );
                })}
              </Grid>
            </Box>
            <Box sx={{ bgcolor: "#eb3333", flex: 1 }} />
          </Stack>

          <Container maxWidth="lg">
            <Grid container spacing={2}>
              <Grid sx={{ mt: 2 }} item xs={12}>
                <Stack
                  sx={{ mb: 5 }}
                  direction="column"
                  alignItems="flex-end"
                  spacing={1}
                ></Stack>
              </Grid>
            </Grid>
          </Container>
        </Stack>
        {matches ? null : (
          <>
            <Box id="contacts" sx={boxRegisterMap.background}>
              <Typography sx={mainTitleBlock.red}>
                <Box sx={{ fontSize: 50 }}>КОНТАКТЫ</Box>
              </Typography>

              <Stack sx={{ my: 3 }} spacing={1} direction="column">
                <Typography sx={boxRegisterMap.textSecondary}>
                  Эл. почта
                </Typography>
                <Typography
                  component="a"
                  href="mailto:seasons.vdnh@yandex.ru"
                  sx={boxRegisterMap.textThin}
                >
                  seasons.vdnh@yandex.ru
                </Typography>
              </Stack>
              <Stack spacing={1} direction="column">
                <Typography sx={boxRegisterMap.textSecondary}>
                  Место проведения
                </Typography>
                <Typography sx={boxRegisterMap.textThin}>
                  ВДНХ, НАПРОТИВ ПАВИЛЬОНА &#8470;&nbsp;57&nbsp;
                </Typography>
                <Typography sx={boxRegisterMap.textThin}>
                  Г. МОСКВА, ПР-Т МИРА, Д. 119, СТР. 57
                </Typography>
              </Stack>
            </Box>

            <YMaps>
              <Box
                component={Map}
                defaultState={{ center: [55.833386, 37.62492], zoom: 15 }}
                sx={{ width: "100%", height: 500 }}
              >
                <Placemark geometry={[55.833386, 37.62492]} />
              </Box>
            </YMaps>
          </>
        )}

        {!matches ? null : (
          <Stack id="contacts" direction="row">
            <Box bgcolor="white" sx={{ flex: 0.5, py: 9, pl: 9, pr: 3 }}>
              <Stack alignItems="flex-end">
                <Box sx={boxRegisterMap.background}>
                  <Typography sx={mainTitleBlock.red}>
                    <Box sx={{ fontSize: 50 }}>КОНТАКТЫ</Box>
                  </Typography>

                  <Stack sx={{ my: 3 }} spacing={1} direction="column">
                    <Typography sx={boxRegisterMap.textSecondary}>
                      Эл. почта
                    </Typography>
                    <Typography
                      component="a"
                      href="mailto:seasons.vdnh@yandex.ru"
                      sx={boxRegisterMap.textThin}
                    >
                      seasons.vdnh@yandex.ru
                    </Typography>
                  </Stack>
                  <Stack spacing={1} direction="column">
                    <Typography sx={boxRegisterMap.textSecondary}>
                      Место проведения
                    </Typography>
                    <Typography sx={boxRegisterMap.textThin}>
                      ВДНХ, НАПРОТИВ ПАВИЛЬОНА &#8470;&nbsp;57&nbsp;
                    </Typography>
                    <Typography sx={boxRegisterMap.textThin}>
                      Г. МОСКВА, ПР-Т МИРА, Д. 119, СТР. 57
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Box>
            <Box sx={{ flex: 1 }}>
              <YMaps>
                <Box
                  component={Map}
                  defaultState={{ center: [55.833386, 37.62492], zoom: 15 }}
                  sx={{ height: "100%" }}
                >
                  <Placemark geometry={[55.833386, 37.62492]} />
                </Box>
              </YMaps>
            </Box>
          </Stack>
        )}

        <Divider />
        <Footer matches={matches} />
      </Box>
    </>
  );
}

export default Home;
