import {Box, Button, Card, CardMedia, Container, Grid, Stack, Typography} from "@mui/material";

import React from "react";
import {Link} from "@mui/material";

function SportFormat(props) {
    const { theme, matches } = props;

    const sportFormat = {
        cover: {
            backgroundColor: "white",
        },
        headerText: {
            lineHeight: 1,
            color: "#eb3333",
            fontFamily: "Mossport",
            mb: 0,
            [theme.breakpoints.down("md")]: {
                fontSize: "55px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "85px",
            },
        },
        primaryText: {
            color: "black",
            fontFamily: "Gotham Pro",

            [theme.breakpoints.down("md")]: {
                fontSize: "16px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "13px",
            },
        },
        image: {
            [theme.breakpoints.down("md")]: {
                maxWidth: "100%",
                height: "auto",
            },
            [theme.breakpoints.up("md")]: {
                height: "380px",
            },
        },
        button: {
            backgroundColor: "#eb3333",
            borderRadius: 0,
            fontFamily: "Gotham ProBold",
            fontWeight: "bold",
            fontSize: "13px",

            color: "white",
            [theme.breakpoints.down("md")]: {
                fontSize: "10px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "13px",
            },
            "&:hover": {
                background: "white",
                borderColor: "#eb3333",
                color: "#eb3333",
            },
        },
    };

    return(
        <Box sx={sportFormat.cover}>
            <Container sx={{ my: 4 }} maxWidth="lg">
                <Stack sx={{ my: 2 }}>
                    <Typography sx={sportFormat.headerText} component="div">
                        СПОРТИВНЫЙ ФОРМАТ
                    </Typography>

                    <Grid md={6} xs={12}>
                        <Typography sx={sportFormat.primaryText} component="div">
                            Проходит по классическим правилам ориентирования по выбору.
                            Участникам необходимо найти все контрольные пункты (40 КП),
                            отмеченные на карте, и вернуться на финиш за минимально
                            возможное время. Перемещаться разрешается либо бегом либо
                            пешком. Использование любых транспортных средств запрещается.
                        </Typography>
                    </Grid>

                    <Stack spacing={2} sx={{ my: 2 }} direction={matches ? "row" : "column"}>
                        <Box
                            sx={sportFormat.image}
                            component="img"
                            src="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F10.jpg?alt=media&token=0a9736c6-dd1a-44b5-a960-4b0418836820"
                        />

                        <Box
                            sx={sportFormat.image}
                            component="img"
                            src="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F39.jpg?alt=media&token=03ffa03e-798d-4047-a80b-404abe9453fb"
                        />
                    </Stack>

                    <Grid md={6} xs={12}>
                        <Button component={Link} href="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/files%2F%D0%BF%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5.pdf?alt=media&token=ce85a9ac-a774-4df5-a5be-1c95643acd11" sx={sportFormat.button} variant="outlined">
                            <Box sx={{ px: 3 }}>ПОЛОЖЕНИЕ О ПРОВЕДЕНИИ</Box>
                        </Button>
                    </Grid>
                </Stack>
            </Container>
        </Box>
    );
}

export default SportFormat;