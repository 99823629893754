import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";

function Prizes(props) {
  const { theme, club } = props;
  const prizes = {
    cover: {
      backgroundColor: "#eb3333",
    },
    headerText: {
      color: "white",
      fontFamily: "Mossport",
      lineHeight: 1,
      [theme.breakpoints.down("md")]: {
        fontSize: "55px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "85px",
      },
    },
    primaryText: {
      color: "white",
      fontFamily: "Gotham Pro",

      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "13px",
      },
    },
    secondaryText: {
      color: "black",
      fontFamily: "Gotham Pro",

      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "13px",
      },
    },
    imagePrize: {
      mx: 1,
      my: 3,
      [theme.breakpoints.down("md")]: {
        height: "100px",
      },
      [theme.breakpoints.up("md")]: {
        height: "100px",
      },
    },

    button: {
      backgroundColor: "white",
      borderRadius: 0,
      fontFamily: "Gotham ProBold",
      fontWeight: "bold",
      fontSize: "13px",

      color: "#eb3333",
      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "13px",
      },
      "&:hover": {
        background: "#eb3333",
        borderColor: "white",
        color: "white",
      },
    },
  };
  const [list, setList] = useState([]);
  const [text, setText] = useState("");

  useEffect(() => {
    switch (club) {
      case "rogaine":
        setList(prizeList.rogaine);
        setText("УЧАСТВУЯ В РОГЕЙНЕ ВЫ ПОЛУЧИТЕ НЕ ТОЛЬКО УДОВОЛЬСТВИЕ, НО СУВЕНИРЫ ОТ МОСКОВСКОГО СПОРТА!")
        break;
      case "climbing":
        setList(prizeList.climbing)
        setText("ПОСЕЩАЯ УРОКИ СКАЛОЛАЗАНИЯ, ВЫ НЕ ТОЛЬКО СТАНЕТЕ СИЛЬНЕЕ, НО И ПОЛУЧИТЕ СУВЕНИРЫ ОТ МОСКОВСКОГО СПОРТА!")
        break;
      case "skating":
        setList(prizeList.skating)
          setText("ЗАНИМАЙТЕСЬ В ШКОЛЕ ФИГУРНОГО КАТАНИЯ И ПОЛУЧАЙТЕ СУВЕНИРЫ ОТ МОСКОВСКОГО СПОРТА!")
        break;
    }
  }, [club]);

  const prizeList = {
    rogaine: [
      {
        title: "ПЕРВОЕ УЧАСТИЕ:",
        name: "СУМКА “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F%D1%81%D1%83%D0%BC%D0%BA%D0%B0.jpg?alt=media&token=f7c122e1-60f8-43d4-86ec-19031bb816ce",
      },
      {
        title: "ВТОРОЕ УЧАСТИЕ:",
        name: "БАФФ “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F%D0%91%D0%B0%D1%84%D1%84.jpg?alt=media&token=a38f2cbd-488d-46be-bd6a-7b384a308395",
      },
      {
        title: "ТРЕТЬЕ УЧАСТИЕ:",
        name: "ШАПКА “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2Fhat.png?alt=media&token=25687e27-35f2-4536-8542-e32697e32999",
      },
      {
        title: "ЧЕТВЁРТОЕ УЧАСТИЕ:",
        name: "КОМПАС “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F7451.30_5.jpg?alt=media&token=72b6e8e0-c072-4aae-955a-bc476916e7b1",
      },
    ],

    climbing: [
      {
        title: "ПЕРВОЕ УЧАСТИЕ:",
        name: "Футболка “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2FWhatsApp%20Image%202021-11-09%20at%2012.03.36.jpeg?alt=media&token=aa6d851e-1261-4ca3-b40f-33bea7650096",
      },
      {
        title: "ВТОРОЕ УЧАСТИЕ:",
        name: "БАФФ “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F%D0%91%D0%B0%D1%84%D1%84.jpg?alt=media&token=a38f2cbd-488d-46be-bd6a-7b384a308395",
      },
      {
        title: "ТРЕТЬЕ УЧАСТИЕ:",
        name: "ШАПКА “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2Fhat.png?alt=media&token=25687e27-35f2-4536-8542-e32697e32999",
      },
      {
        title: "ЧЕТВЁРТОЕ УЧАСТИЕ:",
        name: "ТЕРМОСТАКАН “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F7f0efd6b58d4825f.jpg?alt=media&token=80451607-e52b-41df-8207-696a6018db6b",
      },
    ],

    skating: [
      {
        title: "ПЕРВОЕ УЧАСТИЕ:",
        name: "Футболка “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2FWhatsApp%20Image%202021-11-09%20at%2012.03.36.jpeg?alt=media&token=aa6d851e-1261-4ca3-b40f-33bea7650096",
      },
      {
        title: "ВТОРОЕ УЧАСТИЕ:",
        name: "Перчатки “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2Fgloves.png?alt=media&token=97cbf55b-0814-49b3-ab3d-860816a2723c",
      },
      {
        title: "ТРЕТЬЕ УЧАСТИЕ:",
        name: "ШАПКА “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2Fhat.png?alt=media&token=25687e27-35f2-4536-8542-e32697e32999",
      },
      {
        title: "ЧЕТВЁРТОЕ УЧАСТИЕ:",
        name: "ТЕРМОСТАКАН “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F7f0efd6b58d4825f.jpg?alt=media&token=80451607-e52b-41df-8207-696a6018db6b",
      },
    ],
  };

  let listBlock;

  const renderList =  (p) => {
    return p.map((prize,i) => {
      return(
          <Grid item xs={12}>
            <Stack
                direction="row"
                justifyContent={i % 2 !== 0 ? "flex-end" : "flex-start"}
            >
              <Box sx={{ backgroundColor: "white", py: 1, px: 2 }}>
                <Stack direction="row" sx={{ mx: 3, my: 1 }} spacing={2}>
                  <Stack spacing={2}>
                    <Typography sx={prizes.secondaryText}>
                      {prize.title}
                    </Typography>

                    <Typography sx={prizes.secondaryText}>
                      {prize.name}
                    </Typography>
                  </Stack>

                  <Box
                      component="img"
                      sx={prizes.imagePrize}
                      src={prize.link}
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>
      );
    })
  }

  try{
    listBlock = renderList(list)
  }catch (e){

  }

  return (
    <Box sx={prizes.cover}>
      <Container sx={{ my: 4 }} maxWidth="lg">
        <Stack sx={{ my: 2 }} spacing={2}>
          <Typography sx={prizes.headerText} component="div">
            ПРИЗЫ
          </Typography>
          <Typography sx={prizes.primaryText} component="div">
            {text}
          </Typography>

          {listBlock}

          <Typography textAlign="right" sx={prizes.primaryText} component="div">
            ПОСЛЕ ЗАВЕРШЕНИЯ МЕРОПРИЯТИЯ, ПРИЗЫ ПОЯВЯТСЯ У ВАС В ЛИЧНОМ
            КАБИНЕТЕ. ПОЛУЧИТЬ ИХ МОЖНО БУДЕТ, ПРЕДЪЯВИВ ПАСПОРТ НА СТОЙКЕ
            РЕГИСТРАЦИИ АДМИНИСТРАТОРУ
          </Typography>

          <Stack alignItems="flex-end">
            <Button
              component={Link}
              to="/profile"
              sx={prizes.button}
              variant="outlined"
            >
              <Box sx={{ px: 3 }}>Личный кабинет</Box>
            </Button>
          </Stack>


        </Stack>
      </Container>
    </Box>
  );
}

export default Prizes;
