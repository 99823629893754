import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import {HashLink} from "react-router-hash-link";

function WhiteBox(props) {
  const { theme } = props;
  const whiteBox = {
    background: {
      backgroundColor: "white",
      px: 3,
      py: 4,
    },
    headerText: {
      fontSize: "24px",
      color: "black",
      fontFamily: "Mossport",
      [theme.breakpoints.down("md")]: {
        fontSize: "28px",
        mb: 2,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "24px",
      },
    },
    primaryText: {
      fontWeight: "bold",
      color: "black",
      fontSize: "11px",
      fontFamily: "Gotham ProBold",

      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "11px",
      },
    },
    button: {
      backgroundColor: "#eb3333",
      borderRadius: 0,
      fontFamily: "Gotham ProBold",
      fontWeight: "bold",
      fontSize: "13px",
      color: "white",

      [theme.breakpoints.down("md")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "13px",
      },
      "&:hover": {
        background: "white",
        borderColor: "#eb3333",
        color: "#eb3333",
      },
    },
  };

  return (
    <Box sx={whiteBox.background}>
      <Container sx={{ my: 4 }} maxWidth="lg">
        <Typography sx={whiteBox.headerText}>
          13 НОЯБРЯ — 28 НОЯБРЯ
        </Typography>
        <Typography sx={whiteBox.primaryText}>
          Bigwallsport— это настоящее городское скалолазание.
        </Typography>
        <Box sx={{ my: 3 }}>
          <Typography sx={whiteBox.primaryText}>
            Наш большой и светлый павильон — это целый мир уникального личного
            опыта и комфортная среда для общения между гостями скалодрома.
          </Typography>
        </Box>

        <Button component={HashLink} smooth to='/climbing#schedule' sx={whiteBox.button} variant="outlined">
          <Box sx={{ px: 3 }}>РАСПИСАНИЕ</Box>
        </Button>
      </Container>
    </Box>
  );
}

export default WhiteBox;
