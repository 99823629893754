import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";

function PrizesProcess(props) {
  const { theme, user } = props;
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const prizeProcess = {
    cover: {
      backgroundColor: "#eb3333",
    },
    headerText: {
      color: "white",
      fontFamily: "Mossport",
      lineHeight: 1,
      [theme.breakpoints.down("md")]: {
        fontSize: "55px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "85px",
      },
    },
    primaryText: {
      color: "white",
      fontFamily: "Gotham ProBold",
      fontWeight: "bold",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "26px",
      },
    },
    image: {
      [theme.breakpoints.down("md")]: {
        height: "230px",
      },
      [theme.breakpoints.up("md")]: {
        height: "300px",
      },
    },
    button: {
      backgroundColor: "white",
      borderRadius: 0,
      fontFamily: "Gotham ProBold",
      fontWeight: "bold",
      fontSize: "13px",

      color: "#eb3333",
      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "13px",
      },
      "&:hover": {
        background: "#eb3333",
        borderColor: "white",
        color: "white",
      },
    },
  };
  const prizes = {
    cover: {
      backgroundColor: "#eb3333",
    },
    headerText: {
      color: "white",
      fontFamily: "Mossport",
      lineHeight: 1,
      [theme.breakpoints.down("md")]: {
        fontSize: "55px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "85px",
      },
    },
    primaryText: {
      color: "white",
      fontFamily: "Gotham Pro",

      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "13px",
      },
    },
    secondaryText: {
      color: "black",
      fontFamily: "Gotham Pro",

      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "10px",
      },
    },
    secondaryDisabled: {
      color: "white",
      fontFamily: "Gotham Pro",

      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "10px",
      },
    },
    imagePrize: {
      mx: 1,
      my: 2,
      [theme.breakpoints.down("md")]: {
        height: "50px",
      },
      [theme.breakpoints.up("md")]: {
        height: "60px",
      },
    },
    imagePrizeDisabled: {
      mx: 1,
      my: 2,
      filter: "grayscale(100%)",
      [theme.breakpoints.down("md")]: {
        height: "50px",
      },
      [theme.breakpoints.up("md")]: {
        height: "60px",
      },
    },

    "&.Mui-disabled": {
      color: "white",
      background: "grey",
    },
  };

  const prizeList = {
    rogaine: [
      {
        title: "ПЕРВОЕ УЧАСТИЕ:",
        name: "СУМКА “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F%D1%81%D1%83%D0%BC%D0%BA%D0%B0.jpg?alt=media&token=f7c122e1-60f8-43d4-86ec-19031bb816ce",
      },
      {
        title: "ВТОРОЕ УЧАСТИЕ:",
        name: "БАФФ “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F%D0%91%D0%B0%D1%84%D1%84.jpg?alt=media&token=a38f2cbd-488d-46be-bd6a-7b384a308395",
      },
      {
        title: "ТРЕТЬЕ УЧАСТИЕ:",
        name: "ШАПКА “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2Fhat.png?alt=media&token=25687e27-35f2-4536-8542-e32697e32999",
      },
      {
        title: "ЧЕТВЁРТОЕ УЧАСТИЕ:",
        name: "КОМПАС “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F7451.30_5.jpg?alt=media&token=72b6e8e0-c072-4aae-955a-bc476916e7b1",
      },
    ],

    climbing: [
      {
        title: "ПЕРВОЕ УЧАСТИЕ:",
        name: "Футболка “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2FWhatsApp%20Image%202021-11-09%20at%2012.03.36.jpeg?alt=media&token=aa6d851e-1261-4ca3-b40f-33bea7650096",
      },
      {
        title: "ВТОРОЕ УЧАСТИЕ:",
        name: "БАФФ “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F%D0%91%D0%B0%D1%84%D1%84.jpg?alt=media&token=a38f2cbd-488d-46be-bd6a-7b384a308395",
      },
      {
        title: "ТРЕТЬЕ УЧАСТИЕ:",
        name: "ШАПКА “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2Fhat.png?alt=media&token=25687e27-35f2-4536-8542-e32697e32999",
      },
      {
        title: "ЧЕТВЁРТОЕ УЧАСТИЕ:",
        name: "ТЕРМОСТАКАН “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F7f0efd6b58d4825f.jpg?alt=media&token=80451607-e52b-41df-8207-696a6018db6b",
      },
    ],

    skating: [
      {
        title: "ПЕРВОЕ УЧАСТИЕ:",
        name: "Футболка “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2FWhatsApp%20Image%202021-11-09%20at%2012.03.36.jpeg?alt=media&token=aa6d851e-1261-4ca3-b40f-33bea7650096",
      },
      {
        title: "ВТОРОЕ УЧАСТИЕ:",
        name: "Перчатки “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2Fgloves.png?alt=media&token=97cbf55b-0814-49b3-ab3d-860816a2723c",
      },
      {
        title: "ТРЕТЬЕ УЧАСТИЕ:",
        name: "ШАПКА “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2Fhat.png?alt=media&token=25687e27-35f2-4536-8542-e32697e32999",
      },
      {
        title: "ЧЕТВЁРТОЕ УЧАСТИЕ:",
        name: "ТЕРМОСТАКАН “МОСКОВСКИЙ СПОРТ”",
        link: "https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F7f0efd6b58d4825f.jpg?alt=media&token=80451607-e52b-41df-8207-696a6018db6b",
      },
    ],
  };

  let render;

  const renderList = (p, progress) => {
    return p.map((prize, i) => {
      return (
        <Box sx={progress[i].received ? { backgroundColor: "white", py: 1, px: 2 } : { backgroundColor: "gray", py: 1, px: 2 }}>
          <Stack
            justifyContent="space-evenly"
            direction="row"
            sx={matches ? { mx: 2, my: 1 } : null}
            spacing={2}
          >
            <Stack spacing={2}>
              <Typography sx={progress[i].received ? prizes.secondaryText : prizes.secondaryDisabled}>{prize.title}</Typography>

              <Typography sx={progress[i].received ? prizes.secondaryText : prizes.secondaryDisabled}>{prize.name}</Typography>
            </Stack>

            <Box component="img" sx={progress[i].received ? prizes.imagePrize : prizes.imagePrizeDisabled} src={prize.link} />
          </Stack>
        </Box>
      );
    });
  };

  const renderProgress = () => {
    return (
      <Box sx={prizeProcess.cover}>
        <Container sx={{ my: 4 }} maxWidth="lg">
          <Stack sx={{ my: 2 }} spacing={2}>
            <Typography sx={prizeProcess.headerText} component="div">
              МОИ ПРИЗЫ
            </Typography>
            <Stack
              sx={{ flexBasis: 0 }}
              alignItems={matches ? "flex-end" : "center"}
              justifyContent="center"
              direction={matches ? "row" : "column"}
              spacing={2}
            >
              <Stack
                sx={{ flex: 1, width: "90%" }}
                spacing={1}
                direction="column"
              >
                <Typography sx={prizeProcess.primaryText}>
                  СЕКЦИЯ РОГЕЙНА
                </Typography>
                {renderList(prizeList.rogaine, user.rogaine_progress)}
              </Stack>

              <Stack
                sx={{ flex: 1, width: "90%" }}
                spacing={1}
                direction="column"
              >
                <Typography sx={prizeProcess.primaryText}>
                  СЕКЦИЯ СКАЛОЛАЗАНИЯ
                </Typography>
                {renderList(prizeList.climbing, user.climbing_progress)}
              </Stack>
              <Stack
                sx={{ flex: 1, width: "90%" }}
                spacing={1}
                direction="column"
              >
                <Typography sx={prizeProcess.primaryText}>
                  СЕКЦИЯ ФИГУРНОГО КАТАНИЯ
                </Typography>
                {renderList(prizeList.skating, user.skating_progress)}
              </Stack>
              {/*
            <Stack direction="column"></Stack>

            <Stack direction="column"></Stack>*/}
            </Stack>
          </Stack>
        </Container>
      </Box>
    );
  };

  try {
    render = renderProgress();
  } catch (e) {

  }

  return <>{render}</>;
}

export default PrizesProcess;
