import { Route, Switch } from "react-router-dom";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "./contexts/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./components/Login";
import Profile from "./components/profile-page-components/Profile";
import { CssBaseline } from "@mui/material";
import Register from "./components/Register";
import Home from "./components/home-page-components/Home";
import ForgotPassword from "./components/ForgotPassword";
import Climbing from "./components/climbing-page-components/Climbing";
import Rogaine from "./components/rogaine-page-components/Rogaine";
import Event from "./components/event-page-components/Event";
import EventForm from "./components/event-form-page-components/EventForm";
import { ruRU as coreRuRU } from "@mui/material/locale";
import { ruRU } from "@mui/x-data-grid";
import Question from "./components/question-page-components/Question";
import Skating from "./components/skating-page-components/Skating";
import Progress from "./components/event-page-components/Progress";

function App() {
  const theme = createTheme(
    {
      palette: {
        primary: {
          main: "#eb3333",
        },
        secondary: {
          main: "#eb3333",
        },

        background: {
          default: "rgba(0, 0, 0, 0.04)",
          navBar: "#fff",
        },
      },
    },
    ruRU,
    coreRuRU
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>

          <Route path="/login">
            <Login />
          </Route>

          <Route path="/forgot">
            <ForgotPassword />
          </Route>

          <Route path="/register">
            <Register />
          </Route>

          <Route path="/progress">
            <Progress/>
          </Route>

          <Route path="/skating">
            <Skating />
          </Route>

          <ProtectedRoute path="/event" component={Event} />

          <Route path="/question">
            <Question />
          </Route>

          <Route path="/form">
            <EventForm />
          </Route>

          <Route path="/climbing">
            <Climbing />
          </Route>

          <Route path="/rogaine">
            <Rogaine />
          </Route>

          <ProtectedRoute path="/profile" component={Profile} />
        </Switch>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
