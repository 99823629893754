import {Box, Container, IconButton, Stack, useMediaQuery} from "@mui/material";

import React from "react";
import {useTheme} from "@emotion/react";
import Link from "@mui/material/Link";

function Footer(props) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("lg"));
    return(
        <Box sx={{ display: "flex", bgcolor: "white" }}>
            <Container maxWidth="lg">
                <Stack direction="row">
                    <Stack alignItems="center" direction="row" spacing={1}>
                        <Box
                            sx={{ mt: 1 }}
                            component="a"
                            target="_blank"
                            href="https://www.mos.ru/moskomsport/"
                        >
                            <Box
                                sx={{ height: 30, margin: 1 }}
                                component="img"
                                src="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F%D0%B4%D0%B5%D0%BF%20%D1%81%D0%BF%D0%BE%D1%80%D1%82%D0%B0_%D0%BB%D0%BE%D0%B3%D0%BE.svg?alt=media&token=94cd45f4-0e08-4726-9870-9074e29230b1"
                            />
                        </Box>

                        <Box

                            component="a"
                            target="_blank"
                            href=" https://moscow.sport/"
                        >
                            <Box
                                sx={{ height: 30, margin: 1 }}
                                component="img"
                                src="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F%D0%9C%D0%A1_%D0%BB%D0%BE%D0%B3%D0%BE_RGB_1_%D0%BA%D1%80%D0%B0%D1%81.svg?alt=media&token=b13be87a-4565-4935-8426-1b76630622b3"
                            />
                        </Box>



                            <Box sx={{ mt: 1 }} component="a" target="_blank" href="https://vdnh.ru/">
                                <Box
                                    sx={{ height: 35, margin: 1 }}
                                    component="img"
                                    src="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2Fvdnh____.svg?alt=media&token=faac3caf-c4b0-48d8-affb-bd3f9f2b9b3f"
                                />
                            </Box>

                    </Stack>
                    {/*{props.matches ? (
                        <Stack sx={{width: "100%"}} direction="row" justifyContent="flex-end">
                            <IconButton href="https://www.instagram.com/moscow.sport_official/" component={Link} sx={{ backgroundColor: "grey", my: 2 }}>
                                <InstagramIcon />
                            </IconButton>
                        </Stack>
                    ) : null}*/}
                </Stack>
            </Container>
        </Box>
    );
}

export default Footer;