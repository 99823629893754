import { Link } from "react-router-dom";
import {
  AppBar,
  Box,
  Button, Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { HashLink } from "react-router-hash-link";
import CloseIcon from "@mui/icons-material/Close";

function createData(name, href) {
  return { name, href };
}

const rows = [
  /*  createData("О СЕЗОНАХ", "/"),
  createData("ДИСЦИПЛИНЫ", "/#disciplines"),*/
  createData("РАСПИСАНИЕ", "/#schedule"),
  createData("ГАЛЕРЕЯ", "/#galery"),
  createData("ПРИЗЫ", "/#prizes"),
  /*  createData("ПРАВИЛА", "/profile"),
 createData("О НАС", "/"),*/
  createData("КОНТАКТЫ", "/#contacts"),
  createData("ЛИЧНЫЙ КАБИНЕТ", "/profile"),
];

function MobileMenu() {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const list = () => (
    <Box
      sx={{ width: 280, ml: 3 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Stack alignItems="flex-end">
        <IconButton onClick={toggleDrawer(false)}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <List>
        <ListItem button to="/" component={Link}>
          <ListItemText>
            <Typography
              sx={{
                color: "#929292",
                fontSize: "20px",
                letterSpacing: "1px",
                fontFamily: "Mossport",
              }}
            >
              О СЕЗОНАХ
            </Typography>
          </ListItemText>
        </ListItem>

        <ListItem button to="/rogaine" component={Link}>
          <ListItemText>
            <Typography
              sx={{
                color: "#929292",
                fontSize: "20px",
                letterSpacing: "1px",
                fontFamily: "Mossport",
              }}
            >
              РОГЕЙН
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem button to="/climbing" component={Link}>
          <ListItemText>
            <Typography
              sx={{
                color: "#929292",
                fontSize: "20px",
                letterSpacing: "1px",
                fontFamily: "Mossport",
              }}
            >
              СКАЛОЛАЗАНИЕ
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem button to="/skating" component={Link}>
          <ListItemText>
            <Typography
              sx={{
                color: "#929292",
                fontSize: "20px",
                letterSpacing: "1px",
                fontFamily: "Mossport",
              }}
            >
              ФИГУРНОЕ КАТАНИЕ
            </Typography>
          </ListItemText>
        </ListItem>

        <ListItem button to="/#schedule" component={HashLink} smooth>
          <ListItemText>
            <Typography
              sx={{
                color: "#929292",
                fontSize: "20px",
                letterSpacing: "1px",
                fontFamily: "Mossport",
              }}
            >
              РАСПИСАНИЕ
            </Typography>
          </ListItemText>
        </ListItem>
          <ListItem button to="/#galery" component={HashLink} smooth>
              <ListItemText>
                  <Typography
                      sx={{
                          color: "#929292",
                          fontSize: "20px",
                          letterSpacing: "1px",
                          fontFamily: "Mossport",
                      }}
                  >
                      ГАЛЕРЕЯ
                  </Typography>
              </ListItemText>
          </ListItem>
        <ListItem button to="/#prizes" component={HashLink} smooth>
          <ListItemText>
            <Typography
              sx={{
                color: "#929292",
                fontSize: "20px",
                letterSpacing: "1px",
                fontFamily: "Mossport",
              }}
            >
              ПРИЗЫ
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem button to="/#contacts" component={HashLink} smooth>
          <ListItemText>
            <Typography
              sx={{
                color: "#929292",
                fontSize: "20px",
                letterSpacing: "1px",
                fontFamily: "Mossport",
              }}
            >
              КОНТАКТЫ
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem button to="/profile" component={Link}>
          <ListItemText>
            <Typography
              sx={{
                color: "#929292",
                fontSize: "20px",
                letterSpacing: "1px",
                fontFamily: "Mossport",
              }}
            >
              ЛИЧНЫЙ КАБИНЕТ
            </Typography>
          </ListItemText>
        </ListItem>
      </List>

      <Stack mt={3} direction="column">
        <Box
          component="a"
          target="_blank"
          href="https://www.mos.ru/moskomsport/"
        >
          <Box
            sx={{ height: 54, margin: 1 }}
            component="img"
            src="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F%D0%B4%D0%B5%D0%BF%20%D1%81%D0%BF%D0%BE%D1%80%D1%82%D0%B0_%D0%BB%D0%BE%D0%B3%D0%BE.svg?alt=media&token=94cd45f4-0e08-4726-9870-9074e29230b1"
          />
        </Box>

        <Box component="a" target="_blank" href=" https://moscow.sport/">
          <Box
            sx={{ height: 54, margin: 1 }}
            component="img"
            src="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F%D0%9C%D0%A1_%D0%BB%D0%BE%D0%B3%D0%BE_RGB_1_%D0%BA%D1%80%D0%B0%D1%81.svg?alt=media&token=b13be87a-4565-4935-8426-1b76630622b3"
          />
        </Box>

        <Box component="a" target="_blank" href="https://vdnh.ru/">
          <Box
            sx={{ height: 54, margin: 1 }}
            component="img"
            src="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2Fvdnh____.svg?alt=media&token=faac3caf-c4b0-48d8-affb-bd3f9f2b9b3f"
          />
        </Box>
      </Stack>
    </Box>
  );

  return (
    <>
      <IconButton
        onClick={toggleDrawer(true)}
        size="large"
        edge="start"
        sx={{ mr: 2, color: "black" }}
      >
        <MenuIcon sx={{ width: 50, height: 50 }} />
      </IconButton>
      <Drawer anchor={"right"} open={open} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </>
  );
}

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

function NavBar() {
  const club = [
    createData("РОГЕЙН", "/rogaine"),
    createData("СКАЛОЛАЗАНИЕ", "/climbing"),
    createData("ФИГУРНОЕ КАТАНИЕ", "/skating"),
  ];

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let data;

  const renderNavBar = () => {
    return (
      <>
        {matches ? (
          <>
            <AppBar sx={{ bgcolor: "background.navBar"}} position="fixed">
              <Container sx={{ bgcolor: "background.navBar"}} maxWidth="lg">
                <Toolbar sx={{ bgcolor: "background.navBar", minHeight: 90 }}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Box
                        sx={{ mt: 1 }}
                        component="a"
                        target="_blank"
                        href="https://www.mos.ru/moskomsport/"
                    >
                      <Box
                          sx={{ height: 50, margin: 1 }}
                          component="img"
                          src="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F%D0%B4%D0%B5%D0%BF%20%D1%81%D0%BF%D0%BE%D1%80%D1%82%D0%B0_%D0%BB%D0%BE%D0%B3%D0%BE.svg?alt=media&token=94cd45f4-0e08-4726-9870-9074e29230b1"
                      />
                    </Box>

                    <Box

                        component="a"
                        target="_blank"
                        href=" https://moscow.sport/"
                    >
                      <Box
                          sx={{ height: 50, margin: 1 }}
                          component="img"
                          src="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F%D0%9C%D0%A1_%D0%BB%D0%BE%D0%B3%D0%BE_RGB_1_%D0%BA%D1%80%D0%B0%D1%81.svg?alt=media&token=b13be87a-4565-4935-8426-1b76630622b3"
                      />
                    </Box>

                    <Box sx={{ mt: 1 }} component="a" target="_blank" href="https://vdnh.ru/">
                      <Box
                          sx={{ height: 65, margin: 1 }}
                          component="img"
                          src="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2Fvdnh____.svg?alt=media&token=faac3caf-c4b0-48d8-affb-bd3f9f2b9b3f"
                      />
                    </Box>
                  </Stack>


                  <Typography
                      component="div"
                      sx={{ flexGrow: 1, fontSize: 20 }}
                  />

                  <Box>
                    <Button
                        sx={{
                          color: "#929292",
                          fontSize: "20px",
                          marginRight: "5px",
                          fontFamily: "Mossport",
                          letterSpacing: "1px",
                        }}
                        component={HashLink}
                        smooth
                        to="/"
                        size="small"
                    >
                      О СЕЗОНАХ
                    </Button>

                    <Button
                        sx={{
                          color: "#929292",
                          fontSize: "20px",
                          marginRight: "5px",
                          fontFamily: "Mossport",
                          letterSpacing: "1px",
                        }}
                        onClick={handleClick}
                        size="small"
                    >
                      ДИСЦИПЛИНЫ
                    </Button>
                    {rows.map((row, i) => {
                      return (
                          <Button
                              sx={{
                                color: "#929292",
                                fontSize: "20px",
                                marginRight: "5px",
                                fontFamily: "Mossport",
                                letterSpacing: "1px",
                              }}
                              component={HashLink}
                              smooth
                              key={i}
                              to={row.href}
                              size="small"
                          >
                            {row.name}
                          </Button>
                      );
                    })}
                  </Box>
                </Toolbar>
              </Container>

            </AppBar>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {club.map((row, i) => {
                return (
                  <MenuItem key={i} component={Link} to={row.href}>
                    <Typography
                      sx={{
                        color: "#929292",
                        fontSize: "20px",
                        letterSpacing: "1px",
                        fontFamily: "Mossport",
                      }}
                    >
                      {row.name}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Menu>
            <Offset />
          </>
        ) : (
          <Stack
            sx={{ position: "fixed", width: "100%" }}
            justifyContent="flex-end"
            direction="row"
          >
            <MobileMenu sx={{ position: "fixed" }} />
          </Stack>
        )}
      </>
    );
  };

  try {
    data = renderNavBar();
  } catch (e) {}

  return <>{data}</>;
}

NavBar.propTypes = {
  title: PropTypes.any,
};

export default NavBar;
