import {Box, Button, Card, CardMedia, Container, Grid, Link, Stack, Typography} from "@mui/material";

import React from "react";

function ActiveFormat(props) {
    const { theme, matches } = props;
    const activeFormat = {
        cover: {
            backgroundColor: "#eb3333",
        },
        headerText: {
            color: "white",
            fontFamily: "Mossport",
            lineHeight: 1,
            [theme.breakpoints.down("md")]: {
                fontSize: "55px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "85px",
            },
        },
        primaryText: {
            color: "white",
            fontFamily: "Gotham Pro",

            [theme.breakpoints.down("md")]: {
                fontSize: "16px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "13px",
            },
        },
        image: {
            [theme.breakpoints.down("md")]: {
                maxWidth: "100%",
                height: "auto",
            },
            [theme.breakpoints.up("md")]: {
                height: "380px",
            },
        },
        button: {
            backgroundColor: "white",
            borderRadius: 0,
            fontFamily: "Gotham ProBold",
            fontWeight: "bold",
            fontSize: "13px",

            color: "#eb3333",
            [theme.breakpoints.down("md")]: {
                fontSize: "10px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "13px",
            },
            "&:hover": {
                background: "#eb3333",
                borderColor: "white",
                color: "white",
            },
        },
    };
    return(
        <Box sx={activeFormat.cover}>
            <Container sx={{ my: 4 }} maxWidth="lg">
                <Stack sx={{ my: 2 }}>
                    <Typography sx={activeFormat.headerText} component="div">
                        АКТИВНО-ПОЗНАВАТЕЛЬНЫЙ ФОРМАТ
                    </Typography>

                    <Grid md={6} xs={12}>
                        <Typography sx={activeFormat.primaryText} component="div">
                            Проходит параллельно со Спортивным на той же территории и
                            карте. Участникам необходимо за 2 часа после старта найти как
                            можно больше КП, правильно ответить на вопросы и вернуться на
                            финиш не позднее чем через 2 часа после старта. Вопросы
                            зашифрованы в QR-ссылки, и для их прочтения и ответа необходим
                            смартфон с интернетом и приложением распознавания QR-кодов.
                            Нахождение КП и отметка электронным чипом (выдается на старте)
                            = 1 балл. Правильный ответ на вопрос +1 балл. Опоздание на
                            финиш на 1 минуту - минус 1 балл, на 2 минуты - минус 2 балла
                            и тд. Побеждает участник, набравший максимальную сумму баллов.
                        </Typography>
                    </Grid>

                    <Stack spacing={2} sx={{ my: 2 }} direction={matches ? "row" : "column"}>
                        <Box
                            sx={activeFormat.image}
                            component="img"
                            src="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2F18.jpg?alt=media&token=3cfb017d-5dc2-4ee6-8646-b468d907e2b2"
                        />

                        <Box
                            sx={activeFormat.image}
                            component="img"
                            src="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/pics%2FWhatsApp%20Image%202021-11-07%20at%2014.49.22.jpeg?alt=media&token=08ed94f9-e589-4c48-b113-1c0a84532ffe"
                        />
                    </Stack>


                    <Grid md={6} xs={12}>
                        <Button  component={Link} href="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/files%2F%D0%BF%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5.pdf?alt=media&token=ce85a9ac-a774-4df5-a5be-1c95643acd11" sx={activeFormat.button} variant="outlined">
                            <Box sx={{ px: 3 }}>ПОЛОЖЕНИЕ О ПРОВЕДЕНИИ</Box>
                        </Button>
                    </Grid>
                </Stack>
            </Container>
        </Box>
    );
}
export default ActiveFormat;