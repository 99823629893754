import { useAuth } from "../../contexts/AuthContext";
import {
  Box,
  Divider,
  Grid,
  Typography, useMediaQuery,
} from "@mui/material";
import NavBar from "../NavBar";
import {
  collectionGroup,
  doc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import React, { useEffect, useState } from "react";
import Info from "./Info";

import { getEventJson } from "../../firebase/FirebaseFunctions";

import { useTheme } from "@emotion/react";
import EventItem from "../event-page-components/EventItem";
import EventSchedule from "./EventSchedule";
import PrizesProcess from "./PrizesProcess";
import Footer from "../Footer";

function Profile() {
  const { currentUser } = useAuth();
  const [user, setUser] = useState();
  const [events, setEvents] = useState();
  const [edit, setEdit] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  let info;
  let carousel;

  useEffect(() => {
    getUser(currentUser.uid);
  }, [currentUser.uid]);

  useEffect(() => {
    onSnapshot(
      query(
        collectionGroup(firestore, `events`),
        where(`club`, `in`, ["skating", "rogaine", "climbing"])
      ),
      (docs) => {
        let e = [];
        docs.forEach((doc) => {
          if (doc.data().club === "rogaine" && doc.data().isClass === undefined) {
            if (doc.data().members_orientation.includes(currentUser.uid)) {
              e.push(getEventJson(doc));
              return;
            }
            if (doc.data().members_rogaine.includes(currentUser.uid)) {
              e.push(getEventJson(doc));
            }
          } else {
            if (doc.data().members.includes(currentUser.uid)) {
              e.push(getEventJson(doc));
            }
          }
        }

        );

        setEvents(e);
      }
    );
  }, [currentUser]);

  const getUser = (uid) => {
    onSnapshot(doc(firestore, `users/${uid}`), (doc) => {
      setUser(doc.data());
    });
  };

  const renderInfo = (edit) => {
    let {
      firstName,
      lastName,
      middleName,
      birthDate,
      email,
      phonenumber,
      sex,
    } = user;

    return (
      <Info
        handleClose={() => {
          setEdit(false);
        }}
        theme={theme}
        edit={edit}
        firstName={firstName}
        sex={sex}
        middleName={middleName}
        email={email}
        phonenumber={phonenumber}
        birthDate={birthDate}
        lastName={lastName}
      />
    );
  };

  const renderEventsCarousel = (events) => {
    if (events.length > 0)
      return events.map((doc, i) => {
        return (
          <Grid key={i} item xs={12}>
              <EventItem user={user} isProfile={true} event={doc} />
          </Grid>
        );
      });
    else
      return (
        <Grid item xs={12}>

            <Typography variant="h5">Здесь пока пусто</Typography>

        </Grid>
      );
  };


  try {
    info = renderInfo(edit);
    carousel = renderEventsCarousel(events);
  } catch (e) {}

  return (
    <>
      <NavBar />
      <Divider />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {info}
        <EventSchedule theme={theme}>{carousel}</EventSchedule>
        <PrizesProcess user={user} theme={theme} />
        <Footer matches={matches}/>
      </Box>
    </>
  );
}
export default Profile;
