import { Route, Redirect } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"

function ProtectedRoute({ component: Component, ...restOfProps }) {
    const { currentUser } = useAuth();

    return (
        <Route
            {...restOfProps}
            render={(props) =>
                currentUser ? <Component {...props} /> : <Redirect to="/login" />
            }
        />
    );
}

export default ProtectedRoute;