import {
  arrayRemove,
  arrayUnion,
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
  increment,
  documentId,
  addDoc,
  orderBy,
  limit,
  deleteDoc,
  writeBatch,
  setDoc,
} from "firebase/firestore";

import { firestore, functions, storage } from "../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { httpsCallable } from "firebase/functions";

export const getRoleOrganizer = async (club, user) => {
  let organizer = false;

  if (club === "class") {
    club = "rogaine";
  }

  await getDoc(doc(firestore, `clubs/${club}`)).then((doc) => {
    if (doc.exists())
      if (doc.data().organizers.includes(user.uid)) organizer = true;
  });

  return organizer;
};

export const getRoleMember = async (club, user, uid) => {
  let member = "";



  await getDoc(doc(firestore, `clubs/${club}/events/${uid}`)).then((doc) => {



    if (club !== "rogaine") {
      if (doc.exists())
        if (doc.data().members.includes(user.uid)) member = "default";
    } else {
      if (doc.data().isClass === undefined) {
        if (doc.exists())
          if (doc.data().members_rogaine.includes(user.uid))
            member = "rogaine";
        if (doc.exists())
          if (doc.data().members_orientation.includes(user.uid))
            member = "orientation";
      } else {
        if (doc.exists())
          if (doc.data().members.includes(user.uid)) member = "default";
      }
    }
  });



  return member;
};

export const getEventJson = (doc) => {
  if (doc.club === "rogaine")
    return {
      id: doc.id,
      description: doc.data().description,
      photoURL: doc.data().photoURL,
      startDate: doc.data().startDate,
      endDate: doc.data().endDate,
      organizer: doc.data().organizer,
      club: doc.data().club,
      isActive: doc.data().isActive,
      isOver: doc.data().isOver,
      members_rogaine: doc.data().members_rogaine,
      members_orientation: doc.data().members_orientation,
      results: doc.data().results,
      isHidden: doc.data().isHidden,
      isClass: doc.data().isClass,
      date: doc.data().date,
    };
  else
    return {
      id: doc.id,
      description: doc.data().description,
      photoURL: doc.data().photoURL,
      startDate: doc.data().startDate,
      endDate: doc.data().endDate,
      organizer: doc.data().organizer,
      club: doc.data().club,
      isActive: doc.data().isActive,
      price: doc.data().price,
      members_rogaine: doc.data().members_rogaine,
      members_orientation: doc.data().members_orientation,
      amount: doc.data().amount,
      isOver: doc.data().isOver,
      results: doc.data().results,
      isHidden: doc.data().isHidden,
      isClass: doc.data().isClass,
      date: doc.data().date,
    };
};

export const getEvents = async (club) => {
  const events = [];
  await getDocs(collection(firestore, `clubs/${club}/events`)).then((docs) => {
    docs.forEach((doc) => {
      if (doc.exists) {
        let event = getEventJson(doc);
        events.push(event);
      }
    });
  });

  return events;
};

export const leaveEvent = async (uid, eventUid, club, isClass) => {
  await deleteDoc(doc(firestore, `users/${uid}/events/${eventUid}`));



  if (isClass){
    await updateDoc(doc(firestore, `clubs/${club}/events/${eventUid}`), {
      members: arrayRemove(uid),
    });
  }

  if (club === "rogaine") {

    await updateDoc(doc(firestore, `clubs/${club}/events/${eventUid}`), {
      members_orientation: arrayRemove(uid),
      members_rogaine: arrayRemove(uid),
    });
  } else if (!isClass) {
    const user = await getDoc(doc(firestore, `users/${uid}`));

    const time = user.data().time;
    const result = time.find(({ uid }) => uid === eventUid);

    if (result)
      await updateDoc(doc(firestore, `users/${uid}`), {
        time: arrayRemove(result),
      });

    await updateDoc(doc(firestore, `clubs/${club}/events/${eventUid}`), {
      members: arrayRemove(uid),
      amount: increment(1),
    });
  }
};

export const getUserEvents = async (uid) => {
  let e = [];
  const events = query(
    collectionGroup(firestore, `events`),
    where(`club`, `in`, ["skating", "rogaine", "climbing", "class"])
  );

  const querySnapshot = await getDocs(events);

  await querySnapshot.forEach((doc) => {
    if (doc.data().club === "rogaine") {
      if (doc.data().members_orientation.includes(uid)) {
        e.push(getEventJson(doc));
        return;
      }
      if (doc.data().members_rogaine.includes(uid)) {
        e.push(getEventJson(doc));
      }
    } else {
      if (doc.data().members.includes(uid)) {
        e.push(getEventJson(doc));
      }
    }
  });
  return e;
};

export const getQuestionByLink = async (link) => {
  let question = [];
  await getDocs(
    query(
      collection(firestore, `clubs/rogaine/questions`),
      where("link", "==", link)
    )
  ).then((docs) => {
    docs.forEach((doc) => {
      question.push(doc.data());
    });
  });

  return question[0];
};

export const startEvent = async (eventUid, club) => {
  await updateDoc(doc(firestore, `clubs/${club}/events/${eventUid}`), {
    isActive: true,
  });
};

export const endEvent = async (eventUid, club) => {
  const batch = writeBatch(firestore);

  await updateDoc(doc(firestore, `clubs/${club}/events/${eventUid}`), {
    isActive: false,
    isOver: true,
  });

  const event = await getEvent(club, eventUid);

  switch (club) {
    case "rogaine":
      event.arrived_orientation.forEach((uid) => {
        batch.update(doc(firestore, `users/${uid}`), {
          rogaine_points: increment(1),
        });
      });

      event.arrived_rogaine.forEach((uid) => {
        batch.update(doc(firestore, `users/${uid}`), {
          rogaine_points: increment(1),
        });
      });
      break;
    case "skating":
      event.arrived.forEach((uid) => {
        batch.update(doc(firestore, `users/${uid}`), {
          skating_points: increment(1),
        });
      });
      break;
    case "climbing":
      /*      event.arrived_orientation.forEach((uid) => {
        batch.update(doc(firestore, `users/${uid}`), {
          climbing_points: increment(1),
        });
      });*/
      break;
  }

  await batch.commit();
};

export const getMembers = async (club, eventUid, type) => {

  let members = [];

  let list;

  if (club === "class")
    await getDoc(doc(firestore, `clubs/rogaine/events/${eventUid}`)).then(
      (doc) => {
        if (type === "orientation")
          if (doc.exists()) list = doc.data().members_orientation;

        if (type === "rogaine")
          if (doc.exists()) list = doc.data().members_rogaine;

        if (type === "default")
          if (doc.exists()) list = doc.data().members;
      }
    );
  else
    await getDoc(doc(firestore, `clubs/${club}/events/${eventUid}`)).then(
      (doc) => {
        if (type === "orientation")
          if (doc.exists()) list = doc.data().members_orientation;

        if (type === "rogaine")
          if (doc.exists()) list = doc.data().members_rogaine;

        if (type === "default")
          if (doc.exists()) list = doc.data().members;
      }
    );

  if (list.length > 0)
    await getDocs(collection(firestore, `users`)).then((docs) => {
      docs.forEach(async (user) => {
        if (list.includes(user.id))
          if (user.exists()) {
            if (user.data().time) {
              const time = user.data().time.find(({ uid }) => uid === eventUid);
              if (time)
                members.push({
                  id: user.id,
                  firstName: user.data().firstName,
                  lastName: user.data().lastName,
                  middleName: user.data().middleName,
                  phonenumber: user.data().phonenumber,
                  email: user.data().email,
                  time: user.data().time.find(({ uid }) => uid === eventUid)
                    .time,
                });
              else
                members.push({
                  id: user.id,
                  firstName: user.data().firstName,
                  lastName: user.data().lastName,
                  phonenumber: user.data().phonenumber,
                  email: user.data().email,
                  middleName: user.data().middleName,
                  time: "",
                });
            } else
              members.push({
                id: user.id,
                firstName: user.data().firstName,
                lastName: user.data().lastName,
                middleName: user.data().middleName,
                email: user.data().email,
                phonenumber: user.data().phonenumber,
                time: "",
              });
          }
      });
    });

  return members;
};

function arrayUnique(array) {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }

  return a;
}

export const givePrizeByIndex = async (uid, index, club, value) => {
  let user = await getDoc(doc(firestore, `users/${uid}`));

  let progress;
  switch (club) {
    case "rogaine":
      progress = user.data().rogaine_progress;
      progress[index].received = value;

      await updateDoc(doc(firestore, `users/${uid}`), {
        rogaine_progress: progress,
      });
      break;
    case "skating":
      progress = user.data().skating_progress;
      progress[index].received = value;

      await updateDoc(doc(firestore, `users/${uid}`), {
        skating_progress: progress,
      });
      break;
    case "climbing":
      progress = user.data().climbing_progress;
      progress[index].received = value;

      await updateDoc(doc(firestore, `users/${uid}`), {
        climbing_progress: progress,
      });
      break;
  }
};

export const chooseTime = async (uid, eventUid, time) => {};

const getUserJson = (doc) => {
  return {
    id: doc.id,
    firstName: doc.data().firstName,
    middleName: doc.data().middleName,
    lastName: doc.data().lastName,
    rogaine_points: doc.data().rogaine_points,
    skating_points: doc.data().skating_points,
    climbing_points: doc.data().climbing_points,
    rogaine_progress: doc.data().rogaine_progress,
    skating_progress: doc.data().skating_progress,
    climbing_progress: doc.data().climbing_progress,
  };
};

export const getProgress = async (club) => {
  let members = [];
  let list = [];

  await getDocs(
    query(collectionGroup(firestore, `events`), where(`club`, `==`, club))
  ).then((docs) => {
    docs.forEach((doc) => {
      if (club === "rogaine") {
        list = arrayUnique(list.concat(doc.data().members_rogaine));
        list = arrayUnique(list.concat(doc.data().members_orientation));
      } else {
        list = arrayUnique(list.concat(doc.data().members));
      }
    });
  });

  if (list.length > 0)
    await getDocs(collection(firestore, `users`)).then((docs) => {
      docs.forEach((doc) => {
        if (list.includes(doc.id))
          if (doc.exists()) {
            members.push(getUserJson(doc));
          }
      });
    });

  return members;
};

export const joinEventClass = async (uid, eventUid, club) => {
  await updateDoc(doc(firestore, `clubs/${club}/events/${eventUid}`), {
    members: arrayUnion(uid),
  });
};

export const joinEvent = async (uid, eventUid, club, mail, time) => {
  await updateDoc(doc(firestore, `clubs/${club}/events/${eventUid}`), {
    members: arrayUnion(uid),
    amount: increment(-1),
  });


  if (club === "climbing")
    await setDoc(
        doc(firestore, `users/${uid}`),
        {
          time: arrayUnion({ uid: eventUid, time: time }),
        },
        { merge: true }
    );


  let text = "";

  switch (club) {
    case "skating":
      text = "Фигурное катание";
      break;
    case "climbing":
      text = "Скалолазание";
      break;
  }

  await addDoc(collection(firestore, `mail`), {
    to: mail,
    message: {
      subject: "Спортивные сезоны на ВДНХ",
      text: `Вы зарегистрировались на ${text} на сайте https://seasons.moscow.sport/. Не забудьте взять с собой паспорт.
Отменить запись можно в личном кабинете`,
    },
  });
};

export const joinEventOrientation = async (uid, eventUid, mail) => {
  await updateDoc(doc(firestore, `clubs/rogaine/events/${eventUid}`), {
    members_orientation: arrayUnion(uid),
  });

  await addDoc(collection(firestore, `mail`), {
    to: mail,
    message: {
      subject: "Спортивные сезоны на ВДНХ",
      text: `Вы зарегистрировались на Ориентирование на скорость на сайте https://seasons.moscow.sport/. Не забудьте взять с собой паспорт.
Отменить запись можно в личном кабинете`,
    },
  });
};

export const joinEventRogaine = async (uid, eventUid, mail) => {
  await updateDoc(doc(firestore, `clubs/rogaine/events/${eventUid}`), {
    members_rogaine: arrayUnion(uid),
  });

  await setDoc(doc(firestore, `users/${uid}/events/${eventUid}`), {
    answered_correct: [],
    answered_incorrect: [],
  });

  await addDoc(collection(firestore, `mail`), {
    to: mail,
    message: {
      subject: "Спортивные сезоны на ВДНХ",
      text: `Вы зарегистрировались на Рогейн с проверкой знаний на сайте https://seasons.moscow.sport/. Не забудьте взять с собой паспорт.
Отменить запись можно в личном кабинете`,
    },
  });
};

export const getMemberAnswered = async (uid, eventUid) => {
  let answered;

  await getDoc(doc(firestore, `users/${uid}/events/${eventUid}`)).then(
    (doc) => {
      if (doc.exists()) answered = doc.data();
    }
  );

  return answered;
};

export const updateArrived = async (club, eventUid, arrived) => {
  let old_arrived;
  if (club === "climbing" || club === "skating")
    await getDoc(doc(firestore, `clubs/${club}/events/${eventUid}`)).then(
      (doc) => {
        old_arrived = doc.data().arrived;
      }
    );

  if (club === "class")
    await updateDoc(doc(firestore, `clubs/rogaine/events/${eventUid}`), {
      arrived: arrived,
    });
  else
    await updateDoc(doc(firestore, `clubs/${club}/events/${eventUid}`), {
      arrived: arrived,
    });

  if (club === "climbing") {
    const batch = writeBatch(firestore);
    const event = await getEvent(club, eventUid);

    event.members.forEach((uid) => {
      if (event.arrived.includes(uid)) {
        batch.update(doc(firestore, `users/${uid}`), {
          climbing_points: increment(1),
        });
      }
      if (club === "climbing")
        if (old_arrived.includes(uid)) {
          batch.update(doc(firestore, `users/${uid}`), {
            climbing_points: increment(-1),
          });
        }
    });

    await batch.commit();
  }

  if (club === "skating") {
    const batch = writeBatch(firestore);
    const event = await getEvent(club, eventUid);

    event.members.forEach((uid) => {
      if (event.arrived.includes(uid)) {
        batch.update(doc(firestore, `users/${uid}`), {
          skating_points: increment(1),
        });
      }
      if (club === "skating")
        if (old_arrived.includes(uid)) {
          batch.update(doc(firestore, `users/${uid}`), {
            skating_points: increment(-1),
          });
        }
    });

    await batch.commit();
  }

};

export const updateArrivedRogaine = async (club, eventUid, arrived) => {
  await updateDoc(doc(firestore, `clubs/${club}/events/${eventUid}`), {
    arrived_rogaine: arrived,
  });
};

export const updateArrivedOrientation = async (club, eventUid, arrived) => {
  await updateDoc(doc(firestore, `clubs/${club}/events/${eventUid}`), {
    arrived_orientation: arrived,
  });
};

export const getArrived = async (club, eventUid, type) => {
  let arrived = [];
  if (club === "class")
    await getDoc(doc(firestore, `clubs/rogaine/events/${eventUid}`)).then(
      (doc) => {
        if (type === "orientation")
          if (doc.exists()) arrived = doc.data().arrived_orientation;

        if (type === "rogaine")
          if (doc.exists()) arrived = doc.data().arrived_rogaine;

        if (type === "default") if (doc.exists()) arrived = doc.data().arrived;
      }
    );
  else
    await getDoc(doc(firestore, `clubs/${club}/events/${eventUid}`)).then(
      (doc) => {
        if (type === "orientation")
          if (doc.exists()) arrived = doc.data().arrived_orientation;

        if (type === "rogaine")
          if (doc.exists()) arrived = doc.data().arrived_rogaine;

        if (type === "default") if (doc.exists()) arrived = doc.data().arrived;
      }
    );

  return arrived;
};

export const getEvent = async (club, eventUid) => {
  let event;
  await getDoc(doc(firestore, `clubs/${club}/events/${eventUid}`)).then(
    (doc) => {
      if (doc.exists()) event = doc.data();
    }
  );

  return event;
};

export const deleteEvent = async (club, eventUid) => {
  await deleteDoc(doc(firestore, `clubs/${club}/events/${eventUid}`));
  await deleteDoc(doc(firestore, `users/${club}/events/${eventUid}`));

  if (club === "rogaine") {
    const deleteByPath = httpsCallable(functions, "deleteByPath");
    deleteByPath({
      path: `clubs/${club}/events/${eventUid}/questions`,
    });
  }
};

export const getSelectedQuestionsByUid = async (club, eventUid) => {
  let questions = [];
  await getDocs(
    collection(firestore, `clubs/${club}/events/${eventUid}/questions`)
  ).then((docs) => {
    docs.forEach((doc) => {
      if (doc.exists()) questions.push(doc.data().id);
    });
  });

  return questions;
};

export const getQuestionsByUid = async (club, eventUid) => {
  let questions = [];
  await getDocs(
    query(
      collection(firestore, `clubs/${club}/events/${eventUid}/questions`),
      orderBy("id", "asc"),
      limit(60)
    )
  ).then((docs) => {
    docs.forEach((doc) => {
      if (doc.exists()) questions.push(doc.data());
    });
  });

  return questions;
};

export const createEventRogaine = async (
  description,
  organizer,
  startTime,
  endTime,
  date,
  image,
  club,
  selected
  /*  questions*/
) => {
  let url = "";

  if (image != null) {
    const imageRef = ref(storage, "events/images/" + image.name);

    await uploadBytes(imageRef, image);

    url = await getDownloadURL(imageRef);
  }

  await addDoc(collection(firestore, `clubs/${club}/events`), {
    description: description,
    organizer: organizer,
    startDate: date + " " + startTime,
    endDate: date + " " + endTime,
    photoURL: url,
    members_orientation: [],
    members_rogaine: [],
    arrived_orientation: [],
    arrived_rogaine: [],
    isActive: false,
    isOver: false,
    date: "",
    club: club,
  });

  /*  const setQuestions = httpsCallable(functions, "setQuestions");
  setQuestions({
    eventUid: eventRef.id,
    questions: questions,
    selected: selected,
  });*/
};

export const editEventRogaine = async (
  uid,
  description,
  organizer,
  startTime,
  endTime,
  date,
  image,
  club,
  selected
  /*  questions*/
) => {
  let url = "";

  if (image != null) {
    const imageRef = ref(storage, "events/images/" + image.name);

    await uploadBytes(imageRef, image);

    url = await getDownloadURL(imageRef);
  }

  await updateDoc(doc(firestore, `clubs/${club}/events/${uid}`), {
    description: description,
    organizer: organizer,
    startDate: date + " " + startTime,
    endDate: date + " " + endTime,
    photoURL: url,
    members: [],
    club: club,
  });

  /*  const updateQuestions = httpsCallable(functions, "updateQuestions");
  updateQuestions({ eventUid: uid, questions: questions, selected: selected });*/
};

export const editEvent = async (
  uid,
  description,
  organizer,
  startTime,
  endTime,
  date,
  amount,
  price,
  image,
  club
) => {
  let url = "";

  if (image != null) {
    const imageRef = ref(storage, "events/images/" + image.name);

    await uploadBytes(imageRef, image);

    url = await getDownloadURL(imageRef);
  }

  await updateDoc(doc(firestore, `clubs/${club}/events/${uid}`), {
    description: description,
    organizer: organizer,
    startDate: date + " " + startTime,
    endDate: date + " " + endTime,
    photoURL: url,
    members: [],
    club: club,
    amount: parseInt(amount),
    price: parseInt(price),
    arrived: [],
  });
};

export const createEvent = async (
  description,
  organizer,
  startTime,
  endTime,
  date,
  amount,
  price,
  image,
  club
) => {
  let url = "";

  if (image != null) {
    const imageRef = ref(storage, "events/images/" + image.name);

    await uploadBytes(imageRef, image);

    url = await getDownloadURL(imageRef);
  }

  await addDoc(collection(firestore, `clubs/${club}/events`), {
    description: description,
    organizer: organizer,
    startDate: date + " " + startTime,
    endDate: date + " " + endTime,
    photoURL: url,
    members: [],
    club: club,
    amount: parseInt(amount),
    price: parseInt(price),
    isActive: false,
    isOver: false,
    date: "",
    arrived: [],
  });
};

export const getQuestions = async (club) => {
  let questions = [];
  if (club === "rogaine") {
    await getDocs(
      query(
        collection(firestore, "clubs/rogaine/questions"),
        orderBy("id", "asc"),
        limit(60)
      )
    ).then((querySnapshot) => {
      querySnapshot.docs.forEach((doc) => {
        if (doc.exists()) questions.push(doc.data());
      });
    });

    return questions;
  }
};

export const getActiveRogaine = async () => {
  let e = [];

  const rogaine_active = query(
    collectionGroup(firestore, `events`),
    where(`club`, `==`, `rogaine`),
    where(`isActive`, `==`, true)
  );

  const querySnapshotRogaineActive = await getDocs(rogaine_active);

  await querySnapshotRogaineActive.forEach((doc) => {
    e.push(doc);
  });

  return e;
};

export const checkQuestionByUid = async (eventUid, questionId) => {
  let valid = false;

  await getDoc(
    doc(firestore, `clubs/rogaine/events/${eventUid}/questions/${questionId}`)
  ).then((doc) => {
    if (doc.exists()) valid = true;
  });

  return valid;
};

export const checkUserByQuestion = async (uid, eventUid) => {
  let valid = false;
  await getDoc(doc(firestore, `clubs/rogaine/events/${eventUid}`)).then(
    (doc) => {
      if (doc.exists())
        if (doc.data().members_rogaine.includes(uid)) valid = true;
    }
  );

  return valid;
};

export const countAnswer = async (correct, eventUid, userUid, questionId) => {
  if (correct)
    await setDoc(doc(firestore, `users/${userUid}/events/${eventUid}`), {
      answered_correct: arrayUnion(questionId),
    });
  else
    await setDoc(doc(firestore, `users/${userUid}/events/${eventUid}`), {
      answered_incorrect: arrayUnion(questionId),
    });
};
