import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import { useAuth } from "../contexts/AuthContext";
import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from "@mui/material";
import { useHistory } from "react-router-dom";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      format="+7 ### ### ####"
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function Register() {
  const history = useHistory();
  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [sex, setSex] = useState("");
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    sex: "",
    birthDate: "",
    email: "",
    phonenumber: "",
  });

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [checked, setChecked] = useState(false);

  const handleCheck = (e) => {
    setChecked(e.target.checked);
  };

  const handleChangeSex = (event) => {
    setSex(event.target.value);
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  let data;

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = new FormData(event.currentTarget);

    if (form.get("password") !== form.get("passwordConfirm")) {
      setError("Пароли не совпадают");
      return setOpen(true);
    }

    try {
      setError("");
      setLoading(true);
      await signup(
        form.get("email"),
        form.get("password"),
        form.get("firstName"),
        form.get("middleName"),
        form.get("lastName"),
        form.get("phonenumber"),
        form.get("birthDate"),
        form.get("sex")
      )
        .then(() => {
          history.push("/profile");
        })
        .catch(function (error) {
          let errorCode = error.code;
          let errorMessage = error.message;

          if (errorCode === "auth/weak-password") {
            setError("Слабый пароль (Пароль должен состоять из 6-ти символов)");
          }
          if (errorCode === "auth/email-already-in-use") {
            setError("Email уже использован");
          }

          setOpen(true);
        });
    } catch (e) {}

    setLoading(false);
  };

  const renderLogin = () => {
    return (
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(https://source.unsplash.com/random)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Регистрация
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    onChange={handleChange}
                    type="text"
                    id="lastName"
                    label="Фамилия"
                    name="lastName"
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    type="text"
                    required
                    fullWidth
                    id="firstName"
                    label="Имя"
                    onChange={handleChange}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    type="text"
                    id="middleName"
                    label="Отчество"
                    name="middleName"
                    onChange={handleChange}
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    type="email"
                    id="email"
                    label="Почта"
                    name="email"
                    onChange={handleChange}
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    label="Номер телефона"
                    value={values.phonenumber}
                    name="phonenumber"
                    id="phonenumber-input"
                    fullWidth
                    onChange={handleChange}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="sex-select-label">Пол</InputLabel>
                    <Select
                      labelId="sex-select-label"
                      id="sex-select"
                      value={sex}
                      name="sex"
                      label="Пол"
                      onChange={handleChangeSex}
                    >
                      <MenuItem value={"Муж"}>Муж</MenuItem>
                      <MenuItem value={"Жен"}>Жен</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    required
                    fullWidth
                    id="birthDate"
                    label="Дата рождения"
                    name="birthDate"
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Пароль"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="passwordConfirm"
                    label="Повторите пароль"
                    type="password"
                    id="passwordConfirm"
                    autoComplete="new-password"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={checked}
                        onChange={handleCheck}
                        color="primary"
                      />
                    }
                    label={
                      <>
                        <Box component="span">Я согласен с</Box>
                        <Link href="https://firebasestorage.googleapis.com/v0/b/sportclubs-moscow-sport.appspot.com/o/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5_%D0%B4%D0%BB%D1%8F_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8_%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85_%D0%B8_%D0%BF%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf?alt=media&token=2d8753ba-dd79-41c2-ac20-4c06477c2847">
                          {" "}
                          политикой конфиденциальности
                        </Link>
                      </>
                    }
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                disabled={loading || !checked}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                ЗАРЕГИСТРИРОВАТЬСЯ
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="/login" variant="body2">
                    Уже есть аккаунт? Войдите
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {error}
          </Alert>
        </Snackbar>
      </Grid>
    );
  };

  try {
    data = renderLogin();
  } catch (e) {}

  return <>{data}</>;
}

export default Register;
